import { Button, Grid, Typography } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import DOMPurify from 'dompurify';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Campo, Formulario } from '../../../interfaces/form-flow';
import { answerForm, getStatusFormFlow } from '../../../services/form-flow';
import getCookie from '../../../utils/getCookies';
import {
  Area,
  MultCheckboxe,
  Multiple,
  Phone,
  Select,
  Text,
  TextDate,
  TextEmail,
  TextNumber,
} from '../Inputs';
import Arquivo from '../Inputs/Arquivo';
import MultArquivo from '../Inputs/MultArquivo';
import MultipleAPI from '../Inputs/MultipleAPI';
import SelectAPI from '../Inputs/SelectAPI';

interface Props {
  page: number;
  formData: Formulario[];
  handleStep: (pageOrStep: number) => void;
  handleSubmit: (values: any, errors?: any) => void;
  formsAnswerData?: any[];
  requestMyData: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#264259',
      },
    },
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    color: '#264259',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: 16,
    padding: 20,
  },
  subtitle: {
    opacity: 0.6,
    textAlign: 'center',
    fontWeight: 'normal',
    fontSize: 16,
  },
  formControl: {
    minWidth: 120,
  },
  adornedEnd: {
    paddingRight: 0,
  },
  buttonForm: {
    backgroundColor: '#264259',
  },
  gridContainer: {
    marginTop: 20,
  },
  buttonStyleBase: {
    padding: '5px 10px',
    border: 'none',
    borderRadius: '5px',
    fontSize: '12px',
    cursor: 'pointer',
    transition: '0.3s',
    marginTop: '5px',
    color: '#fff',
  },
}));

export default function Flow({
  page,
  handleStep,
  formData,
  handleSubmit,
  formsAnswerData,
  requestMyData,
}: Props): JSX.Element {
  const classes = useStyles();
  const [currentPageData, setCurrentPageData] = useState<Formulario>(
    formData[page],
  );
  const [currentFormsAnswer, setCurrentFormsAnswer] = useState(
    formsAnswerData[page],
  );
  const [values, setValues] = useState<any>({});
  const [valuesPattern, setValuesPattern] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [statusFormFlow, setStatusFormFlow] = useState<any>({});

  useEffect(() => {
    const upcomingPageData = formData[page];
    const upcomingAnswerData = formsAnswerData[page];

    if (upcomingAnswerData) {
      setCurrentFormsAnswer(upcomingAnswerData);
    }
    if (upcomingPageData) {
      setCurrentPageData(upcomingPageData);

      if (upcomingPageData.campos.length) {
        // VALUES
        setValues(currentValues => {
          const newValues = upcomingPageData.campos.reduce((obj, field) => {
            if (upcomingAnswerData) {
              obj[field.id] =
                upcomingAnswerData?.resposta[field.id].resposta || '';
            } else {
              obj[field.id] = '';
            }
            return obj;
          }, {});

          return Object.assign({}, currentValues, newValues);
        });

        // SET ERRORS
        setErrors(currentValues => {
          const newValues = upcomingPageData.campos.reduce((obj, field) => {
            obj[field.id] = '';
            return obj;
          }, {});
          return Object.assign({}, newValues, currentValues);
        });
      }
    }
  }, [page, formData, formsAnswerData]);

  const handleSave = async slug => {
    const formDataUser = JSON.parse(getCookie('gov_user_form_started') || '{}');
    const user = JSON.parse(getCookie('gov_user_data') || '{}');
    if (formDataUser) {
      const forResposta = {};
      currentPageData.campos.forEach(field => {
        const fieldId = field.id;
        let value = values[fieldId];
        if (field.tipo === 'Data' && value && value instanceof Date) {
          value = value.toLocaleDateString('pt-BR');
        }
        forResposta[fieldId] = {
          id: fieldId,
          resposta: value,
        };
      });

      const formatData = {
        flow_resposta: formDataUser.id,
        formulario: slug,
        cpf: user.cpf,
        resposta: forResposta,
      };

      try {
        const response = await answerForm(formatData);

        if (response) {
          handleStep(page + 1);
        }
      } catch (error) {
        if (error.response.status === 406) {
          handleStep(page + 1);
        }
        console.log('error ao salvar resposta', error);
      }
    }
  };

  const onSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (validate()) {
      const totalPage = formData.length - 1;
      //const nextPage = page + 1;
      if (page <= totalPage) {
        await handleSave(currentPageData.slug);
        // handleStep(nextPage);
      }

      if (page === totalPage) {
        await checkStatusFormFlow();
        handleSubmit(values, errors);
      }
    }
  };

  const checkStatusFormFlow = async () => {
    const formDataUser = JSON.parse(getCookie('gov_user_form_started') || '{}');
    const user = JSON.parse(getCookie('gov_user_data') || '{}');
    if (formDataUser?.identificador && user?.cpf) {
      try {
        const { data } = await getStatusFormFlow({
          identificador: formDataUser.identificador,
          cpf: user.cpf,
        });
        setStatusFormFlow(data);
      } catch (error) {
        console.log('error ao verificar status form flow', error);
      }
    }
  };

  // Valida campos, para tratamento externo
  const validate = useCallback(() => {
    const invalids = [];
    const currentValues = {};

    if (currentPageData?.campos?.length) {
      currentPageData.campos.forEach(field => {
        const fieldId = field.id;
        const value = values[fieldId];
        // Marca os erros
        if (field.obrigatorio && !value) {
          currentValues[fieldId] = 'Campo obrigatório';
        }

        if (field.validador) {
          const reg = new RegExp(field.validador, 'g').test(value);
          if (!reg) {
            currentValues[fieldId] = 'Campo no formato inválido';
          }
        }

        if (currentValues[fieldId]) invalids.push(currentValues[fieldId]);

        setErrors(currentValues);
      });
    }

    return !invalids.length;
  }, [currentPageData?.campos, values]);

  // set os Inputs
  const fieldChanged = (fieldId: number, value: any) => {
    setValues(currentValues => {
      currentValues[fieldId] = value;
      return currentValues;
    });

    setCurrentPageData(currentPageData => {
      return Object.assign({}, currentPageData);
    });

    if (errors) {
      setErrors(currentError => (currentError[fieldId] = ''));
    }
  };

  // determina a proxima pagina (Navegação entre forms)
  const handlePrev = () => {
    const pagePrev = page - 1;
    if (pagePrev >= 0) {
      handleStep(pagePrev);
    }
  };

  // Testa a proxima pagina
  const hasPageNext = () => {
    return (
      (page >= 0 && page < formData.length - 1) || formData.length - 1 === page
    );
  };

  // Pega os dados do GovBR se a solicitação for para si
  const getValueFromUser = (titulo: string, fieldId: number) => {
    if (!requestMyData) return '';
    const user = JSON.parse(getCookie('gov_user_data') || '{}');
    switch (titulo) {
      case 'CPF':
      case 'cpf':
      case 'Cpf':
        setValues(currentValues => {
          currentValues[fieldId] = user.cpf;
          return currentValues;
        });
        setValuesPattern(currentValues => {
          currentValues[fieldId] = user.cpf;
          return currentValues;
        });
        break;
      case 'NOME':
      case 'Nome':
      case 'nome':
        setValues(currentValues => {
          currentValues[fieldId] = user.nome;
          return currentValues;
        });
        setValuesPattern(currentValues => {
          currentValues[fieldId] = user.nome;
          return currentValues;
        });
        break;
      default:
        return '';
    }
  };

  const getEmailFromUser = (fieldId: number) => {
    if (!requestMyData) return '';
    const user = JSON.parse(getCookie('gov_user_data') || '{}');

    if (user?.contato?.email) {
      setValues(currentValues => {
        currentValues[fieldId] = user.contato.email;
        return currentValues;
      });
      setValuesPattern(currentValues => {
        currentValues[fieldId] = user.contato.email;
        return currentValues;
      });
    }
  };

  const getTelefoneFromUser = (fieldId: number) => {
    if (!requestMyData) return '';
    const user = JSON.parse(getCookie('gov_user_data') || '{}');

    if (user?.contato?.celular) {
      setValues(currentValues => {
        currentValues[fieldId] = user.contato.celular;
        return currentValues;
      });
      setValuesPattern(currentValues => {
        currentValues[fieldId] = user.contato.celular;
        return currentValues;
      });
    }
  };

  const matrixFieldChanged = (campoId, ordem, value, label, idx) => {
    setValues(prevValues => {
      const updatedValues = { ...prevValues };

      if (!updatedValues[campoId]) {
        updatedValues[campoId] = [];
      }

      if (!updatedValues[campoId][idx]) {
        updatedValues[campoId][idx] = [];
      }

      const indexExistingObject = updatedValues[campoId][idx]?.findIndex(
        obj => obj.id === ordem,
      );

      if (indexExistingObject > -1) {
        updatedValues[campoId][idx][indexExistingObject].resposta = value;
      } else {
        updatedValues[campoId][idx].push({
          id: ordem,
          resposta: value,
          label: label,
        });
      }

      console.log(updatedValues, ' updatedValues');

      return updatedValues;
    });
  };

  const findValueForInput = (campoId, ordem, idx) => {
    const respostaArray = values[campoId]?.[idx];

    if (!respostaArray) return null;

    const obj = respostaArray.find(item => item.id === ordem);

    return obj?.resposta || null;
  };

  function convertDateFormat(dateString) {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  function convertToLongFormat(dateString: string | null | undefined) {
    if (!dateString) {
      console.log('');
    }

    const [day, month, year] = dateString.split('/').map(Number);

    const date = new Date(year, month - 1, day, 11, 11);

    const options: Intl.DateTimeFormatOptions = {
      weekday: 'short',
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
      timeZone: 'America/Sao_Paulo',
    };
    const longDate =
      date.toLocaleString('en-US', options) + ' (Horário Padrão de Brasília)';

    return longDate;
  }

  const [replicationCounts, setReplicationCounts] = useState({});

  useEffect(() => {
    const initialCounts = {};
    formData.forEach(form => {
      form.campos.forEach(campo => {
        if (campo.tipo === 'Matriz') {
          initialCounts[campo.id] = 1;
        }
      });
    });
    setReplicationCounts(initialCounts);
  }, [formData]);

  const handleReplicate = (campoId, limite) => {
    setReplicationCounts(prevCounts => {
      const newCount =
        prevCounts[campoId] < limite + 1
          ? prevCounts[campoId] + 1
          : prevCounts[campoId];
      return { ...prevCounts, [campoId]: newCount };
    });
  };

  const handleDeleteField = campoId => {
    setReplicationCounts(prevCounts => {
      const newCount = prevCounts[campoId] > 1 ? prevCounts[campoId] - 1 : 1;
      return { ...prevCounts, [campoId]: newCount };
    });
  };

  const renderActionButton = (campoId, idx, limite) => {
    if (limite === 0) return null;

    if (idx === 0 && replicationCounts[campoId] < limite + 1) {
      return (
        <>
          <button
            type='button'
            onClick={() => handleReplicate(campoId, limite)}
            className={classes.buttonStyleBase}
            style={{ backgroundColor: '#007BFF' }}
          >
            Duplicar
          </button>
        </>
      );
    } else if (idx > 0) {
      return (
        <>
          <button
            type='button'
            onClick={() => handleDeleteField(campoId)}
            className={classes.buttonStyleBase}
            style={{ backgroundColor: 'red' }}
          >
            Excluir
          </button>
        </>
      );
    }
    return null;
  };

  return (
    <>
      {currentPageData && (
        <>
          <Grid container alignItems='center' justify='center'>
            <Grid item xs={12}>
              <Typography variant='h6' className={classes.title}>
                {currentPageData.titulo}
              </Typography>
            </Grid>
            <Grid
              style={{ textAlign: 'center', marginBottom: 20 }}
              item
              xs={12}
            >
              <Typography className={classes.subtitle}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(currentPageData.descricao),
                  }}
                />
              </Typography>
            </Grid>
          </Grid>

          <form onSubmit={onSubmit} className={classes.root}>
            {!currentPageData.campos.length ? (
              <Alert severity='warning' style={{ marginBottom: 10 }}>
                Nenhum campo disponível
              </Alert>
            ) : (
              <Grid container spacing={2}>
                {currentPageData.campos.map((campo: Campo) => (
                  <Fragment key={campo.id}>
                    {campo.tipo === 'Texto' && (
                      <Grid sm={12} md={6} item>
                        <Text
                          required={campo.obrigatorio}
                          id={`${campo.tipo}-${campo.id}`}
                          label={campo.titulo}
                          value={
                            values[campo.id]
                              ? values[campo.id]
                              : getValueFromUser(campo.titulo, campo.id)
                          }
                          error={errors[campo.id] ? true : false}
                          onChange={e => fieldChanged(campo.id, e.target.value)}
                          helperText={
                            errors[campo.id]
                              ? errors[campo.id]
                              : campo.texto_ajuda
                          }
                          disabled={
                            currentFormsAnswer?.formulario ===
                              currentPageData.slug ||
                            !!Object.keys(valuesPattern).find(
                              chave =>
                                valuesPattern[chave] === values[campo.id],
                            )
                          }
                          mask={
                            campo.mascara !== 'mascara' ? campo.mascara : ''
                          }
                        />
                      </Grid>
                    )}
                    <Grid xs={12} md={12} />
                    {Array.from({ length: replicationCounts[campo.id] }).map(
                      (_, idx) => (
                        <>
                          {campo.tipo === 'Matriz' &&
                            campo.alternativas.map((alternativa, index) => {
                              const dateValue = findValueForInput(
                                campo.id,
                                alternativa.ordem,
                                idx,
                              );
                              switch (alternativa.campo) {
                                case 'Data':
                                  return (
                                    <Grid
                                      xs={12}
                                      md={6}
                                      item
                                      key={`${campo.id}-${index}-${idx}`}
                                    >
                                      <TextDate
                                        required={campo.obrigatorio}
                                        id={`${alternativa.campo}-${campo.id}-${index}-${idx}`}
                                        label={alternativa.titulo}
                                        value={
                                          dateValue
                                            ? convertToLongFormat(dateValue)
                                            : null
                                        }
                                        error={
                                          errors[campo.id] &&
                                          errors[campo.id][index]
                                            ? true
                                            : false
                                        }
                                        onChange={dateEvent => {
                                          matrixFieldChanged(
                                            campo.id,
                                            alternativa.ordem,
                                            convertDateFormat(dateEvent),
                                            alternativa.titulo,
                                            idx,
                                          );
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        helperText={
                                          errors[campo.id] &&
                                          errors[campo.id][index]
                                            ? errors[campo.id][index]
                                            : alternativa.texto_ajuda
                                        }
                                        disabled={
                                          currentFormsAnswer?.formulario ===
                                            currentPageData.slug ||
                                          !!Object.keys(valuesPattern).find(
                                            chave =>
                                              valuesPattern[chave] ===
                                              values[campo.id],
                                          )
                                        }
                                      />
                                    </Grid>
                                  );
                                case 'Texto':
                                  return (
                                    <Grid
                                      xs={12}
                                      md={6}
                                      item
                                      key={`${campo.id}-${index}-${idx}`}
                                    >
                                      <Text
                                        required={campo.obrigatorio}
                                        id={`${alternativa.campo}-${campo.id}-${index}-${idx}`}
                                        label={alternativa.titulo}
                                        value={findValueForInput(
                                          campo.id,
                                          alternativa.ordem,
                                          idx,
                                        )}
                                        error={
                                          errors[campo.id] &&
                                          errors[campo.id][index]
                                            ? true
                                            : false
                                        }
                                        onChange={data => {
                                          matrixFieldChanged(
                                            campo.id,
                                            alternativa.ordem,
                                            data.target.value,
                                            alternativa.titulo,
                                            idx,
                                          );
                                        }}
                                        mask={campo.mascara}
                                        helperText={
                                          errors[campo.id] &&
                                          errors[campo.id][index]
                                            ? errors[campo.id][index]
                                            : alternativa.texto_ajuda
                                        }
                                        disabled={
                                          currentFormsAnswer?.formulario ===
                                            currentPageData.slug ||
                                          !!Object.keys(valuesPattern).find(
                                            chave =>
                                              valuesPattern[chave] ===
                                              values[campo.id],
                                          )
                                        }
                                      />
                                    </Grid>
                                  );
                                case 'Telefone':
                                  return (
                                    <Grid
                                      xs={12}
                                      md={6}
                                      item
                                      key={`${campo.id}-${index}-${idx}`}
                                    >
                                      <Phone
                                        required={campo.obrigatorio}
                                        id={`${alternativa.campo}-${campo.id}-${index}-${idx}`}
                                        label={alternativa.titulo}
                                        value={findValueForInput(
                                          campo.id,
                                          alternativa.ordem,
                                          idx,
                                        )}
                                        error={
                                          errors[campo.id] &&
                                          errors[campo.id][index]
                                            ? true
                                            : false
                                        }
                                        onChange={event => {
                                          matrixFieldChanged(
                                            campo.id,
                                            alternativa.ordem,
                                            event.target.value,
                                            alternativa.titulo,
                                            idx,
                                          );
                                        }}
                                        mask={campo.mascara}
                                        helperText={
                                          errors[campo.id] &&
                                          errors[campo.id][index]
                                            ? errors[campo.id][index]
                                            : alternativa.texto_ajuda
                                        }
                                        disabled={
                                          currentFormsAnswer?.formulario ===
                                            currentPageData.slug ||
                                          !!Object.keys(valuesPattern).find(
                                            chave =>
                                              valuesPattern[chave] ===
                                              values[campo.id],
                                          )
                                        }
                                      />
                                    </Grid>
                                  );
                                case 'Email':
                                  return (
                                    <Grid
                                      xs={12}
                                      md={6}
                                      item
                                      key={`${campo.id}-${index}-${idx}`}
                                    >
                                      <TextEmail
                                        required={campo.obrigatorio}
                                        id={`${alternativa.campo}-${campo.id}-${index}-${idx}`}
                                        label={alternativa.titulo}
                                        value={findValueForInput(
                                          campo.id,
                                          alternativa.ordem,
                                          idx,
                                        )}
                                        error={
                                          errors[campo.id] &&
                                          errors[campo.id][index]
                                            ? true
                                            : false
                                        }
                                        onChange={e =>
                                          matrixFieldChanged(
                                            campo.id,
                                            alternativa.ordem,
                                            e.target.value,
                                            alternativa.titulo,
                                            idx,
                                          )
                                        }
                                        helperText={
                                          errors[campo.id] &&
                                          errors[campo.id][index]
                                            ? errors[campo.id][index]
                                            : alternativa.texto_ajuda
                                        }
                                        disabled={
                                          currentFormsAnswer?.formulario ===
                                            currentPageData.slug ||
                                          !!Object.keys(valuesPattern).find(
                                            chave =>
                                              valuesPattern[chave] ===
                                              values[campo.id],
                                          )
                                        }
                                      />
                                    </Grid>
                                  );
                                case 'Area':
                                  return (
                                    <Grid
                                      xs={12}
                                      md={6}
                                      item
                                      key={`${campo.id}-${index}-${idx}`}
                                    >
                                      <Area
                                        required={campo.obrigatorio}
                                        id={`${alternativa.campo}-${campo.id}-${index}-${idx}`}
                                        label={alternativa.titulo}
                                        value={findValueForInput(
                                          campo.id,
                                          alternativa.ordem,
                                          idx,
                                        )}
                                        error={
                                          errors[campo.id] &&
                                          errors[campo.id][index]
                                            ? true
                                            : false
                                        }
                                        onChange={data =>
                                          matrixFieldChanged(
                                            campo.id,
                                            alternativa.ordem,
                                            data.target.value,
                                            alternativa.titulo,
                                            idx,
                                          )
                                        }
                                        helperText={
                                          errors[campo.id] &&
                                          errors[campo.id][index]
                                            ? errors[campo.id][index]
                                            : alternativa.texto_ajuda
                                        }
                                        disabled={
                                          currentFormsAnswer?.formulario ===
                                            currentPageData.slug ||
                                          !!Object.keys(valuesPattern).find(
                                            chave =>
                                              valuesPattern[chave] ===
                                              values[campo.id],
                                          )
                                        }
                                      />
                                    </Grid>
                                  );
                                case 'Numero':
                                  return (
                                    <Grid
                                      xs={12}
                                      md={6}
                                      item
                                      key={`${campo.id}-${index}-${idx}`}
                                    >
                                      <TextNumber
                                        id={`${alternativa.campo}-${campo.id}-${index}-${idx}`}
                                        label={alternativa.titulo}
                                        value={findValueForInput(
                                          campo.id,
                                          alternativa.ordem,
                                          idx,
                                        )}
                                        required={campo.obrigatorio}
                                        onChange={data =>
                                          matrixFieldChanged(
                                            campo.id,
                                            alternativa.ordem,
                                            data.target.value,
                                            alternativa.titulo,
                                            idx,
                                          )
                                        }
                                        helperText={
                                          errors[campo.id] &&
                                          errors[campo.id][index]
                                            ? errors[campo.id][index]
                                            : alternativa.texto_ajuda
                                        }
                                        disabled={
                                          currentFormsAnswer?.formulario ===
                                            currentPageData.slug ||
                                          !!Object.keys(valuesPattern).find(
                                            chave =>
                                              valuesPattern[chave] ===
                                              values[campo.id],
                                          )
                                        }
                                      />
                                    </Grid>
                                  );
                                default:
                                  return null;
                              }
                            })}
                          {campo.tipo === 'Matriz' && (
                            <Grid
                              item
                              xs={12}
                              md={12}
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              {renderActionButton(
                                campo.id,
                                idx,
                                campo.limite_replicacoes,
                              )}
                            </Grid>
                          )}
                        </>
                      ),
                    )}
                    {campo.tipo === 'Area' && (
                      <Grid sm={12} md={12} item>
                        <Area
                          required={campo.obrigatorio}
                          id={`${campo.tipo}-${campo.id}`}
                          label={campo.titulo}
                          value={values[campo.id] ? values[campo.id] : ''}
                          error={errors[campo.id] ? true : false}
                          onChange={e => fieldChanged(campo.id, e.target.value)}
                          helperText={
                            errors[campo.id]
                              ? errors[campo.id]
                              : campo.texto_ajuda
                          }
                          disabled={
                            currentFormsAnswer?.formulario ===
                              currentPageData.slug ||
                            !!Object.keys(valuesPattern).find(
                              chave =>
                                valuesPattern[chave] === values[campo.id],
                            )
                          }
                        />
                      </Grid>
                    )}
                    {campo.tipo === 'Email' && (
                      <Grid sm={12} md={4} item>
                        <TextEmail
                          required={campo.obrigatorio}
                          id={`${campo.tipo}-${campo.id}`}
                          label={campo.titulo}
                          value={
                            values[campo.id]
                              ? values[campo.id]
                              : getEmailFromUser(campo.id)
                          }
                          error={errors[campo.id] ? true : false}
                          onChange={e => fieldChanged(campo.id, e.target.value)}
                          helperText={
                            errors[campo.id]
                              ? errors[campo.id]
                              : campo.texto_ajuda
                          }
                          disabled={
                            currentFormsAnswer?.formulario ===
                              currentPageData.slug ||
                            !!Object.keys(valuesPattern).find(
                              chave =>
                                valuesPattern[chave] === values[campo.id],
                            )
                          }
                        />
                      </Grid>
                    )}
                    {campo.tipo === 'Numero' && (
                      <Grid sm={12} md={6} item>
                        <TextNumber
                          id={`${campo.tipo}-${campo.id}`}
                          label={campo.titulo}
                          value={values[campo.id] ? values[campo.id] : ''}
                          required={campo.obrigatorio}
                          onChange={e => fieldChanged(campo.id, e.target.value)}
                          helperText={
                            errors[campo.id]
                              ? errors[campo.id]
                              : campo.texto_ajuda
                          }
                          disabled={
                            currentFormsAnswer?.formulario ===
                              currentPageData.slug ||
                            !!Object.keys(valuesPattern).find(
                              chave =>
                                valuesPattern[chave] === values[campo.id],
                            )
                          }
                        />
                      </Grid>
                    )}
                    {campo.tipo === 'Telefone' && (
                      <Grid sm={12} md={3} item>
                        <Phone
                          required={campo.obrigatorio ? true : false}
                          id={`${campo.tipo}-${campo.id}`}
                          label={campo.titulo}
                          value={
                            values[campo.id]
                              ? values[campo.id]
                              : getTelefoneFromUser(campo.id)
                          }
                          error={errors[campo.id] ? true : false}
                          onChange={e => fieldChanged(campo.id, e.target.value)}
                          mask={campo.mascara}
                          helperText={
                            errors[campo.id]
                              ? errors[campo.id]
                              : campo.texto_ajuda
                          }
                          disabled={
                            currentFormsAnswer?.formulario ===
                              currentPageData.slug ||
                            !!Object.keys(valuesPattern).find(
                              chave =>
                                valuesPattern[chave] === values[campo.id],
                            )
                          }
                        />
                      </Grid>
                    )}
                    {campo.tipo === 'Data' && (
                      <Grid sm={12} md={3} item>
                        <TextDate
                          required={campo.obrigatorio}
                          label={campo.titulo}
                          value={values[campo.id] ? values[campo.id] : null}
                          error={errors[campo.id] ? true : false}
                          onChange={data => fieldChanged(campo.id, data)}
                          InputLabelProps={{ shrink: true }}
                          helperText={
                            errors[campo.id]
                              ? errors[campo.id]
                              : campo.texto_ajuda
                          }
                          disabled={
                            currentFormsAnswer?.formulario ===
                              currentPageData.slug ||
                            !!Object.keys(valuesPattern).find(
                              chave =>
                                valuesPattern[chave] === values[campo.id],
                            )
                          }
                        />
                      </Grid>
                    )}
                    {campo.tipo === 'Alternativas' &&
                      campo.tipo_alternativa === 'RadioButton' && (
                        <Grid sm={12} md={12} item>
                          <MultCheckboxe
                            required={campo.obrigatorio}
                            id={`${campo.tipo}-${campo.id}`}
                            label={campo.titulo}
                            value={values[campo.id] ? values[campo.id] : ''}
                            error={errors[campo.id] ? true : false}
                            onChange={e =>
                              fieldChanged(campo.id, e.target.value)
                            }
                            helperText={
                              errors[campo.id]
                                ? errors[campo.id]
                                : campo.texto_ajuda
                            }
                            itens={campo.alternativas}
                            disabled={
                              currentFormsAnswer?.formulario ===
                                currentPageData.slug ||
                              !!Object.keys(valuesPattern).find(
                                chave =>
                                  valuesPattern[chave] === values[campo.id],
                              )
                            }
                          />
                        </Grid>
                      )}
                    {campo.tipo === 'Alternativas' &&
                      campo.tipo_alternativa === 'Select' && (
                        <Grid sm={12} md={6} item>
                          <Select
                            required={campo.obrigatorio}
                            id={`${campo.tipo}-${campo.id}`}
                            label={campo.titulo}
                            value={values[campo.id] ? values[campo.id] : ''}
                            error={errors[campo.id] ? true : false}
                            onChange={e =>
                              fieldChanged(campo.id, e.target.value)
                            }
                            helperText={
                              errors[campo.id]
                                ? errors[campo.id]
                                : campo.texto_ajuda
                            }
                            itens={campo.alternativas}
                            getOptionLabel={option => option.titulo}
                            disabled={
                              currentFormsAnswer?.formulario ===
                                currentPageData.slug ||
                              !!Object.keys(valuesPattern).find(
                                chave =>
                                  valuesPattern[chave] === values[campo.id],
                              )
                            }
                          />
                        </Grid>
                      )}
                    {campo.tipo === 'Alternativas_API' && (
                      <Grid sm={12} md={6} item>
                        <SelectAPI
                          required={campo.obrigatorio}
                          id={`${campo.tipo}-${campo.id}`}
                          label={campo.titulo}
                          value={values[campo.id] ? values[campo.id] : ''}
                          error={errors[campo.id] ? true : false}
                          onChange={value => fieldChanged(campo.id, value)}
                          helperText={
                            errors[campo.id]
                              ? errors[campo.id]
                              : campo.texto_ajuda
                          }
                          itens={campo.alternativas}
                          getOptionLabel={option => option.label}
                          disabled={
                            currentFormsAnswer?.formulario ===
                              currentPageData.slug ||
                            !!Object.keys(valuesPattern).find(
                              chave =>
                                valuesPattern[chave] === values[campo.id],
                            )
                          }
                        />
                      </Grid>
                    )}
                    {campo.tipo === 'Multiplos' && (
                      <Grid sm={12} md={12} item>
                        <Multiple
                          required={campo.obrigatorio}
                          label={campo.titulo}
                          values={values[campo.id] ? values[campo.id] : []}
                          error={errors[campo.id] ? true : false}
                          onChange={value => fieldChanged(campo.id, value)}
                          InputLabelProps={{ shrink: true }}
                          helperText={
                            errors[campo.id]
                              ? errors[campo.id]
                              : campo.texto_ajuda
                          }
                          itens={campo.alternativas}
                          getOptionLabel={option => option.label}
                          disabled={
                            currentFormsAnswer?.formulario ===
                              currentPageData.slug ||
                            !!Object.keys(valuesPattern).find(
                              chave =>
                                valuesPattern[chave] === values[campo.id],
                            )
                          }
                        />
                      </Grid>
                    )}
                    {campo.tipo === 'Multiplos_API' && (
                      <Grid sm={12} md={6} item>
                        <MultipleAPI
                          required={campo.obrigatorio}
                          label={campo.titulo}
                          values={values[campo.id] ? values[campo.id] : []}
                          error={errors[campo.id] ? true : false}
                          onChange={value => fieldChanged(campo.id, value)}
                          InputLabelProps={{ shrink: true }}
                          helperText={
                            errors[campo.id]
                              ? errors[campo.id]
                              : campo.texto_ajuda
                          }
                          itens={campo.alternativas}
                          getOptionLabel={option => option.label}
                          disabled={
                            currentFormsAnswer?.formulario ===
                              currentPageData.slug ||
                            !!Object.keys(valuesPattern).find(
                              chave =>
                                valuesPattern[chave] === values[campo.id],
                            )
                          }
                        />
                      </Grid>
                    )}

                    {campo.tipo === 'Arquivo' && (
                      <Grid sm={12} md={6} item>
                        <Arquivo
                          required={campo.obrigatorio}
                          id={`${campo.tipo}-${campo.id}`}
                          label={campo.titulo}
                          value={values[campo.id] ? values[campo.id] : ''}
                          error={errors[campo.id] ? true : false}
                          onChange={value => fieldChanged(campo.id, value)}
                          helperText={
                            errors[campo.id]
                              ? errors[campo.id]
                              : campo.texto_ajuda
                          }
                          disabled={
                            currentFormsAnswer?.formulario ===
                              currentPageData.slug ||
                            !!Object.keys(valuesPattern).find(
                              chave =>
                                valuesPattern[chave] === values[campo.id],
                            )
                          }
                        />
                      </Grid>
                    )}
                    {campo.tipo === 'Multiplos_Arquivos' && (
                      <Grid sm={12} md={12} item>
                        <MultArquivo
                          required={campo.obrigatorio}
                          id={`${campo.tipo}-${campo.id}`}
                          label={campo.titulo}
                          value={values[campo.id] ? values[campo.id] : []}
                          error={errors[campo.id] ? true : false}
                          onChange={value => fieldChanged(campo.id, value)}
                          helperText={
                            errors[campo.id]
                              ? errors[campo.id]
                              : campo.texto_ajuda
                          }
                          disabled={
                            currentFormsAnswer?.formulario ===
                              currentPageData.slug ||
                            !!Object.keys(valuesPattern).find(
                              chave =>
                                valuesPattern[chave] === values[campo.id],
                            )
                          }
                          numberFilesPermitted={campo?.limite_replicacoes}
                        />
                      </Grid>
                    )}
                  </Fragment>
                ))}
              </Grid>
            )}

            {/* Navegação padrão */}
            <Grid
              container
              direction='row'
              justify='space-between'
              alignItems='center'
              className={classes.gridContainer}
            >
              <Grid item xs={3}>
                {page > 0 && (
                  <Button
                    style={{ color: '#264259' }}
                    onClick={() => handlePrev()}
                  >
                    VOLTAR
                  </Button>
                )}
              </Grid>
              <Grid item xs={3}>
                {hasPageNext() && (
                  <Button
                    className={classes.buttonForm}
                    variant='contained'
                    fullWidth
                    color='primary'
                    type='submit'
                    disabled={
                      statusFormFlow?.pendentes?.length &&
                      page === formData.length - 1
                    }
                  >
                    {formData.length - 1 === page
                      ? 'SALVAR'
                      : 'SALVAR / AVANÇAR'}
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </>
  );
}
