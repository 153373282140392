import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { MdArrowBack, MdContentPaste, MdEvent, MdMenu } from 'react-icons/md';
import { Breadcrumb } from '../../Components';
import { ItermsResult, ItermsTerms } from '../../interfaces/termos';
import { loadSignedTermSISECI } from '../../services/temosSISECI';
import { loadSignedTermSSO } from '../../services/temosSSO';
import theme from '../../services/theme-service';
import { extractDateFormat } from '../../utils/dateFormat';
import getCookie from '../../utils/getCookies';
import PaginationService from './components/PaginationService';
import TermoDialog from './components/TermoDialog';

type Props = {
  history: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 10px',
  },
  boxIndicatorsBack: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0px'
  },
  indicatorsBack: {
    display: 'flex',
    width: '100%',
    color: '#373F41',
    maxWidth: '1250px',
    alignItems: 'center',
  },
  boxTermsList: {
    backgroundColor: '#fff',
    padding: 30,
    borderRadius: 4,
    margin: '40px 0px',
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    color: '#373F41',
    marginBottom: 30,
    marginTop: 30,
  },
  link: {
    cursor: 'pointer',
    fontSize: 16,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
  },
  infoText: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'normal',
    color: '#333333',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
  },
  boxDateInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#373F41'
  },
  date: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 16,
    fontStyle: 'normal',
    margin: '0px 4px',
  },
  textHeaderModal: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    width: '90%',
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 700,
  },
  header: {
    background: theme.palette.primary.main,
  },
  buttonClose: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: '#fff',
  },
  boxActions: {
    display: 'flex',
    justifyContent: 'space-between',

    borderRadius: 4,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: '#fff',

  },
  textTitleSelected: {
    cursor: 'pointer',
    padding: '15px 40px',
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500,
  },
  textTitle: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    padding: '15px 40px',
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500,
  },
  boxActivy: {
    width: '100%',
    textAlign: 'center'
  },
  boxFavorite: {
    width: '100%',
    textAlign: 'center'
  },
  textMenuItem: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: "normal",
    marginLeft: 10,
  },
  menuItem: {
    color: theme.palette.primary.main,
    width: 200,
  },
  iconEvent: {
    height: 24,
    width: 24,
  },
  iconContentPaste: {
    height: 45,
    width: 45,
  },
}));

const linksBreadcrumb = [
  {
    name: "Termos",
    redirectTo: "#",
  },
];

export default function Terms({ history }: Props): JSX.Element {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:604px)');
  const [termSelected, setTermSelected] = useState<ItermsTerms | undefined>();
  const [open, setOpen] = useState<boolean>(false);
  const [termsSiseci, setTermsSiseci] = useState<ItermsResult>();
  const [termsSSO, setTermsSSO] = useState<ItermsResult>();
  const [loading, setLoading] = useState<boolean>(false);
  const tokenSiseci: string | null = getCookie('gov_access_token_sso') || '{}';
  const tokenSSO: string | null = getCookie('gov_access_token_sso') || '{}';
  const loginType = getCookie('loginType');
  const [
    serviceSelected,
    setServiceSelected,
  ] = useState<'sso' | 'siseci' | 'ouvidoria'>('sso');
  const [anchorElMenu, setAnchorElMenu] =
    React.useState<HTMLButtonElement | null>(null);

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const getSignedTermSISECI = async (page = 1) => {
    try {
      setLoading(true);
      const { data } = await loadSignedTermSISECI(tokenSSO, page);
      if (data?.results) {
        setTermsSiseci(data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getSignedTermSSO = async (page = 1) => {
    try {
      const { data } = await loadSignedTermSSO(tokenSSO, page);
      if (data?.results) {
        setTermsSSO(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenTermModal = (term: ItermsTerms) => {
    setTermSelected(term);
    setOpen(true);
  };
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    type?: "menu"
  ) => {
    if (type === "menu") {
      setAnchorElMenu(event.currentTarget);
    }
  };

  useEffect(() => {
    if (tokenSiseci) {
      getSignedTermSISECI();
    }
    if (tokenSSO) {
      getSignedTermSSO();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenSiseci, tokenSSO]);

  useEffect(() => {
    if (!open && !loading) {
      if (loginType === 'e-cnpj') {
        history.push('/workspace/meus-sistemas');
      }
    }
  }, [open, loading, history, loginType]);

  return (
    <>
      <Breadcrumb links={linksBreadcrumb} />
      <Box className={classes.boxIndicatorsBack}>
        <Box className={classes.indicatorsBack}>
          <IconButton onClick={() => history.push('/workspace/minha-area/privacidade')}>
            <MdArrowBack />
          </IconButton>
          <Typography style={{ fontFamily: 'Roboto', fontWeight: 700 }}>
            Termos de aceite
          </Typography>
        </Box>
      </Box>
      {loading ? (
        <Box
          marginBottom="60px"
          width="100%"
          display="flex"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Container>
            <Box style={{ padding: 25, backgroundColor: '#fff', marginBottom: 20, }}>
              {matches ? (<Box className={classes.boxActions}>
                <Box
                  className={classes.boxActivy}
                  onClick={(): void => {
                    setServiceSelected('sso');
                  }}
                >
                  <Typography
                    className={
                      serviceSelected === 'sso'
                        ? classes.textTitleSelected
                        : classes.textTitle
                    }
                  >
                    SSO
                  </Typography>
                </Box>
                <Box
                  className={classes.boxFavorite}
                  onClick={(): void => setServiceSelected('siseci')}
                >
                  <Typography
                    className={
                      serviceSelected === 'siseci'
                        ? classes.textTitleSelected
                        : classes.textTitle
                    }
                  >
                    SERVIÇO
                  </Typography>
                </Box>
                <Box
                  className={classes.boxFavorite}
                  onClick={(): void => setServiceSelected('ouvidoria')}
                >
                  <Typography
                    className={
                      serviceSelected === 'ouvidoria'
                        ? classes.textTitleSelected
                        : classes.textTitle
                    }
                  >
                    OUVIDORIA
                  </Typography>
                </Box>
              </Box>) : (
                <Box style={{ color: theme.palette.primary.main }}>
                  <IconButton onClick={(e): void => handleClick(e, "menu")}>
                    <MdMenu color='primary' />
                  </IconButton>
                  {serviceSelected === 'sso' && `SSO`}
                  {serviceSelected === 'siseci' && `SERVIÇO`}
                  {serviceSelected === 'ouvidoria' && `OUVIDORIA`}

                  <Popover
                    open={Boolean(anchorElMenu)}
                    anchorEl={anchorElMenu}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <Box>
                      <Divider />

                      <MenuItem
                        className={classes.menuItem}
                        onClick={() => { setServiceSelected('sso'); handleCloseMenu(); }}
                      >
                        <Typography className={classes.textMenuItem}>
                          SSO
                        </Typography>
                      </MenuItem>
                      <Divider />

                      <MenuItem
                        className={classes.menuItem}
                        onClick={() => { setServiceSelected('siseci'); handleCloseMenu(); }}
                      >
                        <Typography className={classes.textMenuItem}>
                          SERVIÇO
                        </Typography>
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        className={classes.menuItem}
                        onClick={() => { setServiceSelected('ouvidoria'); handleCloseMenu(); }}
                      >
                        <Typography className={classes.textMenuItem}>
                          OUVIDORIA
                        </Typography>
                      </MenuItem>
                    </Box>
                  </Popover>


                </Box>)}

            </Box>
          </Container>

          {serviceSelected === 'siseci' &&
            (<Container>
              <Grid
                container
                spacing={3}

              >


                {termsSiseci?.results.map((term) => (

                  <Grid item xs={12} sm={12} md={4} key={term?.id}>
                    <Card>
                      <CardActionArea onClick={() => handleOpenTermModal(term)}>
                        <Box style={{
                          backgroundColor: theme.palette.primary.main,
                          height: 120,
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          paddingTop: 20,

                        }}>
                          <MdContentPaste className={classes.iconContentPaste} />
                          <Typography style={{ fontSize: 20, fontFamily: 'Roboto', marginTop: 15, marginBottom: 15 }}>SERVIÇO</Typography>
                        </Box>

                        <CardContent>
                          <Typography className={classes.title}>{term?.termo?.titulo}</Typography>
                          <Box>
                            <Typography className={classes.infoText}>
                              <MdEvent className={classes.iconEvent} style={{ marginRight: 5 }} /> Aceito em {term?.assinado_em && extractDateFormat(term?.assinado_em)}
                            </Typography>
                          </Box>

                        </CardContent>
                      </CardActionArea>

                    </Card>
                  </Grid>
                ))}


              </Grid>
              {termsSiseci?.results?.length ? (
                <PaginationService
                  listTerms={termsSiseci}
                  getSearch={getSignedTermSISECI}
                />
              ) : ''}
            </Container>)}
          {serviceSelected === 'sso' && (<Container>
            <Grid
              container
              spacing={3}
            >
              {termsSSO?.results.map((term) => (

                <Grid item xs={12} sm={12} md={4} key={term?.id}>
                  <Card>
                    <CardActionArea onClick={() => handleOpenTermModal(term)}>
                      <Box style={{
                        backgroundColor: theme.palette.primary.main,
                        height: 120,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: 20,

                      }}>
                        <MdContentPaste className={classes.iconContentPaste} />
                        <Typography style={{ fontSize: 20, fontFamily: 'Roboto', marginTop: 15, marginBottom: 15 }}>SSO</Typography>
                      </Box>

                      <CardContent>
                        <Typography className={classes.title}>{term?.termo?.titulo}</Typography>


                        <Box>

                          <Typography className={classes.infoText}>
                            <MdEvent size={16} style={{ marginRight: 5 }} /> Aceito em {term?.assinado_em && extractDateFormat(term?.assinado_em)}
                          </Typography>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
            {termsSSO?.results?.length ? (
              <PaginationService
                listTerms={termsSSO}
                getSearch={getSignedTermSSO}
              />
            ) : ''}
          </Container>)}
          {serviceSelected === 'ouvidoria' && (<Container>
            <Typography style={{ fontSize: 20, fontFamily: 'Roboto', marginTop: 15, marginBottom: 15 }}>Nenhum termo encontrado</Typography>
          </Container>)}
        </>
      )}
      {(termsSiseci?.results?.length || termsSSO?.results?.length) && loginType !== 'e-cnpj' ? (
        <TermoDialog
          term={termSelected}
          setOpen={setOpen}
          open={open}
        />
      ) : null}
    </>
  );
}
