import { Box, Theme, makeStyles } from '@material-ui/core';
import CallToActionOutlined from '@material-ui/icons/CallToActionOutlined';
import InsertInvitationOutlined from '@material-ui/icons/InsertInvitationOutlined';
import PersonOutline from '@material-ui/icons/PersonOutline';
import SettingsApplicationsOutlined from '@material-ui/icons/SettingsApplicationsOutlined';
import { Link } from 'react-router-dom';
import getCookie from '../../../../utils/getCookies';

const CardListActions = () => {
  const useStyles = makeStyles((theme: Theme) => ({
    containercard: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      gap: '16px',
    },
    cardBox: {
      flexGrow: 1,
      flexBasis: '204px',
      height: '184px',
      borderRadius: '8px',
      backgroundColor: '#fff',
      padding: '16px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      boxShadow: '0px 6px 12px 0px #010F1E4D',
      [theme.breakpoints.down('md')]: {
        flexBasis: '336px',
      },
      [theme.breakpoints.down('xs')]: {
        flexBasis: '100%',
        height: '104px',
      },
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'row',
      },
    },
    icon: {
      width: '32px',
      height: '32px',
      color: theme.palette.primary.main,
    },
    title: {
      fontWeight: 700,
      fontSize: '18px',
      color: '#212121',
      margin: 0,
      marginBottom: '8px',
    },
    description: {
      fontSize: '12px',
      color: '#212121',
      margin: 0,
      fontWeight: 400,
    },
    link: {
      display: 'flex',
      textDecoration: 'none',
      float: 'right',
      color: theme.palette.primary.main,
      justifyContent: 'flex-end',
    },
  }));

  const classes = useStyles();
  const currentProfile = getCookie('perfil');

  const listCards = [
    {
      icon: <PersonOutline style={{ fontSize: 32 }} />,
      title: 'Meu perfil',
      description: 'Altera os seus dados básicos.',
      action: 'Acessar perfil',
      link: '/workspace/minha-area/meus-dados',
    },
    {
      icon: <InsertInvitationOutlined style={{ fontSize: 32 }} />,
      title: 'Meus agendamentos',
      description: 'Veja os seus agendamentos.',
      action: 'Acessar agendamentos',
      link: '/workspace/meus_agendamentos',
    },
    {
      icon: <CallToActionOutlined style={{ fontSize: 32 }} />,
      title: 'Minhas ações',
      description: 'Veja todas as ações recentes.',
      action: 'Acessar minhas ações',
      link: '/workspace/atividades',
    },
    ...(currentProfile !== 'Empresa'
      ? [
          {
            icon: <SettingsApplicationsOutlined style={{ fontSize: 32 }} />,
            title: 'Meus Sistemas',
            description: 'Veja os sistemas que você possui acesso.',
            action: 'Acessar meus sistemas',
            link: '/workspace/minha-area/meus-sistemas',
          },
        ]
      : []),
  ];

  return (
    <div className={classes.containercard}>
      {listCards?.map(card => {
        return (
          <Box key={card.action} className={classes.cardBox}>
            <div className={classes.cardContent}>
              <div className={classes.icon}>{card.icon}</div>
              <div>
                <h2 className={classes.title}>{card.title}</h2>
                <h3 className={classes.description}>{card.description}</h3>
              </div>
            </div>
            <Link to={card.link || ''} className={classes.link}>
              {card.action}
            </Link>
          </Box>
        );
      })}
    </div>
  );
};

export default CardListActions;
