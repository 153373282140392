import jwt_decode from 'jwt-decode';
import getCookie from './getCookies';

interface DecodedToken {
  auth_type?: string;
}

export const getLoginTypeFromToken = (): string => {
  const token = getCookie('gov_access_token_sso');

  if (token) {
    try {
      const decoded: DecodedToken = jwt_decode(token);
      return decoded?.auth_type || 'default';
    } catch (error) {
      console.error('Erro ao decodificar o token:', error);
      return 'default';
    }
  } else {
    console.warn('Token não encontrado nos cookies.');
    return 'default';
  }
};
