export const limit = (
  str: string,
  limit = 130,
  suffix = ' [...]',
) => {
  return str.substring(0, limit) + suffix;
};

export const stripTag = (str: string) => {
  return str.replace(/<[^>]*>?/gm, '');
};

export const hidePartOfEmail = (email: string) => {
  const hide = email.split("@")[0].length - 2;
  const r = new RegExp(".{" + hide + "}@", "g");
  return email.replace(r, "***@");
};
