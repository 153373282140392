import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  OutlinedInput,
  Slider,
  Snackbar,
  Typography,
} from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { MdAddAPhoto, MdCheckCircleOutline } from 'react-icons/md';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router-dom';
import { Breadcrumb } from '../../Components';
import { LoginType, UserData } from '../../interfaces/user';
import {
  getImageProfile,
  sendImageProfile,
  updateContactInfo,
} from '../../services/auth';
import { formatCNPJ } from '../../utils/cnpjFormat';
import { cpfMask } from '../../utils/cpfFormat';
import getCookie from '../../utils/getCookies';
import { setCookie } from '../../utils/setCookie';
import { getCroppedImg } from './cropImage';
import { useStyles } from './style';

export default function MyData(): JSX.Element {
  const classes = useStyles();
  const [userData, setUserData] = useState<UserData | undefined>();
  const loginType = getCookie('loginType') as LoginType | null;
  const [image, setImage] = useState({ preview: '', raw: '' });
  const [crop, setCrop] = useState({ x: 100, y: 100 });
  const [savedImage, setSavedImage] = useState<boolean>(false);
  const [zoom, setZoom] = useState(1);
  const [blob, setBlob] = useState(null);
  const [avatarUser, setAvatarUser] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const userDataStorage: string | null = getCookie('gov_user_data');
  const token: string | null = getCookie('gov_access_token_sso');
  const [contactCell, setContactCell] = useState();
  const [open, setOpen] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'error' | 'warning' | 'info'
  >('success');

  const handleContactChange = event => {
    setContactCell(event.target.value);
  };

  const displayMessage = !userData?.contato?.celular;

  const history = useHistory();

  const handleChangeImage = e => {
    setOpenModal(true);
    const reader = new FileReader();
    if (e.target.files.length) {
      const fileArquive = e.target.files[0];
      reader.addEventListener(
        'load',
        () => {
          setImage({
            preview: URL.createObjectURL(fileArquive),
            raw: reader.result as string,
          });
        },
        false,
      );
      e.target.value = '';
      if (fileArquive) {
        reader.readAsDataURL(fileArquive);
      }
    }
  };

  const onCropComplete = async (_, croppedAreaPixels) => {
    const croppedImage: Blob = await getCroppedImg(
      image.raw,
      croppedAreaPixels,
    );
    setBlob(URL.createObjectURL(croppedImage));
    const file = new File([croppedImage], `avatar-${Date.now()}.png`, {
      lastModified: new Date().getTime(),
      type: 'image/png',
    });

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onloadend = () => {
      setFile(file);
    };
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      if (file) {
        const imageResponse = await sendImageProfile(token, file);
        if (imageResponse) {
          setSavedImage(true);
        }
      }
      console.log(contactCell, userData.contato.celular);
      if (contactCell !== userData.contato.celular) {
        await updateContactInfo(token, userData.contato.id, {
          celular: contactCell,
        })
          .then(() => {
            const updatedUserData = {
              ...userData,
              contato: {
                ...userData.contato,
                celular: contactCell,
              },
            };
            setUserData(updatedUserData);

            setCookie('gov_user_data', JSON.stringify(updatedUserData), 7);

            setSnackbarMessage('Contato atualizado com sucesso!');
            setSnackbarSeverity('success');
            setOpenSnackbar(true);
          })
          .catch(error => {
            setSnackbarMessage('Erro ao atualizar o contato!');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
          });
      }
    } catch (error) {
      console.error('Erro ao salvar:', error);
    } finally {
      setLoading(false);
    }
  };

  const isSaveEnabled = Boolean(file || contactCell);

  useEffect(() => {
    if (userDataStorage) {
      const parsedUserData = JSON.parse(userDataStorage);
      setUserData(parsedUserData);

      if (parsedUserData.contato?.celular) {
        setContactCell(parsedUserData.contato.celular);
      }
    }
  }, [userDataStorage]);

  useEffect(() => {
    const handleGetImageProfile = async () => {
      const { data } = await getImageProfile(token);

      if (data?.results?.length > 0) {
        const image = data.results[0].arquivo;
        setAvatarUser(image);
      }
    };
    handleGetImageProfile();
  }, [token]);

  const linksBreadcrumb = [
    {
      name: 'Meu Perfil',
      redirectTo: '#',
    },
  ];

  const getLatestUserLevel = (users): string => {
    if (users.length === 0) {
      return 'Não informado';
    }

    const latestUser = users.reduce(
      (latest, user) =>
        new Date(user.dataAtualizacao) > new Date(latest.dataAtualizacao)
          ? user
          : latest,
      users[0],
    );

    switch (latestUser.id) {
      case '1':
        return 'Bronze';
      case '2':
        return 'Prata';
      case '3':
        return 'Ouro';
      default:
        return 'Não informado';
    }
  };

  return (
    <>
      <Dialog open={savedImage} onClose={() => history.go(0)}>
        <DialogContent>
          <MdCheckCircleOutline
            style={{
              width: 60,
              height: 60,
              color: '#4bbd7e',
              textAlign: 'center',
            }}
          />
          <Typography
            style={{ color: '#333', fontFamily: 'Roboto', fontSize: 22 }}
          >
            Imagem de perfil salva com sucesso!!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.buttonSave}
            onClick={() => history.go(0)}
            variant='contained'
            color='primary'
            size='small'
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      {image.preview && (
        <Dialog open={openModal}>
          <DialogContent>
            <Box width={500} height={474}>
              <Cropper
                image={image.preview}
                crop={crop}
                zoom={zoom}
                cropSize={{ width: 300, height: 300 }}
                cropShape='round'
                showGrid={false}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                maxZoom={5}
              />
            </Box>
          </DialogContent>
          <DialogActions
            style={{
              backgroundColor: '#fff',
              display: 'flex',
              zIndex: 2,
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box width='100%' padding='30px 50px'>
              <Slider
                step={1}
                max={5}
                min={1}
                value={zoom}
                onChange={(e, newValue: any) => setZoom(newValue)}
                aria-labelledby='continuous-slider'
              />
            </Box>
            <Box display='flex' alignSelf='flex-end'>
              <Button
                onClick={() => {
                  setOpenModal(false);
                  setImage({
                    preview: '',
                    raw: '',
                  });
                  setZoom(1);
                  setBlob(null);
                }}
                className={classes.buttonReturn}
                variant='outlined'
              >
                Cancelar
              </Button>
              <Button
                autoFocus
                onClick={() => setOpenModal(false)}
                className={classes.buttonSave}
                variant='contained'
                color='primary'
                size='small'
              >
                Salvar
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
      <Breadcrumb links={linksBreadcrumb} panel={false} />
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box className={classes.boxIndicatorsBack}>
              <Box className={classes.indicatorsBack}>
                <Typography style={{ fontFamily: 'Roboto', fontWeight: 700 }}>
                  Meu Perfil
                </Typography>
              </Box>
              <a href='/workspace' className={classes.goBackLink}>
                <ArrowBackIos fontSize='small' />
                Voltar
              </a>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Collapse in={open} className={classes.alertContainer}>
              {loginType === 'e-cpf' ? (
                <Alert
                  style={{ backgroundColor: '#fff' }}
                  className={classes.alertCustom}
                  severity='warning'
                  onClose={() => {
                    setOpen(false);
                  }}
                >
                  <Typography
                    variant='h6'
                    component='h4'
                    className={classes.alertTitle}
                  >
                    Você realizou seu acesso pelo Certificado Digital e-CPF
                  </Typography>
                </Alert>
              ) : loginType === 'e-cnpj' ? (
                <Alert
                  style={{ backgroundColor: '#fff' }}
                  className={classes.alertCustom}
                  severity='warning'
                  onClose={() => {
                    setOpen(false);
                  }}
                >
                  <Typography
                    variant='h6'
                    component='h4'
                    className={classes.alertTitle}
                  >
                    Você realizou seu acesso pelo Certificado Digital
                  </Typography>
                </Alert>
              ) : (
                <Alert
                  onClose={() => {
                    setOpen(false);
                  }}
                  style={{ backgroundColor: '#fff' }}
                  className={classes.alertCustom}
                  severity='warning'
                >
                  <Typography
                    variant='h6'
                    component='h4'
                    className={classes.alertTitle}
                  >
                    Você realizou seu acesso pelo Gov.Br
                  </Typography>
                  Para alterar suas informações de e-mail e celular, você
                  precisa realizar a alteração diretamente no Gov.Br acessando o
                  endereço{' '}
                  <a
                    href='https://contas.acesso.gov.br/alteracao_cadastro'
                    target='_blank'
                    rel='noreferrer'
                  >
                    https://contas.acesso.gov.br/alteracao_cadastro
                  </a>
                  . Após a alteração, faça um novo login em nosso portal para
                  recebermos a atualização.
                </Alert>
              )}
            </Collapse>
            <Card className={classes.cardData}>
              <CardContent>
                <Box display='flex' flexDirection='column' alignItems='center'>
                  <Box className={classes.boxAvatar}>
                    <Avatar
                      src={blob || avatarUser}
                      className={classes.avatar}
                    />
                  </Box>
                  <Typography className={classes.userName}>
                    {userData?.nome || 'Não informado'}
                  </Typography>
                  <Typography className={classes.userCpf}>
                    {loginType === 'e-cnpj'
                      ? formatCNPJ(userData?.empresas[0].cnpj || '') ||
                        'CNPJ não informado'
                      : cpfMask(userData?.cpf) || 'Não informado'}
                  </Typography>
                  <div style={{ paddingBottom: '16px' }}>
                    <input
                      accept='image/*'
                      className={classes.input}
                      id='contained-button-file'
                      onChange={handleChangeImage}
                      type='file'
                    />
                    <label
                      htmlFor='contained-button-file'
                      className={classes.labelButton}
                    >
                      <Button
                        variant='outlined'
                        color='primary'
                        component='span'
                        startIcon={<MdAddAPhoto size={20} />}
                        className={classes.button}
                      >
                        ALTERAR FOTO
                      </Button>
                    </label>
                  </div>
                </Box>
                <div className={classes.inputForm}>
                  <FormControl variant='outlined' fullWidth>
                    <Typography
                      variant='body2'
                      color='inherit'
                      style={{ marginBottom: 4 }}
                    >
                      Email
                    </Typography>
                    <OutlinedInput
                      id='outlined-adornment-email'
                      type='text'
                      autoFocus
                      className={classes.disabledInput}
                      value={userData?.contato?.email || 'Não informado'}
                      disabled
                      labelWidth={0}
                    />
                  </FormControl>
                </div>
                {loginType !== 'e-cnpj' && (
                  <Box className={classes.inputForm}>
                    <Typography
                      variant='body2'
                      color={displayMessage ? 'error' : 'inherit'}
                      style={{ marginBottom: 4 }}
                    >
                      Contato de celular
                    </Typography>
                    <FormControl
                      variant='outlined'
                      fullWidth
                      disabled={loginType === 'default'}
                      className={
                        loginType === 'default' && classes.disabledInput
                      }
                    >
                      <InputMask
                        mask='(99) 99999-9999'
                        value={contactCell}
                        onChange={handleContactChange}
                        maskChar=''
                      >
                        {() => (
                          <OutlinedInput
                            id='outlined-adornment-contact-cell'
                            type='text'
                            labelWidth={0}
                            className={`${classes.inputDefault} ${
                              displayMessage ? classes.errorBorder : ''
                            }`}
                          />
                        )}
                      </InputMask>
                    </FormControl>
                    {loginType === 'e-cpf' && (
                      <Typography
                        variant='body2'
                        color={displayMessage ? 'error' : 'inherit'}
                        style={{
                          marginTop: 2,
                          color: !displayMessage ? '#c3c0c0' : undefined,
                        }}
                      >
                        Atualize o seu celular para continuarmos com o acesso.
                      </Typography>
                    )}
                  </Box>
                )}
                <div className={classes.inputForm}>
                  <FormControl variant='outlined' fullWidth>
                    <Typography
                      variant='body2'
                      color='inherit'
                      style={{ marginBottom: 4 }}
                    >
                      Nível da conta Gov.Br:
                    </Typography>
                    <OutlinedInput
                      id='outlined-adornment-adornment-contact-cell'
                      type='text'
                      className={classes.disabledInput}
                      disabled
                      value={
                        getLatestUserLevel(userData?.govbr_niveis || []) ||
                        'Não informado'
                      }
                    />
                  </FormControl>
                </div>
                {/* <div className={classes.inputForm}>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel htmlFor='outlined-adornment-add-address'>
                      Adicionar endereço
                    </InputLabel>
                    <OutlinedInput
                      id='outlined-adornment-add-address'
                      type='text'
                      label='Adicionar endereço'
                      disabled
                      value={userData?.endereco || 'Não informado'}
                      endAdornment={
                        <InputAdornment position='end'>
                          <Edit color='action' />
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                  </FormControl>
                </div> */}
                <div className={classes.buttonReturnAndSave}>
                  <Button
                    variant='outlined'
                    className={classes.buttonReturn}
                    onClick={() => history.go(-1)}
                  >
                    VOLTAR
                  </Button>
                  {loading ? (
                    <CircularProgress color='primary' />
                  ) : (
                    <Button
                      variant='contained'
                      color='primary'
                      size='small'
                      disabled={!isSaveEnabled}
                      className={classes.buttonSave}
                      onClick={handleSave}
                    >
                      SALVAR
                    </Button>
                  )}
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
