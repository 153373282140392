import {
  Box,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { ArrowBackIos, SearchOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import useStyles from './style';

interface HeaderProps {
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Header = ({ onSearch }: HeaderProps) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Container className={classes.container}>
      <Grid container spacing={3} alignItems='center'>
        <Grid item xs={3}>
          <Box className={classes.boxTitle}>
            <Typography variant='h6' className={classes.title}>
              Meus Sistemas
            </Typography>
            <Typography variant='body2' className={classes.subtitle}>
              Sistemas de acesso ao servidor.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={8}>
          <TextField
            placeholder='Procure por algum sistema'
            fullWidth
            variant='outlined'
            className={classes.searchField}
            onChange={onSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <span className={classes.searchIcon}>
                    <SearchOutlined />
                  </span>
                </InputAdornment>
              ),
              className: classes.inputText,
              classes: {
                notchedOutline: classes.notchedOutline,
              },
            }}
          />
        </Grid>

        <Grid item xs={1} container>
          <Box className={classes.boxIndicatorsBack}>
            <Box className={classes.indicatorsBack}>
              <a href='/' className={classes.goBackLink}>
                <ArrowBackIos fontSize='small' />
                Voltar
              </a>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Header;
