import {
  Box,
  Container,
  Link,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { MdDone } from 'react-icons/md';
import { useLocation, useParams } from 'react-router-dom';
import EvaluationMainContent from '../../Components/EvaluationMainContent';
import LoadingCallback from '../../Components/LoadingCallback';
import {
  clearStorage,
  loginRedirectPrivider,
  logout,
} from '../../services/auth';
import {
  getAllEvaluationFlows,
  updatedEvaluationFlow,
} from '../../services/form-flow';
import getCookie from '../../utils/getCookies';

interface Params {
  id: string;
}

interface EvaluationDataFlow {
  avaliacao_atendimento: number;
  avaliacao_informacao: number;
  comentario_atendimento?: string;
  comentario_informacao?: string;
  created_at: string;
  id: string;
  pendente: boolean;
  flow: number;
  flow_orgao_nome: string;
  flow_protocolo: string;
  updated_at: string;
  user: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 10px',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
  },
  boxTitle: {
    display: 'flex',
    width: '100%',
    marginTop: 40,
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 24,
    color: '#333333',
  },
  boxInfoService: {
    marginTop: 80,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  infoService: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    width: '42.5rem',
    height: '3.5rem',
    borderRadius: 4,
    fontSize: theme.typography.pxToRem(14),
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  titleService: {
    margin: '26px 0px 8px 0px',
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 20,
    textAlign: 'center',
  },
  detailsService: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: 8,
  },
  ticket: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: 30,
  },
  boxEvaluation: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  evaluation: {
    margin: '16px 0px 200px 0px',
    width: 680,
    height: 618,
    borderRadius: 4,
    backgroundColor: theme.palette.text.primary,
  },
  infoSlider: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  evaluationService: {
    with: '100%',
    marginTop: 50,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 18,
    color: '#333333',
    textAlign: 'center',
  },
  boxRating: {
    margin: '16px 0px 24px 0px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  buttonRating: {
    padding: 14,
    margin: '0px 8px',
    borderRadius: 4,
    border: '1px solid #E1E6EF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textInfoRating: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 14,
    color: '#706E7A',
    marginTop: 20,
  },
  description: {
    width: '100%',
    marginTop: 20,
    maxWidth: 600,
    borderRadius: 4,
    minHeight: '142px auto',
    margin: '0px 10px',
    border: '1px solid #E1E6EF',
    fontFamily: 'Roboto',
  },
  buttonNext: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500,
    width: 94,
    height: 44,
    borderRadius: 4,
    textTransform: 'uppercase',
  },
  buttonReturn: {
    fontFamily: 'Roboto',
    width: 94,
    fontSize: 14,
    fontWeight: 500,
    height: 44,
    borderRadius: 4,
    textTransform: 'uppercase',
    backgroundColor: '#fff',
    marginRight: 10,
  },
  boxActions: {
    padding: '0px 40px',
    margin: '50px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  input: {
    color: '#333',
  },
  boxSuccess: {
    width: '100%',
    maxWidth: 680,
    marginTop: 60,
    marginBottom: 40,
    margin: 'auto',
    borderRadius: 4,
    flexDirection: 'column',
    backgroundColor: theme.palette.text.primary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  check: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0px',
  },
  boxIcon: {
    width: 56,
    height: 56,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
  },
  titleInfo: {
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: 16,
  },
  subTitleInfo: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 18,
    textAlign: 'center',
    margin: '0px 10px',
  },
  actions: {
    flexWrap: 'wrap',
    with: '100%',
    display: 'flex',
    padding: '0px 40px',
    marginBottom: 40,
    justifyContent: 'center',
  },
  buttonSchedule: {
    width: 185,
    fontSize: theme.typography.pxToRem(15),
    textTransform: 'uppercase',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    borderRadius: 4,
    padding: 10,
    margin: '10px 0px',
  },
  containerInformation: {
    margin: '40px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonReturnAll: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.primary.main,
    fontSize: 14,
    fontFamily: 'Roboto',
    fontWeight: 500,
    margin: '10px 0px',
  },
  iconStarChecked: {
    height: 24,
    width: 24,
    margin: '1rem',
  },
  iconStar: {
    height: 24,
    width: 24,
  },
  iconErrorInfo: {
    width: 35,
    height: 35,
    color: '#fff',
  },
}));

export function EvaluationFlow(): JSX.Element {
  const classes = useStyles();
  const { id }: Params = useParams();
  const [evaluationFlowListData, setEvaluationFlowListData] =
    useState<EvaluationDataFlow>();
  const [loading, setLoading] = useState<boolean>();
  const [loadingSave, setLoadingSave] = useState<boolean>();
  const [ratingService, setRatingService] = useState<number>(0);
  const [ratingAttendance, setRatingAttendance] = useState<number>(0);
  const [commentService, setCommentService] = useState<string>();
  const [commentAttendance, setCommentAttendance] = useState<string>();
  const [evaluationData, setEvaluationData] =
    useState<EvaluationDataFlow | null>(null);

  const location = useLocation();

  const loginRedirect = () => {
    clearStorage();
    const { pathname } = location;
    loginRedirectPrivider(pathname);
  };
  const tokenSSO: string | null = getCookie('gov_access_token_sso');
  const userData = getCookie('gov_user_data') || '';

  async function loadEvaluationFlows(id: string) {
    try {
      setLoading(true);
      const { data } = await getAllEvaluationFlows(id);

      if (Array.isArray(data) && data.length === 0) {
        logout();
      }

      setEvaluationFlowListData(data?.[0]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id && tokenSSO && userData) {
      loadEvaluationFlows(id);
    } else {
      loginRedirect();
    }
  }, [id, tokenSSO, userData]);

  async function handleEvaluationFlow() {
    if (!id || !ratingAttendance || !ratingService) {
      throw new Error('Dados não preenchidos.');
    }

    try {
      setLoadingSave(true);
      const { data } = await updatedEvaluationFlow(
        id,
        ratingService,
        ratingAttendance,
        commentService,
        commentAttendance,
      );

      setEvaluationData(data);
      setLoadingSave(false);
    } catch (error) {
      setLoadingSave(false);
      console.log(error);
    }
  }

  const handleRatingService = useCallback(
    (value: number) => setRatingService(value),
    [],
  );

  const handleRatingAttendance = useCallback(
    (value: number) => setRatingAttendance(value),
    [],
  );

  const handleServiceComments = useCallback(
    (event: any) => setCommentService(event.target.value),
    [],
  );

  const handleAttendanceComments = useCallback(
    (event: any) => setCommentAttendance(event.target.value),
    [],
  );

  return (
    <Container>
      {loading && <LoadingCallback isLoading={loading} />}

      {!loadingSave && evaluationFlowListData?.pendente === false && (
        <Box className={classes.boxSuccess}>
          <Box className={classes.check}>
            <Box
              className={classes.boxIcon}
              style={{ backgroundColor: '#0BB07B' }}
            >
              <MdDone style={{ color: '#fff', height: 30, width: 30 }} />
            </Box>
          </Box>
          <Box
            style={{
              margin: '40px 0px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography className={classes.titleInfo}>
              Avaliação realizada com sucesso!
            </Typography>
            <Typography className={classes.subTitleInfo}>
              Obrigado pela sua contribuição.
            </Typography>
          </Box>
          <Box
            width='100%'
            className={classes.actions}
            justifyContent='space-between'
          >
            <Link
              className={classes.buttonSchedule}
              href='/'
              style={{ fontSize: 15 }}
            >
              Ir para o portal
            </Link>
          </Box>
        </Box>
      )}

      {loading === false &&
        evaluationFlowListData?.pendente &&
        !evaluationData && (
          <EvaluationMainContent
            type='formflow'
            handleSetRatingService={handleRatingService}
            handleSetRatingAttendance={handleRatingAttendance}
            handleSetServiceComments={handleServiceComments}
            handleSetAttendanceComments={handleAttendanceComments}
            ratingService={ratingService}
            ratingAttendance={ratingAttendance}
            handleSaved={handleEvaluationFlow}
            isLoading={loadingSave}
          />
        )}
      {/* 
      {loading === false && !evaluationFlowListData && (
        <Box className={classes.boxSuccess}>
          <Box className={classes.check}
          >
            <Box className={classes.boxIcon}>
              <MdErrorOutline className={classes.iconErrorInfo} />
            </Box>
          </Box>
          <Box className={classes.containerInformation}>
            <Typography className={classes.titleInfo}>A avaliação não pode ser mais realizada!</Typography>
            <Typography className={classes.subTitleInfo}>O período de avaliação expirou.</Typography>
          </Box>
          <Box
            className={classes.actions}
          >
            <Link
              className={classes.buttonSchedule}
              href="/"
            >Ir para o portal</Link>
          </Box>
        </Box>
      )} */}
    </Container>
  );
}
