import {
  Box,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Pagination, PaginationItem } from '@material-ui/lab';

interface Props {
  getSearch: (page: number) => void;
  listTerms: {
    total_pages: number,
    current: number,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 62,
    marginBottom: 100,
    borderRadius: '50%',
    paddingTop:20,
    paddingBottom:20,
    color: '#fff',
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%',
    },
  },
  paginationItem: {
    color: '#9E9E9E',
  },
  paginationSelected: {
    color: '#fff',
  },
}));

export default function PaginationService({
  listTerms,
  getSearch,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <Pagination
        renderItem={(item) => (
          <PaginationItem
            {...item}
            classes={{ root: classes.paginationItem, selected: classes.paginationSelected }}
          />
        )}
        count={listTerms.total_pages}
        page={listTerms.current}
        onChange={(e, page) => {
          getSearch(page);
        }}
        color="primary"
      />
    </Box>
  );
}
