// src/hooks/useLoggedWithCertificado.ts
import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';
import getCookie from '../utils/getCookies';
import { setCookie } from '../utils/setCookie';

interface DecodedToken {
  auth_type?: string;
}

const useLoggedWithCertificado = () => {
  const [loginType, setLoginType] = useState<string | null>(null);

  useEffect(() => {
    const token = getCookie('gov_access_token_sso');

    if (token) {
      try {
        const decoded: DecodedToken = jwt_decode(token);
        const loginTypeDecoded = decoded?.auth_type || 'default';

        if (loginTypeDecoded === 'e-cnpj') {
          setCookie('perfil', 'Cidadão', 7);
        }

        setCookie('loginType', loginTypeDecoded, 7);
        setLoginType(loginTypeDecoded);
      } catch (error) {
        console.error('Erro ao decodificar o token:', error);
        setLoginType('default');
      }
    } else {
      console.warn('Token não encontrado nos cookies.');
      setLoginType('default');
    }
  }, []);

  return loginType;
};

export default useLoggedWithCertificado;
