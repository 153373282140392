export function formatCNPJ(value) {
    if (typeof value !== 'string') {
      return value;
    }
  
    let onlyNums = value.replace(/\D/g, ''); 
    
    if (onlyNums.length > 14) {
      onlyNums = onlyNums.slice(0, 14);
    }
  
    if (onlyNums.length !== 14) {
      return value;
    }
  
    return onlyNums.slice(0, 2) + '.' + 
           onlyNums.slice(2, 5) + '.' + 
           onlyNums.slice(5, 8) + '/' + 
           onlyNums.slice(8, 12) + '-' + 
           onlyNums.slice(12, 14);
  }
  