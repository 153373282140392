import { CircularProgress, FormHelperText, Link } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { MdDelete } from 'react-icons/md';
import { sendFile } from '../../../../services/form-flow';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#264259',
      },
      display: 'block',
    },
  },
  input: {
    color: theme.palette.primary.main,
    border: '1px solid #264259',
    borderRadius: '4px',
    fontSize: '16px',
    padding: '2px',
    width: '100%',
  },
  label: {
    display: 'block',
    marginBottom: theme.spacing(1),
  },
  boxFile: {
    display: 'flex',
    border: '1px solid #e0e0e0',
    justifyContent: 'space-between',
    padding: '10px',
    borderRadius: '10px',
  },
  button: {
    border: 'none',
    background: 'transparent',
    color: '#264259',
  },
  boxButton: {
    borderLeft: '1px solid #e0e0e0',
    paddingLeft: '10px',
  },
}));

type Props = {
  required?: boolean;
  id: string;
  label: string;
  value:
    | ''
    | {
        url: string;
        nome: string;
        id: string;
      };
  error?: boolean;
  onChange: (
    value:
      | ''
      | {
          url: string;
          nome: string;
          id: string;
        },
  ) => void;
  helperText?: string;
  disabled?: boolean;
};

export default function Arquivo(props: Props): JSX.Element {
  const classes = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [file, setFile] = React.useState<File | null>(null);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setLoading(true);
      const afile = event.target.files?.[0];
      setFile(afile);
      const { data } = await sendFile(afile, false);

      const dataSend = {
        url: data.arquivo,
        nome: afile.name,
        id: data.id,
      };
      props.onChange(dataSend);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemove = () => {
    setLoading(true);
    setFile(null);
    setLoading(false);
    props.onChange('');
  };

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <label htmlFor={props.id} className={classes.label}>
            {props.label.replace(/(<([^>]+)>)/gi, '')}
          </label>
          {file ? (
            <div className={classes.boxFile}>
              <div>
                {props.value ? (
                  <Link href={props.value?.url} target='_blank'>
                    {props.value?.nome}
                  </Link>
                ) : (
                  <span>{file?.name} </span>
                )}
              </div>
              <div className={classes.boxButton}>
                <button className={classes.button} onClick={handleRemove}>
                  <MdDelete size={16} />
                </button>
              </div>
            </div>
          ) : (
            <>
              {props.disabled && props.value ? (
                <div className={classes.boxFile}>
                  <Link href={props.value?.url} target='_blank'>
                    {props.value?.nome}
                  </Link>
                </div>
              ) : (
                <>
                  <input
                    type='file'
                    id={props.id}
                    className={classes.input}
                    name={props.id}
                    onChange={handleChange}
                    disabled={props.disabled}
                    required={props.required}
                  />
                  {props.helperText && (
                    <FormHelperText error={props.error}>
                      {props.helperText}
                    </FormHelperText>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
