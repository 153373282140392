import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '16px',
    marginBottom: '40px',
  },
  searchField: {
    width: '679px',
    borderRadius: '2px 0px 0px 0px',
    backgroundColor: '#fff',
    borderColor: '#DDDDDD',
  },
  inputText: {
    color: '#B0B0B0',
    backgroundColor: '#fff',
  },
  notchedOutline: {
    borderColor: '#DDDDDD',
  },
  searchIcon: {
    fontSize: '1.25rem',
    color: theme.palette.text.secondary,
  },
  boxTitle: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '24px',
    color: '#212121',
    lineHeight: '33.6px'
  },
  subtitle: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '14px',
    color: '#212121',
    lineHeight: '19.6px'
  },
  boxIndicatorsBack: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  indicatorsBack: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    color: theme.palette.primary.main,
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 24,
    height: 24,
    color: theme.palette.primary.main,
  },
  backText: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    color: theme.palette.primary.main,
    fontSize: '0.875rem',
  },
  goBackLink: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#004F9F',
    fontSize: '14px',
    fontWeight: 700,
    textTransform: 'none',
    padding: 0,
  }
}));

export default useStyles;
