import React, { useCallback, useMemo, useState } from 'react';

import {
  Box,
  IconButton,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
} from '@material-ui/core';
import { IoMdStar } from 'react-icons/io';
import { MdCancel, MdForwardToInbox, MdMoreVert } from 'react-icons/md';
import { RestitutionSelected } from '../..';
import { EvaluationDialog } from '../../../../Components/EvaluationDialog';

type EvaluationData = {
  avaliacao_atendimento: number;
  avaliacao_informacao: number;
  comentario_atendimento: null | string;
  comentario_informacao: null | string;
  id: string;
  pendente: boolean;
};

type RestitutionData = {
  anexos_restituicao: [];
  historico: number;
  id: string;
  respondido: boolean;
  resposta: string | null;
  solicitacao: number;
};

interface Props {
  handleOpenModal: () => void;
  currentStatus: string;
  onOpenModalCancel: () => void;
  request: any;
  setRequestId: (value: any) => void;
  evaluationData: EvaluationData;
  onLoadRequests: ({ page }) => void;
  restitutionData: RestitutionData[] | null;
  setDataRestitution?: (value: RestitutionSelected) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  boxActionsIcon: {
    width: 40,
    height: 40,
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 1,
  },
  icon: {
    height: 24,
    width: 24,
  },
  iconCancel: {
    height: 24,
    width: 24,
    color: '#f00',
  },
  links: {
    display: 'flex',
    gap: 10,
    fontFamily: 'Roboto',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    color: '#373F41',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.typography.pxToRem(13),
    color: '#373F41',
    fontSize: theme.typography.pxToRem(16),
    fontFamily: 'Roboto',
    fontWeight: 500,
  },
  menuIcon: {
    fontSize: theme.typography.pxToRem(20),
  },
}));

export default function ButtonActions({
  handleOpenModal,
  currentStatus,
  onOpenModalCancel,
  request,
  setRequestId,
  evaluationData,
  onLoadRequests,
  restitutionData,
  setDataRestitution,
}: Props): JSX.Element {
  const [openDialog, setOpenDialog] = useState(false);
  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false);
  }, []);

  const handleCloseMenuAndDialog = () => {
    setAnchorEl(null);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isActiveButtonCancel = useMemo(
    () =>
      currentStatus === 'Finalizado' ||
      currentStatus === 'Cancelado pelo atendente' ||
      currentStatus === 'Cancelado pelo usuário',
    [currentStatus],
  );

  const isActiveButtonEvaluation = useMemo(
    () => evaluationData && evaluationData.pendente,
    [evaluationData],
  );

  const onUpdatedList = useCallback(
    () => onLoadRequests({ page: 1 }),
    [onLoadRequests],
  );

  return (
    <>
      {(isActiveButtonEvaluation || !isActiveButtonCancel) && (
        <Box>
          <Box className={classes.boxActionsIcon}>
            <IconButton
              aria-label='comments'
              aria-haspopup='true'
              onClick={handleClick}
            >
              <MdMoreVert className={classes.icon} style={{ color: '#fff' }} />
            </IconButton>
          </Box>

          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {restitutionData?.map(restituicao => {
              if (!restituicao.respondido) {
                return (
                  <MenuItem
                    key={restituicao.id}
                    onClick={() => {
                      handleOpenModal();
                      setDataRestitution({
                        solicitacao: restituicao.solicitacao,
                        id: restituicao.id,
                      });
                    }}
                  >
                    <Link
                      href='#'
                      onClick={preventDefault}
                      className={classes.links}
                    >
                      <MdForwardToInbox className={classes.icon} />
                      Responder
                    </Link>
                  </MenuItem>
                );
              }
              return null;
            })}

            {evaluationData && evaluationData?.pendente && (
              <MenuItem
                className={classes.menuItem}
                onClick={handleCloseMenuAndDialog}
              >
                <IoMdStar className={classes.menuIcon} />
                <span>Avaliar consumo</span>
              </MenuItem>
            )}
            {!isActiveButtonCancel && (
              <MenuItem
                onClick={() => {
                  onOpenModalCancel();
                  setRequestId(request.id);
                }}
              >
                <Link className={classes.links}>
                  <MdCancel className={classes.iconCancel} />
                  Cancelar solicitação
                </Link>
              </MenuItem>
            )}
          </Menu>
        </Box>
      )}
      <EvaluationDialog
        open={openDialog}
        handleCloseOrOpenDialog={handleCloseDialog}
        type='formflow'
        evaluationIndicator={evaluationData?.id}
        handleUpdated={onUpdatedList}
      />
    </>
  );
}
