import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { FaCar, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { MdDone, MdOutlineQrCodeScanner } from "react-icons/md";
import { useHistory, useParams } from 'react-router-dom';
import { Breadcrumb } from '../../Components';
import RatingEvaluation from '../../Components/RatingEvaluation';
import { avaliacao_geral, getQrCodeServices } from '../../services/agendamento';
import { getService } from '../../services/servico';
import getCookie from '../../utils/getCookies';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
    minHeight: '94vh',
  },
  boxTitle: {
    display: 'flex',
    marginTop: 40,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 32,
    color: '#333333',
    marginBottom: 16,
    marginTop: 16,

  },
  iconQrCode: {
    fontSize: 60,
    color: theme.palette.primary.main,
  },
  textTotalServices: {
    fontSize: 16,
    fontWeight: 400,
    fontFamily: "Roboto",
    color: "#333333",
    marginBottom: 16,
  },
  boxService: {
    minHeight: 91,
    marginBottom: 8,
    width: "100%",
    maxWidth: 800,
    boxShadow: "0px 85px 34px rgba(60, 76, 112, 0.01), 0px 21px 21px rgba(60, 76, 112, 0.09)",
    alignSelf: "center",
    margin: 'auto',
    boxSizing: 'border-box',
    backgroundColor: '#FFFFFF',

    color: "#333333",
    borderRadius: 8,

  },
  boxTitleService: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    minHeight: 91,
    borderRadius: 8,
    cursor: 'pointer',
    "&:hover": {
      background: theme.palette.primary.main,
      color: '#FFFFFF',
      "& $iconCircle": {
        backgroundColor: '#FFFFFF',
        "& $iconService": {
          color: theme.palette.primary.main,
        },
      },
      "& $boxIconChevron": {
        color: '#FFFFFF',
      },
    },

  },
  boxTitleServiceSelected: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    minHeight: 91,
    borderRadius: "8px 8px 0px 0px",
    cursor: 'pointer',
    background: theme.palette.primary.main,
    color: '#FFFFFF',
    "& $iconCircle": {
      backgroundColor: '#FFFFFF',
      "& $iconService": {
        color: theme.palette.primary.main,
      },
    },
    "& $boxIconChevron": {
      color: '#FFFFFF',
    },
  },
  boxTextIconService: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
  },
  boxIconService: {
    display: 'flex',
    width: 80,
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
  },
  iconCircle: {
    display: 'flex',
    width: 40,
    height: 40,
    borderRadius: 40,
    backgroundColor: theme.palette.primary.main,
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
  },
  iconService: {
    fontSize: 18,
    color: "#FFFFFF",
  },
  textService: {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: "Roboto",

  },
  subTextService: {
    fontSize: 14,
    fontWeight: 300,
    fontFamily: "Roboto",
  },
  boxIconChevron: {
    display: 'flex',
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    width: 40,
    marginRight: 16,
    color: '#706E7A',
  },
  boxEvaluation: {
    display: 'flex',
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    width: '100%',
    flexDirection: 'column',
    padding: 40,
  },
  description: {
    width: '100%',
    maxWidth: 640,
    borderRadius: 4,
    minHeight: '142px auto',
    border: '1px solid #E1E6EF',
    fontFamily: 'Roboto',
  },
  input: {
    color: '#333',
  },
  boxSelectRating: {
    display: 'flex',
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    flexDirection: 'column',
  },
  titleSelectRating: {
    fontSize: 18,
    fontFamily: "Roboto",
    fontWeight: 500,
    color: "#333333",
  },
  boxRating: {
    marginTop: 20,
    marginBottom: 20,
  },
  boxDescription: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  buttonNext: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500,
    width: 174,
    height: 44,
    borderRadius: 4,
    marginTop: 46,

  },
  boxSuccess: {
    width: '100%',
    maxWidth: 680,
    marginTop: 60,
    marginBottom: 40,
    margin: 'auto',
    borderRadius: 4,
    flexDirection: 'column',
    backgroundColor: theme.palette.text.primary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  check: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '50%',
    padding: 8,
    backgroundColor: "rgba(36, 147, 70, 0.1)"
  },
  boxIcon: {
    width: 56,
    height: 56,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
  },
  checkIcon: {
    color: '#FFFFFF',
    fontSize: 32,
  },
  boxTitleInfo: {
    margin: '32px 0px 40px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'

  },
  titleInfo: {
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: 16,
  },
  subTitleInfo: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: 16,
    textAlign: 'center',
    margin: '0px 10px',
  },
  actions: {
    with: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

  },
  buttonReturnAll: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.primary.main,
    fontSize: 15,
    fontFamily: 'Roboto',
    fontWeight: 500,
    textTransform: 'uppercase',
    textDecoration: 'none',
  },
  buttonSchedule: {
    width: 291,
    fontSize: 14,
    textTransform: 'uppercase',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    borderRadius: 4,
    padding: 10,
    marginBottom: 40,
  },
}));

type Service = {
  id: number;
  slug: string;
  titulo: string;
  avaliacao_informacao?: number;
  avaliacao_consumo?: number;
  avaliacao_atendimento?: number;
  comentario?: string;
  avaliado?: boolean;
};

type ServiceList = Service[];

export default function ServiceSearchEvaluationByQrCode(): JSX.Element {

  const { slug, ticket_qr } = useParams<{ slug?: string; ticket_qr?: string }>();

  const matchesMobile = useMediaQuery("(max-width:458px)");
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const token: string | null = getCookie('gov_access_token_sso');
  const [message, setMessage] = useState<{
    message: string;
    type: 'error' | 'success' | 'info' | 'warning' | undefined;
  }>({
    message: '',
    type: 'success',
  });
  const [isOk, setIsOk] = useState<boolean>(false);
  const [openEvaluation, setOpenEvaluation] = useState(-1);
  const [listServices, setListServices] = useState<ServiceList>([]);
  const [subTitle, setSubTitle] = useState<string>('');
  const linksBreadcrumb = [
    {
      name: "Meus Agendamentos",
      redirectTo: "/workspace/meus_agendamentos"
    },
    {
      name: "Avaliação de atendimento presencial",
      redirectTo: "#",
    }
  ];


  const getQrCode = async () => {
    try {
      setLoading(true);

      if (slug) {
        const { data } = await getService(slug);
        if (data?.results?.length > 0) {
          setSubTitle(data.results[0]?.descricao || '');
          setListServices([
            {
              ...data.results,
              avaliado: false,
              avaliacao_atendimento: 0,
              avaliacao_consumo: 0,
              avaliacao_informacao: 5,
              comentario: '',
            }
          ]);
        }
      } else {
        const { data } = await getQrCodeServices({
          id: ticket_qr
        });
        if (data?.results?.length > 0) {
          setSubTitle(data.results[0]?.descricao || '');
          setListServices(
            data.results[0]?.lista_servicos.map((serv) => ({
              ...serv,
              avaliado: false,
              avaliacao_atendimento: 0,
              avaliacao_consumo: 0,
              avaliacao_informacao: 5,
              comentario: '',
            }))
            || []);
        }
      }

    } catch (error) {
      setMessage({
        message: 'Algo inesperado ocorreu ao carregar lista de serviços',
        type: 'error',
      });

    } finally {
      setLoading(false);
    }

  };

  const handleSelectService = (index: number) => {
    if (openEvaluation === index) {
      setOpenEvaluation(-1);
    } else {
      setOpenEvaluation(index);
    }
  }

  const handleSendEvaluation = async (service: Service, index: number) => {
    try {
      setLoading(true);
      await avaliacao_geral({
        servico: slug ? service[0].id : service.id,
        avaliacao_informacao: service.avaliacao_informacao,
        comentario_informacao: service.comentario,
        avaliacao_consumo: service.avaliacao_consumo,
        comentario_consumo: service.comentario,
        avaliacao_atendimento: service.avaliacao_atendimento,
        comentario_atendimento: service.comentario,
      });
      const newList = listServices;
      newList[index].avaliado = true;
      setListServices([...newList]);
      setIsOk(true);
    } catch (error: any) {

      if (error?.response?.status === 403) {
        setMessage({
          message: error?.response?.data?.detail || 'Algo inesperado ocorreu',
          type: 'error',
        });

        const newList = listServices;
        newList[index].avaliado = true;
        setListServices([...newList]);


      } else {

        setMessage({
          message: 'Algo inesperado ocorreu',
          type: 'error',
        });
      }



    }
    finally {
      setLoading(false);
    }

  }

  useEffect(() => {
    getQrCode();
  }, []);

  if (loading) {
    return (<Box className={classes.main}>
      <CircularProgress />
    </Box>);
  }

  return (
    <>
      {token && <Breadcrumb links={linksBreadcrumb} />}
      <Box className={classes.main}>
        <Snackbar
          autoHideDuration={3000}
          onClose={(): void => setMessage({ message: '', type: message.type })}
          open={!!message.message}
        >
          <Alert
            onClose={(): void => setMessage({ message: '', type: message.type })}
            severity={message.type}
          >
            {message.message}
          </Alert>
        </Snackbar>
        {!isOk ? (
          <>
            <Box className={classes.boxTitle}>
              <MdOutlineQrCodeScanner className={classes.iconQrCode} />
              <Typography className={classes.title}>Avaliação de atendimento</Typography>
            </Box>
            <Typography className={classes.textTotalServices}>LISTA DE SERVIÇOS ({listServices.length})</Typography>
            <Box
              width={'100%'}
            >
              {listServices.map((service, index) => (
                <Box className={classes.boxService} key={index}>
                  <Box className={openEvaluation === index ? classes.boxTitleServiceSelected : classes.boxTitleService} onClick={() => handleSelectService(index)}>
                    <Box className={classes.boxTextIconService}>
                      <Box className={classes.boxIconService}>
                        <Box className={classes.iconCircle}>
                          <FaCar className={classes.iconService} />
                        </Box>
                      </Box>
                      <Box>
                        <Box>
                          <Typography className={classes.textService}>{slug ? service[0].titulo : service.titulo}</Typography>
                          <Typography className={classes.subTextService}>{slug ? service[0].orgao_nome : subTitle}</Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box className={classes.boxIconChevron}>
                      {openEvaluation === index ? (<FaChevronUp size={12} />) : (<FaChevronDown size={12} />)}
                    </Box>
                  </Box>
                  <Collapse in={openEvaluation === index}>
                    <Box className={classes.boxEvaluation}>
                      <Box className={classes.boxSelectRating}>
                        <Typography className={classes.titleSelectRating}>1. Como você avalia sua experiência com o consumo do serviço?</Typography>
                        <Box className={classes.boxRating}>
                          <RatingEvaluation value={service.avaliacao_consumo} readOnly={service.avaliado} onChange={(event, newValue) => {
                            if (newValue) {

                              const newList = listServices;
                              newList[index].avaliacao_consumo = newValue;
                              setListServices([...newList]);
                            }
                          }} />
                        </Box>
                      </Box>
                      <Box className={classes.boxSelectRating}>
                        <Typography className={classes.titleSelectRating}>2. Como vcê avalia sua experiência com o atendimento?</Typography>
                        <Box className={classes.boxRating}>
                          <RatingEvaluation value={service.avaliacao_atendimento} readOnly={service.avaliado} onChange={(event, newValue) => {

                            if (newValue) {
                              const newList = listServices;
                              newList[index].avaliacao_atendimento = newValue;
                              setListServices([...newList]);
                            }
                          }} />
                        </Box>
                      </Box>
                      <Box className={classes.boxDescription}>
                        <TextField
                          className={classes.description}
                          id="outlined-multiline-static"
                          label={`Deixe seu comentário ${(service.avaliacao_consumo === 0 && service.avaliacao_atendimento === 0) ? '' :
                            ((service.avaliacao_consumo > 0 && service.avaliacao_consumo <= 3) ||
                              (service.avaliacao_atendimento > 0 && service.avaliacao_atendimento <= 3)) ?
                              '(obrigatório) (Mínimo 10 caracteres)' : '(opcional)'
                            }`}
                          multiline
                          variant="outlined"
                          rows={5}
                          onChange={(e) => {

                            const newList = listServices;
                            newList[index].comentario = e.target.value;
                            setListServices([...newList]);
                          }}
                          InputProps={{
                            className: classes.input,
                          }}
                          disabled={service.avaliado}
                          value={service.comentario}
                        />
                      </Box>
                      <Box>
                        <Button className={classes.buttonNext}
                          variant='contained'
                          color='primary'
                          size='small'
                          disabled={service.avaliado ||
                            (service.avaliacao_consumo === 0 || service.avaliacao_atendimento === 0)
                            || (service.avaliacao_consumo <= 3 && service.comentario.length < 10)
                            || (service.avaliacao_atendimento <= 3 && service.comentario.length < 10)
                          }
                          onClick={() => handleSendEvaluation(service, index)}
                        >
                          Enviar avaliação
                        </Button>
                      </Box>
                    </Box>
                  </Collapse>
                </Box>
              ))}
            </Box>
          </>) : (<>

            <Box className={classes.boxSuccess}>
              <Box className={classes.check}
              >
                <Box className={classes.boxIcon}>
                  <MdDone className={classes.checkIcon} />
                </Box>
              </Box>
              <Box className={classes.boxTitleInfo}>
                <Typography className={classes.titleInfo}>Avaliação Enviada!</Typography>
                <Typography className={classes.subTitleInfo}>
                  {
                    slug
                      ? 'Agradecemos por compartilhar seu feedback conosco.'
                      : (
                        <>
                          Agradecemos por compartilhar seu feedback conosco, para continuar avaliando mais serviços você pode clicar em
                          <strong> Verificar novamente o qr code. </strong>
                        </>
                      )
                  }
                </Typography>
              </Box>
              <Box
                className={classes.actions}
                justifyContent={matchesMobile || !token ? 'center' : 'space-between'}
              >

                <Button
                  className={classes.buttonSchedule}
                  variant='contained'
                  color='primary'
                  size='small'
                  onClick={() => setIsOk(false)}
                >VERIFICAR NOVAMENTE O QR CODE</Button>

                <Button
                  className={classes.buttonReturnAll}
                  onClick={() => history.push('/')}

                >
                  Ir para o portal
                </Button>

              </Box>
            </Box>

          </>)}

      </Box>
    </>
  )
}