// DigitalAccessArea.tsx

import { Collapse, Container, Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useEffect, useMemo, useState } from 'react';
import { Breadcrumb } from '../../Components';
import PortalCard from '../../Components/CardInstitutionalSystems';
import {
  addFavoriteSystem,
  getFavoriteInstitutionalSystems,
  getInstitutionalSystems,
  removeFavoriteSystem,
} from '../../services/servico';
import getCookie from '../../utils/getCookies';
import Header from './components/Header';
import useStyles from './style';

export interface InstitutionalSystem {
  id: number;
  nome: string;
  url: string;
  ativo: boolean;
  logo_sistema: string;
}

interface Favorite {
  systemId: number;
  favoriteId: number;
}

const DigitalAccessArea = () => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const currentProfile = getCookie('perfil');
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [favorites, setFavorites] = useState<Favorite[]>([]);
  const [institutionalSystems, setInstitutionalSystems] = useState<
    InstitutionalSystem[]
  >([]);

  const fetchInstitutionalSystems = async () => {
    const tokenSiseci: string | null = getCookie('gov_access_token_sso');
    if (tokenSiseci) {
      setLoading(true);
      setError(null);
      try {
        const response = await getInstitutionalSystems(
          { profile: currentProfile },
          tokenSiseci,
        );
        setInstitutionalSystems(response.data);
      } catch (error) {
        console.error('Erro ao buscar sistemas institucionais:', error);
        setError('Erro ao buscar sistemas institucionais.');
      } finally {
        setLoading(false);
      }
    } else {
      setError('Token de acesso não disponível.');
    }
  };

  const fetchFavoriteInstitutionalSystems = async () => {
    const tokenSiseci: string | null = getCookie('gov_access_token_sso');
    if (tokenSiseci) {
      try {
        const response = await getFavoriteInstitutionalSystems(tokenSiseci);

        const favoriteResults = response.data.results || [];
        const favorites = favoriteResults.map((fav: any) => ({
          systemId: fav.sistema_institucional.id,
          favoriteId: fav.id,
        }));
        setFavorites(favorites);
      } catch (error) {
        console.error(
          'Erro ao buscar sistemas institucionais favoritos:',
          error,
        );
        setError('Erro ao buscar sistemas institucionais favoritos.');
      }
    } else {
      setError('Token de acesso não disponível.');
    }
  };

  useEffect(() => {
    fetchInstitutionalSystems();
    fetchFavoriteInstitutionalSystems();
  }, [currentProfile]);

  const filteredAndSortedPortals = useMemo(() => {
    const filtered = institutionalSystems.filter(portal =>
      portal.nome.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    const sorted = [...filtered].sort((a, b) => {
      const aFav = favorites.some(fav => fav.systemId === a.id);
      const bFav = favorites.some(fav => fav.systemId === b.id);
      if (aFav && !bFav) return -1;
      if (!aFav && bFav) return 1;
      return 0;
    });

    return sorted;
  }, [institutionalSystems, searchTerm, favorites]);

  const handleFavoriteToggle = async (idCard, newFavoriteStatus) => {
    const tokenSiseci: string | null = getCookie('gov_access_token_sso');
    if (!tokenSiseci) {
      setError('Token de acesso não disponível.');
      return;
    }

    if (newFavoriteStatus) {
      try {
        const response = await addFavoriteSystem(idCard, tokenSiseci);
        const newFavoriteId = response.data.id;
        setFavorites(prevFavorites => [
          ...prevFavorites,
          { systemId: idCard, favoriteId: newFavoriteId },
        ]);
      } catch (error) {
        console.error('Erro ao adicionar favorito:', error);
        setError('Erro ao adicionar favorito.');
      }
    } else {
      const favoriteToRemove = favorites.find(fav => fav.systemId === idCard);
      if (favoriteToRemove) {
        try {
          await removeFavoriteSystem(favoriteToRemove.favoriteId, tokenSiseci);
          setFavorites(prevFavorites =>
            prevFavorites.filter(fav => fav.systemId !== idCard),
          );
        } catch (error) {
          console.error('Erro ao remover favorito:', error);
          setError('Erro ao remover favorito.');
        }
      }
    }
  };

  const linksBreadcrumb = [
    {
      name: 'Meus Sistemas',
      redirectTo: '/workspace/meus-sistemas',
    },
  ];

  return (
    <>
      <Breadcrumb links={linksBreadcrumb} panel={false} />
      <Header onSearch={e => setSearchTerm(e.target.value)} />
      <Container>
        <Collapse in={open}>
          <Alert
            style={{ backgroundColor: '#fff' }}
            className={classes.alertCustom}
            severity='warning'
            onClose={() => {
              setOpen(false);
            }}
          >
            <Typography
              variant='h6'
              component='h4'
              className={classes.alertTitle}
            >
              O Acesso a Serviços não disponível
            </Typography>
            Seu acesso ao sistema foi realizado pelo Certificado Digital, alguns
            serviços não estarão disponíveis, para acesso total ao sistema por
            favor realize o seu acesso pelo endereço{' '}
            <a
              href='https://contas.acesso.gov.br/alteracao_cadastro'
              target='_blank'
              rel='noreferrer'
            >
              acesso.gov.br
            </a>
          </Alert>
        </Collapse>
      </Container>
      <Container>
        {loading ? (
          <Typography align='center' variant='body1' color='textSecondary'>
            Carregando sistemas...
          </Typography>
        ) : error ? (
          <Typography align='center' variant='body1' color='error'>
            {error}
          </Typography>
        ) : (
          <Grid container spacing={4}>
            {filteredAndSortedPortals.length > 0 ? (
              filteredAndSortedPortals.map(portal => {
                const favorite = favorites.find(
                  fav => fav.systemId === portal.id,
                );
                const isFavorite = !!favorite;
                return (
                  <Grid item key={portal.id}>
                    <PortalCard
                      imageSrc={portal.logo_sistema}
                      title={portal.nome}
                      idCard={portal.id}
                      linkText='Acessar portal'
                      onClick={() =>
                        window.open(portal.url, '_blank', 'noopener,noreferrer')
                      }
                      isFavorite={isFavorite}
                      onFavoriteToggle={handleFavoriteToggle}
                    />
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={12}>
                <Typography
                  align='center'
                  variant='body1'
                  color='textSecondary'
                >
                  Nenhum sistema encontrado.
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Container>
    </>
  );
};

export default DigitalAccessArea;
