import { TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#264259',
      },
    },
  },
  inputColor: {
    color: theme.palette.primary.main,
  },
  inputRoot: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
  tag: {
    color: theme.palette.primary.main,
    fontSize: 14,
  },
}));

interface TextSelectProps {
  itens: { ordem: string; titulo: string }[];
  getOptionLabel: (option: any) => string;
  onChange: (value: any) => void;
  values?: any[];
}

const SelectAPI: React.FC<TextFieldProps & TextSelectProps> = props => {
  const classes = useStyles();

  const makeLabelOptions = (itens: any[]): Array<any> => {
    const options = [];
    itens.forEach(item => {
      for (const key in item) {
        options.push({
          value: item[key],
          label: item[key],
        });
      }
    });

    return options;
  };

  return (
    <Autocomplete
      id='tags-outlined'
      options={makeLabelOptions(props.itens ?? [])}
      classes={{
        inputRoot: classes.inputRoot,
        tag: classes.tag,
        option: classes.tag,
      }}
      getOptionLabel={option => props.getOptionLabel(option)}
      onChange={(e, v) => {
        console.log(v?.value);
        props.onChange(v?.value || '');
      }}
      defaultValue={props.values ?? []}
      filterSelectedOptions
      renderInput={params => (
        <TextField
          variant='outlined'
          placeholder='...'
          InputProps={{
            className: classes.inputColor,
          }}
          error={props.error}
          label={props.label}
          helperText={props.helperText}
          fullWidth
          {...params}
        />
      )}
    />
  );
};

export default SelectAPI;
