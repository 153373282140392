import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { addDays, isAfter } from 'date-fns';
import { FileObject } from 'material-ui-dropzone';
import { useEffect, useState } from 'react';
import { MdArrowBack, MdClose, MdMessage } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import {
  anexarArquivoResposta,
  atendimentoPorId,
  enviarMensagem,
  mensagemLida,
} from '../../services/atendimentos';
import { cpfMask } from '../../utils/cpfFormat';
import {
  Chat,
  DetailChat,
  ModalSendFiles,
  OrganSolicitation,
  SendMessage,
  Video,
} from './components';

import { Breadcrumb } from '../../Components';
import { AttendanceOnlineI } from '../../interfaces/atendimento';
import { UserData } from '../../interfaces/user';
import { extractDateFormat } from '../../utils/dateFormat';
import getCookie from '../../utils/getCookies';

const useStyles = makeStyles((theme: Theme) => ({
  boxIndicatorsBack: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0px',
  },
  indicatorsBack: {
    display: 'flex',
    width: '100%',
    color: '#373F41',
    maxWidth: '1250px',
    alignItems: 'center',
  },
  main: {
    width: '100%',
    minHeight: 400,
    display: 'flex',
    backgroundColor: '#fff',
    flexDirection: 'column',
    alignItems: 'center',
  },
  boxVideoChat: {
    display: 'flex',
    width: '100%',
    marginTop: 10,
    padding: 5,
    minHeight: 400,
  },
  buttonSwitch: {
    marginTop: 20,
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    display: 'flex',
  },
  button: {
    width: 48,
    height: 48,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 0,
    borderRadius: 0,
    border: '1px solid #0F6FB7',
    backgroundColor: '#0F6FB7',
    '&:hover': {
      backgroundColor: '#2985cc',
    },
  },
  textDateTime: {
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(14),
  },
  icon: {
    width: 24,
    height: 24,
  },
}));

interface Params {
  id: string;
}

export default function AttendanceOnlineChat(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const params: Params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [hideChat, setHideChat] = useState<boolean>(true);
  const [hideInfo, setHideInfo] = useState<boolean>(false);
  const [chatService, setChatService] = useState<AttendanceOnlineI>();
  const matches = useMediaQuery('(max-width:928px)');
  const matchesSm = useMediaQuery('(max-width:576px)');

  const [showSendArquive, setShowSendArquive] = useState<boolean>(false);
  const [files, setFiles] = useState<FileObject[]>([]);

  const [loadingSendFiles, setLoadingSendFiles] = useState<boolean>(false);
  const showVideo = true;

  const connect = () => {
    const baseWsUrl = atob(process.env.REACT_APP_WEB_SOCKET);
    // baseWsUrl = baseWsUrl.replace(/^https:\/\//i, 'wss://');

    if (!baseWsUrl) {
      return;
    }
    const ws = new WebSocket(`${baseWsUrl}/ws/${params.id}`);

    ws.onmessage = async (e: any) => {
      const dataReceive = JSON.parse(e.data);
      console.log('RECEIVED: ', dataReceive);
      if (dataReceive.event === 'INICIO') {
        const token: string | null = getCookie('gov_access_token_sso');
        await getAttendanceById(token, params.id);
      }
      if (dataReceive.event === 'MENSAGEM') {
        setChatService((chat): any => {
          const newchat: any = {
            ...chat,
            respostas: chat?.respostas.concat(dataReceive),
          };
          console.log(newchat);
          setChatService(newchat);
        });
      }
      if (dataReceive.event === 'FIM') {
        const token: string | null = getCookie('gov_access_token_sso');
        if (token) {
          const { data } = await atendimentoPorId(token, params.id);
          history.push(
            `/workspace/avaliar_atendimento_online/${data.avaliacao_online.id}`,
          );
        }
      }
    };

    ws.onclose = e => {
      console.log(
        'Socket is closed. Reconnect will be attempted in 1 second.',
        e.reason,
      );
      setTimeout(() => {
        connect();
      }, 1000);
    };

    ws.onerror = err => {
      console.error('Socket encountered error: ', err, 'Closing socket');
      ws.close();
    };

    ws.onopen = e => {
      console.log('connect');
    };
  };

  const getAttendanceById = async (token: string, id: string): Promise<any> => {
    setLoading(true);
    try {
      const { data } = await atendimentoPorId(token, id);
      setChatService(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const sendMessage = async (text: string): Promise<any> => {
    const token: string | null = getCookie('gov_access_token_sso');
    if (token && text) {
      try {
        const { data } = await enviarMensagem(token, params.id, text, false);
        const newchat: any = {
          ...chatService,
          respostas: chatService?.respostas.concat(data),
        };
        // console.log(data);
        setChatService(newchat);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const sendArquive = async () => {
    const token: string | null = getCookie('gov_access_token_sso');
    setLoadingSendFiles(true);
    if (token) {
      try {
        const { data } = await enviarMensagem(
          token,
          params.id,
          'Segue anexo: ',
          true,
        );
        const newResposta: any = data;

        for (const fileUpload of files) {
          const response = await anexarArquivoResposta(
            token,
            fileUpload.file.name,
            fileUpload.file.type,
            fileUpload.file,
            data.id,
          );
          newResposta.anexos.push(response.data);
        }
        const newchat: any = {
          ...chatService,
          respostas: chatService?.respostas.concat(newResposta),
        };
        setChatService(newchat);
        setShowSendArquive(false);
        setLoadingSendFiles(false);
      } catch (err) {
        setLoadingSendFiles(false);
      }
    }
    setLoadingSendFiles(false);
  };

  const readMessage = async (): Promise<any> => {
    const dataStorage: string | null = getCookie('gov_user_data');
    const token: string | null = getCookie('gov_access_token_sso');
    const userData: UserData | null = dataStorage
      ? JSON.parse(dataStorage)
      : null;
    if (chatService && userData) {
      // eslint-disable-next-line no-restricted-syntax
      for (const resposta of chatService.respostas) {
        const { lido, user, id } = resposta;
        if (!lido && user.username === userData.cpf && token) {
          // eslint-disable-next-line no-await-in-loop
          await mensagemLida(token, id);
        }
      }
    }
  };
  const handleToEvaluetion = (ticket: string) => {
    history.push(`/workspace/avaliar_atendimento_online/${ticket}`);
  };

  const handleEvaluationDate = (schedule: AttendanceOnlineI) => {
    if (!schedule?.avaliacao_online) {
      return;
    }
    const date = new Date(schedule.avaliacao_online.created_at);
    const now = new Date();
    const afterOneWeek = addDays(date, 7);
    if (isAfter(afterOneWeek, now) && schedule.avaliacao_online.pendente) {
      return (
        <Button
          color='primary'
          variant='outlined'
          onClick={() => handleToEvaluetion(schedule.avaliacao_online.id)}
          style={{ marginBottom: 20, width: '80%' }}
        >
          <Typography style={{ textTransform: 'uppercase' }}>
            Avaliar
          </Typography>
        </Button>
      );
    }
    return;
  };
  useEffect(() => {
    const token: string | null = getCookie('gov_access_token_sso');
    if (token) {
      getAttendanceById(token, params.id);
    } else {
      history.replace('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    if (chatService && chatService.tipo === 'Video') {
      if (matchesSm) {
        setHideChat(false);
      } else {
        setHideChat(true);
      }
    }
  }, [matchesSm, chatService]);

  useEffect(() => {
    connect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    readMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatService]);

  const handleRemoveChat = () => {
    setHideChat(!hideChat);
  };

  const handleHideInfo = () => {
    setHideInfo(!hideInfo);
  };

  const linksBreadcrumb = [
    {
      name: 'Minha área',
      redirectTo: '/workspace/minha-area',
    },
    {
      name: 'Atendimentos',
      redirectTo: '/workspace/meus_agendamentos',
    },
    {
      name: chatService?.status || '',
      redirectTo: '#',
    },
  ];

  return (
    <>
      <Breadcrumb links={linksBreadcrumb} />
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.boxIndicatorsBack}>
              <Box className={classes.indicatorsBack}>
                <IconButton
                  onClick={() => history.push('/workspace/meus_agendamentos')}
                >
                  <MdArrowBack className={classes.icon} />
                </IconButton>
                <Typography style={{ fontFamily: 'Roboto', fontWeight: 700 }}>
                  Atendimento online
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.main}>
              {chatService && !loading && (
                <>
                  <ModalSendFiles
                    open={showSendArquive}
                    handleClose={(): void => {
                      setShowSendArquive(false);
                    }}
                    files={files}
                    setFiles={setFiles}
                    sendArquive={sendArquive}
                    loadingFiles={loadingSendFiles}
                  />
                  <Box
                    maxWidth='1052px'
                    padding='20px'
                    display='flex'
                    alignItems='center'
                    width='100%'
                    flexWrap='wrap'
                  >
                    <Box width='100%' padding={matches ? '0px' : '10px'}>
                      <OrganSolicitation
                        organName={chatService.unidade.nome}
                        status={chatService.status}
                        buttonHideInfoChat={handleHideInfo}
                        hideInfo={hideInfo}
                      />
                      {hideInfo ? (
                        <>
                          <DetailChat
                            serviceName={chatService.servico.titulo}
                            dateSolicitation={extractDateFormat(
                              chatService.data,
                            )}
                            scheduleSolicitation={chatService.hora}
                          />
                          <Typography
                            className={classes.textDateTime}
                            style={{ marginBottom: 20, marginTop: 10 }}
                          >
                            Dependente:{' '}
                            <Typography component='strong'>
                              {chatService.dependentes ? 'Sim' : 'Não'}
                            </Typography>
                            {chatService.dependentes && (
                              <>
                                <Typography className={classes.textDateTime}>
                                  Nome:{' '}
                                  <Typography component='strong'>
                                    {chatService.dependentes.nome}
                                  </Typography>
                                </Typography>
                                <Typography className={classes.textDateTime}>
                                  CPF:{' '}
                                  <Typography component='strong'>
                                    {cpfMask(chatService.dependentes.cpf)}
                                  </Typography>
                                </Typography>
                                <Typography className={classes.textDateTime}>
                                  Grau de parentesco:{' '}
                                  <Typography component='strong'>
                                    {chatService.dependentes.grau_parentesco}
                                  </Typography>
                                </Typography>
                              </>
                            )}
                          </Typography>
                        </>
                      ) : (
                        ''
                      )}
                    </Box>

                    <Box className={classes.buttonSwitch}>
                      {chatService.tipo === 'Video' &&
                      chatService.status === 'Em Atendimento' ? (
                        <Button
                          onClick={() => handleRemoveChat()}
                          className={classes.button}
                          style={{
                            color: '#fffff',
                            backgroundColor: '#0F6FB7',
                            display: showVideo ? 'visible' : 'none',
                          }}
                        >
                          {hideChat ? (
                            <MdClose className={classes.icon} />
                          ) : (
                            <MdMessage className={classes.icon} />
                          )}
                        </Button>
                      ) : (
                        ''
                      )}
                    </Box>

                    <Box
                      className={classes.boxVideoChat}
                      style={{ display: matchesSm ? 'block' : 'flex' }}
                    >
                      {chatService.tipo === 'Video' &&
                      chatService.status === 'Em Atendimento' ? (
                        <Video
                          roomName={params.id}
                          hideChat={hideChat}
                          showVideo={showVideo}
                        />
                      ) : (
                        ''
                      )}
                      {hideChat ? (
                        <Box
                          style={
                            matchesSm &&
                            chatService.tipo === 'Video' &&
                            chatService.status === 'Em Atendimento'
                              ? {
                                  width: '100%',
                                  position: 'relative',
                                  top: -650,
                                  backgroundColor: '#fff',
                                }
                              : { width: '100%' }
                          }
                        >
                          <Chat chatService={chatService} />
                          {chatService.status === 'Em Atendimento' && (
                            <SendMessage
                              showSendArquive={(): void =>
                                setShowSendArquive(true)
                              }
                              sendMessage={sendMessage}
                            />
                          )}
                        </Box>
                      ) : (
                        ''
                      )}
                    </Box>
                  </Box>
                </>
              )}
              {loading && (
                <Box
                  marginBottom='60px'
                  flex='1'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                >
                  <CircularProgress />
                </Box>
              )}
              {chatService && handleEvaluationDate(chatService)}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
