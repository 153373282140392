import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { Link as LinkRouter, useLocation } from 'react-router-dom';
import {
  clearStorage,
  // logout,
  getImageProfile,
  getUserData as getUserDataRequest,
  loginRedirectPrivider,
  logout,
} from '../../services/auth';

import { Container } from '@material-ui/core';
import { IoMdSearch } from 'react-icons/io';
import { SearchServices } from '..';
import { LoginType, UserData } from '../../interfaces/user';
import { listLogos } from '../../services/noticias';
import { formatCNPJ } from '../../utils/cnpjFormat';
import { extractDateFormat, extractHourFormat } from '../../utils/dateFormat';
import getCookie from '../../utils/getCookies';
import { nameSplitOne, nameSplitTwo } from '../../utils/nameFormat';
import { setCookie } from '../../utils/setCookie';
import BadgeAvatars from '../BadgeAvatar';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '96px',
    background: theme.palette.primary.main,
    position: 'inherit',
    [theme.breakpoints.down('sm')]: {
      minHeight: '80px',
    },
  },
  text: {
    marginRight: 20,
  },
  textButton: {
    fontWeight: 700,
    fontStyle: 'normal',
    marginLeft: 3,
    fontFamily: 'Roboto',
  },
  textName: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  avatar: {
    height: 56,
    width: 56,
    backgroundColor: '#fff',
    color: '#004F9F',
  },
  logoImg: {
    width: '228px',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '152px',
      height: '100%',
    },
  },
  menuItem: {
    minHeight: 42,
    color: '#737B7D',
    minWidth: 236,
  },
  textMenuItem: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'normal',
    marginLeft: 10,
  },
  textCpf: {
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 300,
    textAlign: 'start',
  },
  searchBar: {
    flex: 0.4,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    textAlign: 'center',
    display: 'flex',
    paddingLeft: 8,
    color: '#ffffff',
    border: '1px #ffffff solid',
    alignContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  searchBarInput: {
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: theme.typography.pxToRem(16),
    color: '#FFF',
    minWidth: '18vw',
    height: 47,
    paddingLeft: 10,
    outline: 0,
  },
  navContent: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
    },
  },
  boxNotifications: {
    marginRight: 24,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  badge: {
    backgroundColor: '#F03D3D',
    position: 'absolute',
    top: '-5px',
    right: '-5px',
    minWidth: 20,
    width: 'auto',
    height: 20,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(12),
    color: '#fff',
  },
  icon: {
    color: '#fff',
    width: 32,
    height: 32,
  },
}));
export interface LogoData {
  titulo: string;
  url_imagem: string;
  url: string;
  tipo: string;
  sistema: string;
}

export default function Header(): JSX.Element {
  const classes = useStyles();
  const matchesMobile = useMediaQuery('(min-width:660px)');
  const [userData, setUserData] = useState<UserData | null>();
  const [, setMessage] = useState<string>('');
  const [avatarUser, setAvatarUser] = useState('');
  const [loading, setLoading] = useState(true);
  const [showSearch, setShowSearch] = useState(false);
  const [logo, setLogo] = useState<LogoData[]>();
  const loginType = getCookie('loginType') as LoginType | null;

  const location = useLocation();

  const token: string | null = getCookie('gov_access_token_sso');
  const tokenSiseci: string | null = getCookie('gov_access_token_sso');

  useEffect(() => {
    const { state } = location;
    if (state as any) {
      setMessage((state as any).message);
    }
  }, [location]);

  const getUserData = async (tokenSso: string, tokenSiseciReceive: string) => {
    try {
      const data = await getUserDataRequest(tokenSso, tokenSiseciReceive);
      const { data: Logos } = await listLogos();

      setLogo(Logos);
      setUserData(data);
      setCookie('gov_user_data', JSON.stringify(data));
    } catch (err) {
      clearStorage();
      if (err.response.data) {
        setMessage(err.response.data.detail);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token && tokenSiseci) {
      getUserData(token, tokenSiseci);
    }
  }, [token, tokenSiseci]);

  const logoutUser = () => {
    logout();
    setUserData(undefined);
  };

  const loginRedirect = () => {
    const { pathname } = location;
    loginRedirectPrivider(pathname);
  };

  useEffect(() => {
    const handleGetImageProfile = async () => {
      const { data } = await getImageProfile(token);

      if (data?.results?.length > 0) {
        const image = data.results[0].arquivo;
        setAvatarUser(image);
      }
    };
    handleGetImageProfile();
  }, [token]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box className={classes.main}>
      <Container className={classes.navContent}>
        <LinkRouter to='/'>
          <img
            alt={logo && logo[0].titulo}
            src={logo && logo[0].url_imagem}
            className={classes.logoImg}
          />
        </LinkRouter>

        <div style={{ marginTop: matchesMobile && '28px' }}>
          {matchesMobile && <SearchServices />}
        </div>

        <Box display='flex' alignItems='center' height={loading && '100px'}>
          {loading ? (
            <CircularProgress
              style={{
                width: 25,
                height: 25,
                color: '#fff',
              }}
            />
          ) : (
            <>
              {userData ? (
                <>
                  {/* <Box className={classes.boxNotifications}>
                    <MdCircleNotifications size={32} style={{ color: "#fff" }} />
                    <span className={classes.badge}>2</span>
                  </Box> */}
                  <Box
                    display='flex'
                    marginTop='12px'
                    marginBottom='5px'
                    flexDirection='row'
                    alignItems='center'
                  >
                    {matchesMobile ? (
                      <Box
                        display='flex'
                        flexDirection='column'
                        alignItems={isMobile ? 'center' : 'flex-end'}
                        marginRight={0}
                        textAlign={isMobile ? 'center' : 'right'}
                        style={{ gap: '3px' }}
                      >
                        {loginType === 'e-cnpj' ? (
                          <>
                            <Typography
                              className={classes.textName}
                              color='textPrimary'
                            >
                              {userData?.nome ||
                                'Nome da Empresa não informado'}
                            </Typography>
                            <Typography
                              className={`${classes.textCpf}`}
                              color='textPrimary'
                            >
                              {formatCNPJ(userData?.empresas[0].cnpj || '') ||
                                'CNPJ não informado'}
                            </Typography>
                            <Typography
                              className={`${classes.textCpf}`}
                              color='textPrimary'
                            >
                              {userData?.contato.email || 'Email não informado'}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography
                              className={classes.textName}
                              color='textPrimary'
                            >
                              {isMobile
                                ? nameSplitTwo(userData?.nome?.toLowerCase())
                                : nameSplitOne(userData?.nome?.toLowerCase())}
                            </Typography>
                            <Typography
                              className={`${classes.textCpf} py-4`}
                              color='textPrimary'
                            >
                              {userData?.contato?.email ||
                                'Email não informado'}
                            </Typography>
                          </>
                        )}
                        <Typography
                          color='textPrimary'
                          className={classes.textCpf}
                        >
                          Último acesso:{' '}
                          {`${extractDateFormat(
                            userData.last_login,
                          )} às ${extractHourFormat(userData.last_login)}`}
                        </Typography>
                      </Box>
                    ) : (
                      <Button onClick={() => setShowSearch(!showSearch)}>
                        <IoMdSearch className={classes.icon} />
                      </Button>
                    )}
                    <BadgeAvatars
                      avatarUrl={avatarUser || null}
                      user={userData}
                      logout={logoutUser}
                    />
                  </Box>
                </>
              ) : (
                <Button
                  style={{
                    marginLeft: 20,
                    padding: '10px 20px',
                    border: '1px solid #fff',
                  }}
                  color='default'
                  variant='outlined'
                  onClick={loginRedirect}
                >
                  <FaUserCircle style={{ height: 20, width: 20 }} />
                  <Typography className={classes.textButton}>Entrar</Typography>
                </Button>
              )}
            </>
          )}
        </Box>
      </Container>
      {showSearch && (
        <Container>{!matchesMobile && <SearchServices />}</Container>
      )}
    </Box>
  );
}
