import {
  Box,
  Dialog,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import DOMPurify from 'dompurify';
import { IoMdClose } from 'react-icons/io';
import { MdAttachFile } from 'react-icons/md';
import { RespostasI } from '../../../../../interfaces/form-flow';
import {
  extractDateFormat,
  extractHourFormat,
} from '../../../../../utils/dateFormat';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textTable: {
      color: '#212121',
      fontWeight: 700,
      borderBottom: '1px solid #004F9F80',
      fontSize: '16px',
    },
    textTableValue: {
      color: '#212121',
      fontWeight: 400,
      fontSize: '14px',
      borderBottom: 'none',
    },
    textLink: {
      margin: '5px 0px 5px 14px',
      textDecoration: 'none',
      color: '#0F6FB7',
    },
    titleInfo: {
      color: '#212121',
      fontFamily: 'Roboto',
      fontSize: 22,
      fontWeight: 700,
      margin: '24px 0px 18px 16px',
    },
    attachText: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Roboto',
      fontSize: 16,
      fontWeight: 700,
      textDecoration: 'none',
      margin: '0px 0px 0px 16px',
      color: '#212121',
    },
    iconRequests: {
      height: 24,
      width: 24,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    status: {
      fontSize: 14,
      fontFamily: 'Roboto',
      fontWeight: 500,
      width: 'fit-content',
      textTransform: 'capitalize',
      padding: '5px 16px',
      borderBottom: 'none',
      borderRadius: '8px',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 24,
        alignSelf: 'center',
      },
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: '8px',
    },
    closeIcon: {
      color: '#212121',
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 'bold',
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      color: '#212121',
      fontSize: theme.typography.pxToRem(22),
      letterSpacing: '0.3px',
      fontWeight: 700,
      marginLeft: '16px',
    },
    boxView: {
      width: '920px',
      [theme.breakpoints.down('md')]: {
        width: '700px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  }),
);

const orderHistoric = (a, b) => {
  return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
};

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: 'rgba(15, 111, 183, 0.05)',
      color: theme?.palette?.primary?.main,
      textTransform: 'uppercase',
      fontWeight: 500,
      fontFamily: 'Roboto',
    },
    body: {
      fontSize: 14,
      color: '#333333',
    },
  }),
)(TableCell);

interface RequestModalProps {
  handleModal: () => void;
  data: RespostasI;
}

const RequestModal = ({ data, handleModal }: RequestModalProps) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        fullWidth
        open={!!data}
        onClose={() => handleModal()}
        maxWidth='md'
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <Fade in={!!data}>
          <div className={classes.paper}>
            <TableRow>
              <StyledTableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={12}
              >
                <Box style={{ width: '100%' }}>
                  <Box component='header' className={classes.header}>
                    <Box className={classes.title}>Informações</Box>
                    <IconButton
                      className={classes.closeIcon}
                      onClick={() => handleModal()}
                    >
                      <IoMdClose />
                    </IconButton>
                  </Box>
                  <Table
                    size='small'
                    aria-label='purchases'
                    style={{ marginBottom: 30 }}
                  >
                    <TableHead>
                      <TableRow style={{ textTransform: 'capitalize' }}>
                        <TableCell className={classes.textTable}>
                          Protocolo
                        </TableCell>
                        <TableCell className={classes.textTable}>
                          serviço
                        </TableCell>
                        <TableCell className={classes.textTable}>
                          Data da Solicitação
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        style={{
                          textTransform: 'uppercase',
                          borderBottom: 'none',
                        }}
                      >
                        <TableCell
                          component='th'
                          scope='row'
                          className={classes.textTableValue}
                        >
                          {data.protocolo}
                        </TableCell>
                        <TableCell className={classes.textTableValue}>
                          {data.flow?.titulo}
                        </TableCell>
                        <TableCell className={classes.textTableValue}>
                          {extractDateFormat(data?.created_at)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Box style={{ margin: '20px 0px', width: '100%' }}>
                    <Typography className={classes.titleInfo}>
                      Documentos
                    </Typography>
                    {data?.documento_resposta?.map((document, index) => (
                      <Typography
                        className={classes.attachText}
                        key={index.toString()}
                      >
                        <MdAttachFile className={classes.iconRequests} />
                        <Link
                          href={`/workspace/minhas_solicitacoes/view_doc/${document.id}/${data.identificador}`}
                          target='_blanck'
                          className={classes.textLink}
                        >
                          {document.assunto}
                        </Link>
                      </Typography>
                    ))}
                    {data?.anexo_documento_resposta?.map((document, index) => (
                      <Typography
                        className={classes.attachText}
                        key={index.toString()}
                      >
                        <MdAttachFile className={classes.iconRequests} />
                        <Link
                          href={document.arquivo}
                          target='-self'
                          className={classes.textLink}
                        >
                          {document.assunto}
                        </Link>
                      </Typography>
                    ))}
                    {data?.documento_resposta?.length === 0 && (
                      <Typography className={classes.attachText}>
                        Não possui documentos
                      </Typography>
                    )}
                  </Box>
                  <Box className={classes.boxView}>
                    <Typography className={classes.titleInfo}>
                      Histórico
                    </Typography>
                    <TableContainer style={{ overflowX: 'auto' }}>
                      <Table
                        size='medium'
                        aria-label='purchases'
                        style={{ marginBottom: 30 }}
                      >
                        <TableHead>
                          <TableRow style={{ textTransform: 'capitalize' }}>
                            <TableCell className={classes.textTable}>
                              ordem
                            </TableCell>
                            <TableCell className={classes.textTable}>
                              status
                            </TableCell>
                            <TableCell className={classes.textTable}>
                              Última Atualização
                            </TableCell>
                            <TableCell className={classes.textTable}>
                              descrição
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data?.historico_flow_resposta
                            ?.sort(orderHistoric)
                            .map((history, index) => (
                              <TableRow
                                key={index}
                                style={{ textTransform: 'uppercase' }}
                              >
                                <TableCell
                                  component='th'
                                  scope='row'
                                  className={classes.textTableValue}
                                  key={index.toString()}
                                >
                                  {index + 1}
                                </TableCell>
                                {history?.status ===
                                  'Complementação Cidadão' && (
                                  <StyledTableCell
                                    align='center'
                                    width={200}
                                    style={{ borderBottom: 'none' }}
                                  >
                                    <Typography
                                      className={classes.status}
                                      style={{
                                        color: '#0F6FB7',
                                        border: '1px solid #0F6FB7',
                                        background: ' rgba(5, 51, 150, 0.05)',
                                      }}
                                    >
                                      Complementação Cidadão
                                    </Typography>
                                  </StyledTableCell>
                                )}
                                {history?.status === 'Aguardando' && (
                                  <StyledTableCell
                                    align='center'
                                    width={200}
                                    style={{ borderBottom: 'none' }}
                                  >
                                    <Typography
                                      className={classes.status}
                                      style={{
                                        color: '#FAAD13',
                                        border:
                                          '1px solid rgba(250, 173, 19, 0.05)',
                                        background: 'rgba(250, 173, 19, 0.05)',
                                      }}
                                    >
                                      Aguardando
                                    </Typography>
                                  </StyledTableCell>
                                )}
                                {history?.status === 'Em Andamento' && (
                                  <StyledTableCell
                                    align='center'
                                    width={200}
                                    style={{ borderBottom: 'none' }}
                                  >
                                    <Typography
                                      className={classes.status}
                                      style={{
                                        color: '#FAAD13',
                                        border:
                                          '1px solid rgba(250, 173, 19, 0.05)',
                                        background: 'rgba(250, 173, 19, 0.05)',
                                      }}
                                    >
                                      Em Andamento
                                    </Typography>
                                  </StyledTableCell>
                                )}
                                {history?.status === 'Prorrogado' && (
                                  <StyledTableCell
                                    align='center'
                                    width={200}
                                    style={{ borderBottom: 'none' }}
                                  >
                                    <Typography
                                      className={classes.status}
                                      style={{
                                        color: '#FAAD13',
                                        border:
                                          '1px solid rgba(250, 173, 19, 0.10)',
                                        background: 'rgba(250, 173, 19, 0.10)',
                                      }}
                                    >
                                      Prorrogado
                                    </Typography>
                                  </StyledTableCell>
                                )}
                                {history?.status === 'Arquivado' && (
                                  <StyledTableCell
                                    align='center'
                                    width={200}
                                    style={{ borderBottom: 'none' }}
                                  >
                                    <Typography
                                      className={classes.status}
                                      style={{
                                        color: '#F03D3D',
                                        border:
                                          '1px solid rgba(240, 61, 61, 0.08)',
                                        background: 'rgba(240, 61, 61, 0.08)',
                                      }}
                                    >
                                      Arquivado
                                    </Typography>
                                  </StyledTableCell>
                                )}
                                {history?.status === 'Encaminhado' && (
                                  <StyledTableCell
                                    align='center'
                                    width={200}
                                    style={{ borderBottom: 'none' }}
                                  >
                                    <Typography
                                      className={classes.status}
                                      style={{
                                        color: '#053396',
                                        border:
                                          '1px solid rgba(5, 51, 150, 0.05)',
                                        background: ' rgba(5, 51, 150, 0.05)',
                                      }}
                                    >
                                      Encaminhado
                                    </Typography>
                                  </StyledTableCell>
                                )}
                                {history?.status === 'Em Aberto' && (
                                  <StyledTableCell
                                    align='center'
                                    width={200}
                                    style={{ borderBottom: 'none' }}
                                  >
                                    <Typography
                                      className={classes.status}
                                      style={{
                                        color: '#053396',
                                        border:
                                          '1px solid rgba(5, 51, 150, 0.05)',
                                        background: ' rgba(5, 51, 150, 0.05)',
                                      }}
                                    >
                                      Em Aberto
                                    </Typography>
                                  </StyledTableCell>
                                )}
                                {history?.status === 'Solicitado' && (
                                  <StyledTableCell
                                    align='center'
                                    width={200}
                                    style={{ borderBottom: 'none' }}
                                  >
                                    <Typography
                                      className={classes.status}
                                      style={{
                                        color: '#053396',
                                        border:
                                          '1px solid rgba(5, 51, 150, 0.05)',
                                        background: ' rgba(5, 51, 150, 0.05)',
                                      }}
                                    >
                                      Solicitado
                                    </Typography>
                                  </StyledTableCell>
                                )}
                                {history?.status === 'Recebido' && (
                                  <StyledTableCell
                                    align='center'
                                    width={200}
                                    style={{ borderBottom: 'none' }}
                                  >
                                    <Typography
                                      className={classes.status}
                                      style={{
                                        color: '#053396',
                                        border:
                                          '1px solid rgba(5, 51, 150, 0.05)',
                                        background: ' rgba(5, 51, 150, 0.05)',
                                      }}
                                    >
                                      Recebido
                                    </Typography>
                                  </StyledTableCell>
                                )}
                                {history?.status === 'Concluído' && (
                                  <StyledTableCell
                                    align='center'
                                    width={200}
                                    style={{ borderBottom: 'none' }}
                                  >
                                    <Typography
                                      className={classes.status}
                                      style={{
                                        color: '#3CD278',
                                        border:
                                          '1px solid rgba(60, 210, 120, 0.10)',
                                        background: 'rgba(60, 210, 120, 0.10)',
                                      }}
                                    >
                                      Concluído
                                    </Typography>
                                  </StyledTableCell>
                                )}
                                {history?.status === 'Respondido' && (
                                  <StyledTableCell
                                    align='center'
                                    width={200}
                                    style={{ borderBottom: 'none' }}
                                  >
                                    <Typography
                                      className={classes.status}
                                      style={{
                                        color: '#3CD278',
                                        border:
                                          '1px solid rgba(60, 210, 120, 0.05)',
                                        background: 'rgba(60, 210, 120, 0.05)',
                                      }}
                                    >
                                      Respondido
                                    </Typography>
                                  </StyledTableCell>
                                )}
                                {history?.status === 'Respondido(Reaberto)' && (
                                  <StyledTableCell
                                    align='center'
                                    width={200}
                                    style={{ borderBottom: 'none' }}
                                  >
                                    <Typography
                                      className={classes.status}
                                      style={{
                                        color: '#3CD278',
                                        border:
                                          '1px solid rgba(60, 210, 120, 0.05)',
                                        background: 'rgba(60, 210, 120, 0.05)',
                                      }}
                                    >
                                      Respondido(Reaberto)
                                    </Typography>
                                  </StyledTableCell>
                                )}

                                {history?.status === 'Finalizado' && (
                                  <StyledTableCell
                                    align='center'
                                    width={200}
                                    style={{ borderBottom: 'none' }}
                                  >
                                    <Typography
                                      className={classes.status}
                                      style={{
                                        color: '#3CD278',
                                        border:
                                          '1px solid rgba(60, 210, 120, 0.05)',
                                        background: 'rgba(60, 210, 120, 0.05)',
                                      }}
                                    >
                                      Finalizado
                                    </Typography>
                                  </StyledTableCell>
                                )}
                                {history?.status ===
                                  'Cancelado pelo usuário' && (
                                  <StyledTableCell
                                    align='center'
                                    width={200}
                                    style={{ borderBottom: 'none' }}
                                  >
                                    <Typography
                                      className={classes.status}
                                      style={{
                                        color: '#F03D3D',
                                        border:
                                          '1px solid rgba(240, 61, 61, 0.08)',
                                        background: 'rgba(240, 61, 61, 0.08)',
                                      }}
                                    >
                                      Cancelado
                                    </Typography>
                                  </StyledTableCell>
                                )}
                                {history?.status ===
                                  'Cancelado pelo atendente' && (
                                  <StyledTableCell
                                    align='center'
                                    width={200}
                                    style={{ borderBottom: 'none' }}
                                  >
                                    <Typography
                                      className={classes.status}
                                      style={{
                                        color: '#F03D3D',
                                        border:
                                          '1px solid rgba(240, 61, 61, 0.08)',
                                        background: 'rgba(240, 61, 61, 0.08)',
                                      }}
                                    >
                                      Cancelado
                                    </Typography>
                                  </StyledTableCell>
                                )}
                                {history?.status === 'Cancelado' && (
                                  <StyledTableCell
                                    align='center'
                                    width={200}
                                    style={{ borderBottom: 'none' }}
                                  >
                                    <Typography
                                      className={classes.status}
                                      style={{
                                        color: '#F03D3D',
                                        border:
                                          '1px solid rgba(240, 61, 61, 0.08)',
                                        background: 'rgba(240, 61, 61, 0.08)',
                                      }}
                                    >
                                      Cancelado
                                    </Typography>
                                  </StyledTableCell>
                                )}

                                {history?.status === 'Restituído' && (
                                  <StyledTableCell
                                    align='center'
                                    width={200}
                                    style={{ borderBottom: 'none' }}
                                  >
                                    <Typography
                                      className={classes.status}
                                      style={{
                                        color: '#053396',
                                        border:
                                          '1px solid rgba(5, 51, 150, 0.05)',
                                        background: ' rgba(5, 51, 150, 0.05)',
                                      }}
                                    >
                                      Restituído
                                    </Typography>
                                  </StyledTableCell>
                                )}
                                <TableCell
                                  className={classes.textTableValue}
                                  style={{ width: '250px' }}
                                >
                                  {`${extractDateFormat(
                                    history?.updated_at,
                                  )} às ${extractHourFormat(
                                    history?.updated_at,
                                  )}`}
                                </TableCell>
                                <TableCell className={classes.textTableValue}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        DOMPurify.sanitize(
                                          history?.descricao,
                                        ) || 'Sem informação',
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          {data?.historico_flow_resposta?.length === 0 && (
                            <TableCell
                              component='th'
                              scope='row'
                              colSpan={12}
                              className={classes.textTableValue}
                            >
                              {'Sem histórico'}
                            </TableCell>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </StyledTableCell>
            </TableRow>
          </div>
        </Fade>
      </Dialog>
    </>
  );
};

export default RequestModal;
