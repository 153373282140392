import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { MdArrowBack, MdCheck } from "react-icons/md";
import { Breadcrumb } from "../../Components";

import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px",
  },
  boxIndicatorsBack: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px",
  },
  indicatorsBack: {
    display: "flex",
    width: "100%",
    color: "#373F41",
    maxWidth: "1250px",
    alignItems: "center",
  },
  boxAccess: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    minHeight: 300,
    padding: 20,
  },
  cardContent: {

  },
  iconAccess: {
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    borderRadius: 4,
    width: 50,
    height: 50,
    padding: 7,
    marginBottom: 20,
  },
  textCard: {
    fontFamily: "Roboto",
    color: "#373F41",
    marginTop: 10,
    fontSize: 16,
    letterSpacing: 0.3,
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  subTextCard: {
    fontFamily: "Roboto",
    color: "#373F41",
    marginTop: 5,
    marginBottom: 20,
    fontSize: 16,
    letterSpacing: 0.3,
    fontWeight: "normal",
  },
  linkCard: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    fontFamily: "Roboto",
    letterSpacing: 0.3,
    fontWeight: "normal",
  },
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

type Props = {
  history: any;
};

export default function DetailDigitalService({ history }: Props): JSX.Element {
  const classes = useStyles();
  const linksBreadcrumb = [
    {
      name: "Título serviço",
      redirectTo: "#",
    },
  ];
  return (
    <>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Breadcrumb links={linksBreadcrumb} />
            <Box className={classes.boxIndicatorsBack}>
              <Box className={classes.indicatorsBack}>
                <IconButton onClick={() => history.push('/workspace')}>
                  <MdArrowBack />
                </IconButton>
                <Typography style={{ fontFamily: "Roboto", fontWeight: 700 }}>
                  Título serviço
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Card className={classes.boxAccess}>
              <CardContent className={classes.cardContent}>
                <Timeline align="alternate">
                  <TimelineItem style={{}}>
                    <TimelineSeparator>
                      <TimelineDot color="primary">
                        <MdCheck style={{ color: "#FFFFFF", width: 24, height: 24 }} />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent
                      style={{
                        color: "#373F41",
                        fontFamily: "Roboto",
                        opacity: 0.3,
                        paddingBottom: 50,

                      }}
                    >
                      <Typography>Processo aberto</Typography>
                      <Typography>29/03/2021</Typography>
                      <Typography>Realizado em DETRAN-SEDE</Typography>
                    </TimelineContent>
                  </TimelineItem>

                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot color="primary">
                        <Typography
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: 18,
                            width: 24,
                            height: 24,
                            textAlign: "center",
                            color: "#FFF",
                          }}
                        >
                          2
                        </Typography>
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent
                      style={{
                        color: "#373F41",
                        fontFamily: "Roboto",
                        paddingBottom: 50,

                      }}
                    >
                      <Typography>Pagamento de Débitos</Typography>
                      <Typography>29/03/2021</Typography>
                      <Box marginTop={5}>
                        <Button
                          style={{
                            color: "#373F41",
                            opacity: 0.3,
                            marginLeft: 10,
                          }}
                        >
                          VOLTAR
                        </Button>
                        <Button variant="contained" color="primary">
                          PRÓXIMO
                        </Button>
                      </Box>
                    </TimelineContent>
                  </TimelineItem>

                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot
                        style={{
                          backgroundColor: "#373F41",
                          opacity: 0.3,
                        }}
                      >
                        <Typography
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: 18,
                            width: 24,
                            height: 24,
                            textAlign: "center",
                            color: "#FFF",
                          }}
                        >
                          3
                        </Typography>
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent
                      style={{
                        color: "#373F41",
                        fontFamily: "Roboto",
                        opacity: 0.3,

                      }}
                    >
                      <Typography>Finalizar</Typography>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
