import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 10px',
  },
  alertCustom: {
    borderLeft: '5px solid #ffa726',
    color: '#545758',
    '& a': {
      color: '#1976d2',
    },
  },
  boxIndicatorsBack: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    margin: '20px 0px',
  },
  indicatorsBack: {
    display: 'flex',
    width: '100%',
    color: '#373F41',
    maxWidth: '1250px',
    alignItems: 'center',
  },
  cardData: {
    maxWidth: 680,
    width: '100%',
    backgroundColor: '#FFFFFF',
    paddingLeft: 50,
    paddingRight: 50,
  },
  alertContainer: {
    borderRadius: 8,
    maxWidth: 680,
    width: '100%',
    boxShadow: '0px 4px 8px 0px #0010204D',
    marginBottom: 14,
  },
  boxAvatar: {
    marginTop: 20,
    border: `2px solid ${theme.palette.primary.main}`,
    width: 80,
    height: 80,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    height: 64,
    width: 64,
    backgroundColor: '#E1EDF6',
  },
  userName: {
    fontSize: 16,
    color: '#373F41',
    fontWeight: 'normal',
    paddingTop: 16,
    fontFamily: 'Roboto',
  },
  userCpf: {
    fontSize: 16,
    color: '#373F41',
    fontWeight: 'normal',
    padding: '4px 0px 24px',
    opacity: 0.5,
  },
  input: {
    display: 'none',
  },
  button: {
    minWidth: 161,
    height: 44,
    fontSize: '100%',
    borderRadius: 4,
    padding: '12px 16px',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  alertTitle: {
    fontWeight: 500,
    fontSize: 14,
    color: '#212A31',
    margin: 0,
  },
  disabledInput: {
    backgroundColor: '#DDDDDD',
  },
  labelButton: {
    fontSize: 14,
    fontFamily: 'Roboto',
  },
  inputForm: {
    margin: '24px 0px',
    color: '#111',
  },
  buttonReturnAndSave: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 16,
  },
  buttonReturn: {
    width: 83,
    marginRight: 16,
    borderRadius: 4,
    color: '#373F41',
    border: 0,
    textTransform: 'uppercase',
  },
  buttonSave: {
    width: 83,
  },
  icon: {
    width: 24,
    height: 24,
  },
  errorBorder: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#DA1E28',
    },
  },
  inputDefault: {
    color: '#111111',
  },
  goBackLink: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#004F9F',
    fontSize: '14px',
    fontWeight: 700,
    textTransform: 'none',
    padding: 0,
  }
}));