import jwt_decode from 'jwt-decode';
import { useEffect } from 'react';
import getCookie from '../utils/getCookies';
import { setCookie } from '../utils/setCookie';

interface DecodedToken {
  auth_type?: string;
}

const useLoggedWithCertificado = () => {
  useEffect(() => {
    const token = getCookie('gov_access_token_sso');

    if (token) {
      try {
        const decoded: DecodedToken = jwt_decode(token);
        const loginType = decoded?.auth_type || 'default';

        if (loginType === 'e-cnpj') {
          setCookie('perfil', 'Cidadão', 7);
        }

        setCookie('loginType', loginType, 7);
      } catch (error) {
        console.error('Erro ao decodificar o token:', error);
      }
    } else {
      console.warn('Token não encontrado nos cookies.');
    }
  }, []);
};

export default useLoggedWithCertificado;
