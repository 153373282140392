import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 10px',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  content: {
    display: 'flex',
  },
  contentUser: {
    display: 'flex',

    justifyContent: 'space-between',
    alignItems: 'center',
  },
  alertCustom: {
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    margin: '0px 0px 32px 0px',
    borderLeft: '5px solid #ffa726',
    borderRadius: 10,
    color: '#545758',
    '& a': {
      color: '#1976d2',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '& .MuiAlert-icon': {
      alignSelf: 'center',
      marginRight: theme.spacing(1),
    },
  },
  alertTitle: {
    fontWeight: 700,
    fontSize: '1rem',
    color: '#545758',
    margin: 0,
  },
  textName: {
    textTransform: 'capitalize',
    paddingTop: 10,
    display: 'flex',
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(28),
    fontWeight: 500,
    margin: '20px 0px',
    color: '#373F41',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(22),
    },
  },
  iconDash: {
    background: theme.palette.primary.main,
    color: '#FFFFFF',
    width: 40,
    height: 40,
    padding: 5,
    borderRadius: 4,
    marginRight: 10,
  },
  linkToAll: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 16,
    textDecoration: 'none',
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    padding: '10px 0px',
    marginTop: '24px',
    width: '147px',
    textAlign: 'center',
    borderRadius: '2px',
  },
  containerCards: {
    marginTop: '50px',
    padding: 0,
  },
}));

export default useStyles