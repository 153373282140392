import {
  Box,
  Card,
  CardActions,
  CardContent,
  Snackbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useState } from 'react';
import { FaBookmark, FaRegBookmark } from 'react-icons/fa';

interface PortalCardProps {
  imageSrc: string;
  title: string;
  linkText: string;
  idCard: number;
  onClick: () => void;
  onFavoriteToggle: (idCard: number, newFavoriteStatus: boolean) => void;
  isFavorite: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: 280,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 4px 4px 0px #00000040',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
  },
  cardContent: {
    padding: 0,
    position: 'relative',
  },
  iconBox: {
    fontSize: '2.5rem',
    color: theme.palette.primary.main,
    padding: 0,
  },
  favoriteIcon: {
    position: 'absolute',
    top: 8,
    right: 0,
    cursor: 'pointer',
    '& svg': {
      fontSize: '14px',
      color: theme.palette.primary.main,
    },
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '16px',
    color: '#212121',
    marginBottom: 16,
  },
  image: {
    width: 'auto',
    height: '35px',
    objectFit: 'contain',
  },
  link: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '0.875rem',
    color: '#004F9F',
    textDecoration: 'none',
    cursor: 'pointer',
    textAlign: 'end',
    width: '100%',
  },
  cardActions: {
    padding: 0,
    marginTop: 'auto',
  },
}));

const PortalCard = ({
  imageSrc,
  title,
  linkText,
  idCard,
  onClick,
  onFavoriteToggle,
  isFavorite: initialIsFavorite,
}: PortalCardProps) => {
  const classes = useStyles();
  const [isFavorite, setIsFavorite] = useState(initialIsFavorite);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'error'
  >('info');

  const handleFavoriteToggle = async () => {
    const newFavoriteStatus = !isFavorite;

    try {
      onFavoriteToggle(idCard, newFavoriteStatus);
      if (newFavoriteStatus) {
        setSnackbarMessage('Adicionado aos favoritos');
        setSnackbarSeverity('success');
      } else {
        setSnackbarMessage('Removido dos favoritos');
        setSnackbarSeverity('info');
      }
      setIsFavorite(newFavoriteStatus);
    } catch (error) {
      setSnackbarMessage('Erro ao atualizar favoritos');
      setSnackbarSeverity('error');
    }

    setOpenSnackbar(true);
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContent}>
        <Box className={classes.favoriteIcon} onClick={handleFavoriteToggle}>
          {isFavorite ? <FaBookmark /> : <FaRegBookmark />}
        </Box>
        <Box className={classes.iconBox}>
          {imageSrc && (
            <img src={imageSrc} alt={title} className={classes.image} />
          )}
        </Box>
        <Typography className={classes.title}>{title}</Typography>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Typography className={classes.link} onClick={onClick}>
          {linkText}
        </Typography>
      </CardActions>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Card>
  );
};

export default PortalCard;
