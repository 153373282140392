import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DOMPurify from 'dompurify';
import { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { getNotices } from '../../services/avisos';
import getCookie from '../../utils/getCookies';
import { setCookie } from '../../utils/setCookie';

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  titleDialog: {
    color: '#373F41',
    backgroundColor: '#F4F5F7',
    paddingTop: 20,
    paddingBottom: 20,
    paddingRight: 40,
  },
  contentDialog: {
    color: '#000',
  },
}));

export interface INotice {
  titulo: string;
  conteudo: string;
  id: number;
}

export default function ModalAlertsDialog(): JSX.Element {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [notice, setNotice] = useState<INotice | undefined>();

  const loadCurrentNotices = async () => {
    try {
      const { data } = await getNotices();
      if (data) {
        setNotice(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    loadCurrentNotices();
  }, []);

  useEffect(() => {
    const noticeViewed = getCookie('notice-visualized');
    if (notice && noticeViewed !== notice.id.toString()) {
      setCookie('notice-visualized', notice.id.toString());
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [notice]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      fullWidth
      maxWidth='md'
    >
      <DialogTitle className={classes.titleDialog}>
        {notice?.titulo}
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={handleClose}
        >
          <MdClose />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box
          className={classes.contentDialog}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(notice?.conteudo) || '',
          }}
        />
      </DialogContent>
    </Dialog>
  );
}
