// src/components/Activity.tsx

import { Box, CircularProgress, Container, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { MdArrowBack, MdCalendarToday, MdSchedule } from "react-icons/md";
import { Breadcrumb, PaginationService } from '../../Components';

import { AtividadeProps } from '../../interfaces/atividades';
import { allActivities } from '../../services/atividades';
import theme from '../../services/theme-service';
import getCookie from '../../utils/getCookies';

type Props = {
  history: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 10px',
  },
  boxIndicatorsBack: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0px'
  },
  indicatorsBack: {
    display: 'flex',
    width: '100%',
    color: '#373F41',
    maxWidth: '1250px',
    alignItems: 'center',
  },
  boxTermsList: {
    backgroundColor: '#fff',
    borderRadius: 4,
    marginBottom: 22,
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    fontFamily: 'Roboto',
    textTransform: 'capitalize',
    color: '#373F41',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 500,
    }
  },
  boxDateInfo: {
    display: 'flex',
    color: '#373F41',
    alignItems: 'center',
    marginTop: 17,
    flexWrap: 'wrap',
  },
  date: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 16,
    fontStyle: 'normal',
    margin: '0px 4px',
    color: '#373F41',
    opacity: 0.6,
  },
  errorText: {
    fontSize: 22,
    fontFamily: 'Roboto',
    fontWeight: 500,
  },
  iconActivy: {
    height: 24,
    width: 24,
    opacity: 0.6,
  }
}));

export default function Activity({ history }: Props): JSX.Element {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const token: string | null = getCookie('gov_access_token_sso');
  const [activitiesData, setActivitiesData] = useState<AtividadeProps | null>(null);
  const [error, setError] = useState<string | null>(null);

  const isActivityValidData = activitiesData?.results?.length > 0;
  const isNotActivityValidData = activitiesData?.results?.length === 0;

  const linksBreadcrumb = [
    {
      name: "Minhas Ações",
      redirectTo: "#",
    },
  ];

  const formatarDataHora = (data: string | Date, options: Intl.DateTimeFormatOptions) => {
    return new Intl.DateTimeFormat("pt-BR", {
      timeZone: "America/Campo_Grande",
      ...options,
    }).format(new Date(data));
  };

  useEffect(() => {
    const getAllActivities = async () => {
      try {
        setLoading(true);
        setError(null);
        const { data } = await allActivities({ token, page });

        setActivitiesData(data);
      } catch (error: any) {
        if (error.response?.status === 404) {
          setActivitiesData({ results: [] });
        } else {
          setError('Ocorreu um erro ao buscar as atividades. Tente novamente mais tarde.');
        }
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      getAllActivities();
    } else {
      setError('Token de autenticação não encontrado. Por favor, faça login novamente.');
    }
  }, [page, token]);

  return (
    <>
      <Breadcrumb links={linksBreadcrumb} />
      <Box className={classes.boxIndicatorsBack}>
        <Box className={classes.indicatorsBack}>
          <IconButton onClick={() => history.push('/workspace')} aria-label="Voltar">
            <MdArrowBack />
          </IconButton>
          <Typography style={{ fontFamily: "Roboto", fontWeight: 700 }}>
            Minhas Ações
          </Typography>
        </Box>
      </Box>
      <Container
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
        }}
      >
        {loading ? (
          <CircularProgress
            style={{
              width: 25,
              height: 25,
              color: `${theme.palette.primary} `,
            }}
          />
        ) : error ? (
          <Typography className={classes.errorText}>{error}</Typography>
        ) : (
          <>
            {isActivityValidData ? (
              activitiesData.results.map((activiti) => (
                <Grid
                  container
                  alignItems="center"
                  justify="space-between"
                  md={5}
                  direction="row"
                  className={classes.boxTermsList}
                  key={activiti.id}
                >
                  <Grid item sm={8} xs={11} style={{ padding: 20 }}>
                    <Typography className={classes.title}>
                      {activiti.titulo}
                    </Typography>
                    <Box className={classes.boxDateInfo}>
                      <div style={{ display: "flex" }}>
                        <MdCalendarToday className={classes.iconActivy} />
                        <Typography className={classes.date}>
                          {formatarDataHora(activiti.created_at, { day: "2-digit", month: "2-digit", year: "numeric" })}
                        </Typography>
                      </div>

                      <div style={{ display: "flex" }}>
                        <MdSchedule className={classes.iconActivy} />
                        <Typography className={classes.date}>
                          {formatarDataHora(activiti.created_at, { hour: "2-digit", minute: "2-digit" })}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              ))
            ) : isNotActivityValidData ? (
              <Typography className={classes.errorText}>
                Nenhum resultado foi encontrado!
              </Typography>
            ) : null}
          </>
        )}
      </Container>
      {isActivityValidData && !loading && (
        <PaginationService getSearch={setPage} listSearch={activitiesData} />
      )}
    </>
  );
}
