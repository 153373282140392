import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  makeStyles,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { MdCalendarToday, MdSchedule } from 'react-icons/md';
import { Favorito } from '../../../../../interfaces/servico';
import {
  extractDateFormat,
  extractHourFormat,
} from '../../../../../utils/dateFormat';
import Menus from './Menus';

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    color: '#373F41',
    height: 'auto',
    minHeight: 96,
  },
  textActivit: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    marginBottom: 15,
  },
  subTextActivit: {
    display: 'flex',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(10),
    alignContent: 'center',
    alignItems: 'center',
  },
  iconSubText: {
    padding: 5,
    color: theme.palette.primary.main,
    width: 24,
    height: 24,
  },
}));
interface Props {
  favorites?: Array<Favorito>;
}

export default function TabFavorite({ favorites }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <List
      style={{
        padding: 0,
        width: '100%',
      }}
    >
      {favorites ? (
        favorites.map(
          ({
            id,
            servico_slug,
            titulo,
            created_at,
            agendavel,
            online,
            categoria_slug,
          }) => (
            <React.Fragment key={id}>
              <ListItem className={classes.listItem}>
                <Box>
                  <Typography className={classes.textActivit}>
                    {titulo}
                  </Typography>
                  <Typography className={classes.subTextActivit}>
                    <MdCalendarToday className={classes.iconSubText} />{' '}
                    {extractDateFormat(created_at)}
                    <MdSchedule className={classes.iconSubText} />{' '}
                    {extractHourFormat(created_at)}
                  </Typography>
                </Box>
                <ListItemSecondaryAction>
                  <Menus
                    favoriteId={id}
                    categoria_slug={categoria_slug}
                    agendavel={agendavel}
                    online={online}
                    service_slug={servico_slug}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </React.Fragment>
          ),
        )
      ) : (
        <TableRow style={{ textAlign: 'center' }}>Não há Favoritos</TableRow>
      )}
    </List>
  );
}
