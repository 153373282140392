import getCookie from "../utils/getCookies";

export default function useAuth() {
    const userDataStorage: string | null = getCookie('gov_user_data');

    if (userDataStorage) {
        const parsedUserData = JSON.parse(userDataStorage);
        return parsedUserData;
    }

    return null;
}