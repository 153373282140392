import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiSelect-iconOutlined": {
        height: 24,
        width: 24,
      },
    },
    container: {
      marginTop: theme.spacing(7),

      padding: theme.spacing(0, 2.5),
      marginBottom: theme.spacing(1.25),
      maxWidth: '1232px',
      width: '100%',
      margin: '56px auto 10px',
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
      justifyContent: 'space-between',
    },
    btnFilter: {
      flex: '1 1 200px',
      maxWidth: '250px',
      borderRadius: 4,
    },
    btnFilterDate: {
      flex: '1 1 200px',
      maxWidth: '250px',
      borderRadius: '8px !important',
    },
    itemMenu: {
      borderRadius: '0',
    },
    textMenu: {
      width: '100%',
      textAlign: 'center',
      color: '#373F41',
      fontSize: '16px',
      lineHeight: '20px',
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      fontStyle: 'normal',
    },
    customIcon: {
      color: '#0F6FB7',
      fontSize: '20px',
      marginRight: '8px',
    },

  })
);

interface Filter {
  status?: string | null;
  ordenar?: 'data' | '-data' | null;
}

interface Props {
  setFilter: (filter: any) => void;
}

const FilterAttendance: React.FC<Props> = ({ setFilter }) => {
  const classes = useStyles();
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [dateOrder, setDateOrder] = useState<'data' | '-data' | ''>('');

  useEffect(() => {
    const filter: Filter = {};

    if (statusFilter && statusFilter !== 'Todos') {
      filter.status = statusFilter;
    } else {
      filter.status = null;
    }

    if (dateOrder) {
      filter.ordenar = dateOrder;
    } else {
      filter.ordenar = null;
    }

    setFilter(filter);
  }, [statusFilter, dateOrder, setFilter]);

  const CustomIcon = (props: any) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="none"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0l-3.75-3.75M17.25 21L21 17.25"
      />
    </svg>
  );

  return (
    <Box className={classes.container}>
      <FormControl variant="outlined" className={classes.btnFilter} fullWidth>
        <InputLabel id="status-select-label">Filtrar Agendamentos</InputLabel>
        <Select
          labelId="status-select-label"
          id="status-select-outlined"
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value as string)}
          label="Filtrar Agendamentos"
        >
          {['Todos', 'Aguardando', 'Em Atendimento', 'Finalizados', 'Cancelados', 'Não Compareceu'].map(
            (status) => (
              <MenuItem key={status} value={status} className={classes.itemMenu}>
                <Typography
                  className={classes.textMenu}
                  style={statusFilter === status ? { color: '#0F6FB7' } : {}}
                >
                  {status}
                </Typography>
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>

      <FormControl variant="outlined" className={classes.btnFilterDate} fullWidth>
        <InputLabel id="date-order-select-label">Data de Atendimento</InputLabel>
        <Select
          labelId="date-order-select-label"
          id="date-order-select-outlined"
          style={{ borderRadius: '8px' }}
          value={dateOrder}
          onChange={(e) => setDateOrder(e.target.value as 'data' | '-data' | '')}
          label="Data de atendimento"
          IconComponent={CustomIcon}
          classes={{ iconOutlined: classes.customIcon }}
        >
          <MenuItem value="" className={classes.itemMenu}>
            <Typography
              className={classes.textMenu}
              style={{
                color: !dateOrder ? '#0F6FB7' : undefined,
                textAlign: 'left',
              }}
            >
              Sem Ordenação
            </Typography>
          </MenuItem>
          <MenuItem value="data" className={classes.itemMenu}>
            <Typography
              className={classes.textMenu}
              style={{
                color: dateOrder === 'data' ? '#0F6FB7' : undefined,
                textAlign: 'left',
              }}
            >
              Data Crescente
            </Typography>
          </MenuItem>
          <MenuItem value="-data" className={classes.itemMenu}>
            <Typography
              className={classes.textMenu}
              style={{
                color: dateOrder === '-data' ? '#0F6FB7' : undefined,
                textAlign: 'left',
              }}
            >
              Data Decrescente
            </Typography>
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default FilterAttendance;
