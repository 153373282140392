import {
  Box,
  Collapse,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PortalCard from '../../Components/CardInstitutionalSystems';
import NavMenu from '../../Components/NavMenu';
import { LoginType, UserData } from '../../interfaces/user';
import { getUserData as getUserDataRequest } from '../../services/auth';
import {
  addFavoriteSystem,
  getFavoriteInstitutionalSystems,
  getInstitutionalSystems,
  removeFavoriteSystem,
} from '../../services/servico';
import getCookie from '../../utils/getCookies';
import { firstName } from '../../utils/nameFormat';
import { setCookie } from '../../utils/setCookie';
import CardListActions from './components/CardListActions';
import RecommendationServices from './components/RecommendationServices';
import TabsActionsFavorite from './components/TabsActionsFavorite';
import { initialState, reducer } from './store/reducer';
import { StateProvider } from './store/stateProvider';
import useStyles from './style';

export interface InstitutionalSystem {
  id: number;
  nome: string;
  url: string;
  ativo: boolean;
  logo_sistema: string;
}

interface Favorite {
  systemId: number;
  favoriteId: number;
}

export default function Home(): JSX.Element {
  const classes = useStyles();
  const currentProfile = getCookie('perfil');
  const loginType = getCookie('loginType') as LoginType | null;
  const [userData, setUserData] = useState<UserData | null>();
  const userDataLocal: string | null = getCookie('gov_user_data');
  const [serviceSelected, setServiceSelected] = useState<
    'solicitacoes' | 'favoritos'
  >('favoritos');
  const token: string | null = getCookie('gov_access_token_sso');
  const tokenSiseci: string | null = getCookie('gov_access_token_sso');
  const matchesMobile = useMediaQuery(
    '(min-width:600px) and (max-width:1000px)',
  );
  const [institutionalSystems, setInstitutionalSystems] = useState<
    InstitutionalSystem[]
  >([]);
  const history = useHistory();
  const [favorites, setFavorites] = useState<Favorite[]>([]);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (loginType === 'e-cnpj') {
      history.push('/workspace/meus-sistemas');
    }
  }, [loginType]);

  const getUserData = async (tokenSso: string, tokenSiseciReceive: string) => {
    try {
      const data = await getUserDataRequest(tokenSso, tokenSiseciReceive);
      setUserData(data);
      setCookie('gov_user_data', JSON.stringify(data), 7);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchFavoriteInstitutionalSystems = async () => {
    const tokenSiseci: string | null = getCookie('gov_access_token_sso');
    if (tokenSiseci) {
      try {
        const response = await getFavoriteInstitutionalSystems(tokenSiseci);
        const favoriteResults = response.data.results || [];
        const favorites = favoriteResults.map((fav: any) => ({
          systemId: fav.sistema_institucional.id,
          favoriteId: fav.id,
        }));
        setFavorites(favorites);
      } catch (error) {
        console.error(
          'Erro ao buscar sistemas institucionais favoritos:',
          error,
        );
      }
    }
  };

  const handleFavoriteToggle = async (idCard, newFavoriteStatus) => {
    const tokenSiseci: string | null = getCookie('gov_access_token_sso');
    if (!tokenSiseci) {
      return;
    }

    if (newFavoriteStatus) {
      try {
        const response = await addFavoriteSystem(idCard, tokenSiseci);
        const newFavoriteId = response.data.id;
        setFavorites(prevFavorites => [
          ...prevFavorites,
          { systemId: idCard, favoriteId: newFavoriteId },
        ]);
      } catch (error) {
        console.error('Erro ao adicionar favorito:', error);
      }
    } else {
      const favoriteToRemove = favorites.find(fav => fav.systemId === idCard);
      if (favoriteToRemove) {
        try {
          await removeFavoriteSystem(favoriteToRemove.favoriteId, tokenSiseci);
          setFavorites(prevFavorites =>
            prevFavorites.filter(fav => fav.systemId !== idCard),
          );
        } catch (error) {
          console.error('Erro ao remover favorito:', error);
        }
      }
    }
  };

  const fetchInstitutionalSystems = async () => {
    const tokenSiseci: string | null = getCookie('gov_access_token_sso');
    if (tokenSiseci) {
      try {
        const response = await getInstitutionalSystems(
          { profile: currentProfile },
          tokenSiseci,
        );
        setInstitutionalSystems(response.data);
      } catch (error) {
        console.error('Erro ao buscar sistemas institucionais:', error);
      }
    }
  };

  useEffect(() => {
    if (userDataLocal) {
      setUserData(JSON.parse(userDataLocal));
    } else if (token && tokenSiseci) {
      getUserData(token, tokenSiseci);
    }
    fetchInstitutionalSystems();
    fetchFavoriteInstitutionalSystems();
  }, [userDataLocal, token, tokenSiseci]);

  const isFavorite = (id: number) => favorites.some(fav => fav.systemId === id);

  const sortedPortals = useMemo(() => {
    const favoritesList = institutionalSystems.filter(system =>
      isFavorite(system.id),
    );
    const nonFavoritesList = institutionalSystems.filter(
      system => !isFavorite(system.id),
    );
    return [...favoritesList, ...nonFavoritesList];
  }, [institutionalSystems, favorites]);

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <NavMenu />
      <Container>
        <Box className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.contentUser}>
                <div>
                  <Typography className={classes.textName}>
                    Olá,{' '}
                    {userData?.nome && (
                      <>{`${firstName(userData?.nome?.toLowerCase())}!`}</>
                    )}
                  </Typography>
                </div>
                {/* <div>{isMobile && <SelectProfile />}</div> */}
              </div>
              <Collapse in={open}>
                {loginType === 'e-cpf' && (
                  <Alert
                    severity='warning'
                    className={`shadow ${classes.alertCustom}`}
                    onClose={() => {
                      setOpen(false);
                    }}
                  >
                    <Box display='flex' alignItems='center'>
                      <Typography
                        variant='h6'
                        component='h4'
                        className={classes.alertTitle}
                      >
                        O Acesso a alguns Serviços não está disponível
                      </Typography>
                    </Box>
                    <Typography variant='body2'>
                      Seu acesso ao sistema foi realizado pelo Certificado
                      Digital, alguns serviços não estarão disponíveis. Para
                      acesso total ao sistema, por favor, realize o seu acesso
                      pelo endereço{' '}
                      <a href='https://acesso.gov.br'>acesso.gov.br</a>.
                    </Typography>
                  </Alert>
                )}
              </Collapse>
              <CardListActions />
            </Grid>
            {!matchesMobile && (
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <RecommendationServices />
                <Link
                  to='/workspace/minhas_solicitacoes'
                  className={classes.linkToAll}
                >
                  Todas Solicitações
                </Link>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} alignItems='center'>
                  <TabsActionsFavorite
                    serviceSelected={serviceSelected}
                    setServiceSelected={setServiceSelected}
                  />
                </Grid>
              </Grid>
              {serviceSelected === 'favoritos' ? (
                <Link to='/workspace/favoritos' className={classes.linkToAll}>
                  Todos Favoritos
                </Link>
              ) : (
                <Link
                  to='/workspace/minhas_solicitacoes'
                  className={classes.linkToAll}
                >
                  Todas Solicitações
                </Link>
              )}
            </Grid>
          </Grid>
        </Box>
        {/* {currentProfile === "Empresa" &&  <ListBusiness/>} */}
        <Container className={classes.containerCards}>
          <Grid container spacing={4}>
            {sortedPortals.length > 0 &&
              sortedPortals.map(system => {
                const favorite = favorites.find(
                  fav => fav.systemId === system.id,
                );
                const isFavorite = !!favorite;

                return (
                  <Grid item key={system.id}>
                    <PortalCard
                      imageSrc={system.logo_sistema}
                      title={system.nome}
                      idCard={system.id}
                      linkText='Acessar portal'
                      isFavorite={isFavorite}
                      onClick={() =>
                        window.open(system.url, '_blank', 'noopener,noreferrer')
                      }
                      onFavoriteToggle={handleFavoriteToggle}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Container>
      </Container>
    </StateProvider>
  );
}
