import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  alertCustom: {
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    margin: '0px 0px 32px 0px',
    borderLeft: '5px solid #ffa726',
    borderRadius: 10,
    color: '#545758',
    '& a': {
      color: '#1976d2',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '& .MuiAlert-icon': {
      alignSelf: 'center',
      marginRight: theme.spacing(1),
    },
  },
  alertTitle: {
    fontWeight: 700,
    fontSize: '1rem',
    color: '#545758',
    margin: 0,
  },
}));

export default useStyles