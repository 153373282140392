import { makeStyles, Theme } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';

import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { MdClose } from 'react-icons/md';
import { SearchII } from '../../../../interfaces/servico';
import { searchServiceWithParams } from '../../../../services/servico';
import getCookie from '../../../../utils/getCookies';
import MoreLink from '../MoreLink';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    position: 'absolute',
    right: 0,
    width: '100%',
    zIndex: 80,
    minHeight: '100vh',
    height: 1400,
    background: 'rgba(0,0,0,0.2)',
  },
  content: {
    width: '100%',
    background: '#FFF',
    boxShadow: '0px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    paddingBottom: 20,
  },
  headerResult: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 0',
    borderBottom: '1px solid #373F41',
  },
  textHeaderResult: {
    color: '#373F41',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: 14,
    letterSpacing: 0.3,
    textTransform: 'uppercase',
  },
  boxResultIntem: {
    padding: '20px 0',
    borderBottom: '1px dashed #737B7D',
  },
  textServiceName: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    color: '#373F41',
    fontSize: 20,
    letterSpacing: 0.3,
    paddingBottom: 10,
  },
  textOrgan: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    color: '#373F41',
    fontSize: 16,
    letterSpacing: 0.3,
    paddingBottom: 10,
  },
  icon: {
    height: 24,
    width: 24,
  },
}));

interface Props {
  show: boolean;
  setShow: (set: boolean) => void;
  termsForSearch: string;
}
export default function Result({
  show,
  termsForSearch,
  setShow,
}: Props): JSX.Element {
  const classes = useStyles();
  const matchesMobile = useMediaQuery('(min-width:660px)');
  const [listSearch, setListSearch] = useState<SearchII>();
  const [loadingSearch, setLoadingSearch] = useState<boolean>(true);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${atob(
      process.env.REACT_APP_RECAPTCHA,
    )}`;
    script.addEventListener('load', () => {});
  }, []);

  const handleClose = () => {
    setShow(false);
  };

  const getSearch = async (term: string, page: number) => {
    setListSearch(undefined);
    const typeUser = await getCookie('perfil');
    const tokenSiseci: string | null = getCookie('gov_access_token_sso');

    try {
      (window as any).grecaptcha.ready(() => {
        (window as any).grecaptcha
          .execute(atob(process.env.REACT_APP_RECAPTCHA), {
            action: 'submit',
          })
          .then(async token => {
            if (token) {
              const { data } = await searchServiceWithParams(
                {
                  ativo: true,
                  page,
                  items_size: 10,
                  publico_especifico: typeUser,
                  search: term,
                },
                tokenSiseci,
              );
              setListSearch(data);
              setLoadingSearch(false);
            }
          });
      });
    } catch (err) {
      console.log(err);
      setLoadingSearch(false);
    }
  };

  useEffect(() => {
    if (termsForSearch) {
      getSearch(termsForSearch, 1);
    }
  }, [termsForSearch]);

  return (
    <>
      {show && (
        <Box
          style={{ top: matchesMobile ? '80px' : '130px' }}
          className={classes.main}
        >
          <Box className={classes.content}>
            <Container>
              <Box className={classes.headerResult}>
                <Typography className={classes.textHeaderResult}>
                  {loadingSearch
                    ? 'Buscando ... '
                    : `${listSearch?.results?.length || 0} resultados`}
                </Typography>
                <IconButton onClick={handleClose}>
                  <MdClose className={classes.icon} />
                </IconButton>
              </Box>

              {loadingSearch && (
                <Box
                  marginBottom='60px'
                  width='100%'
                  display='flex'
                  justifyContent='center'
                  className={classes.boxResultIntem}
                >
                  <CircularProgress color='primary' />
                </Box>
              )}

              {listSearch?.results?.length === 0 && !loadingSearch && (
                <Box
                  className={classes.boxResultIntem}
                  style={{ textAlign: 'center' }}
                >
                  <Typography className={classes.textServiceName}>
                    Nenhum serviço encontrado para {`"${termsForSearch}"`}
                  </Typography>
                </Box>
              )}

              {listSearch &&
                listSearch?.results?.map(search => (
                  <Box key={search.id} className={classes.boxResultIntem}>
                    <Typography className={classes.textServiceName}>
                      {search.titulo}
                    </Typography>
                    <Typography className={classes.textOrgan}>
                      Órgão: {search.orgao_nome}
                    </Typography>
                    <Box>
                      <MoreLink
                        service={search}
                        setShowSearchResult={setShow}
                      />
                    </Box>
                  </Box>
                ))}
            </Container>
          </Box>
        </Box>
      )}
    </>
  );
}
