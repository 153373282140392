import { AxiosResponse } from 'axios';
import getCookie from '../utils/getCookies';
import { apiforFlow } from './api';
const tokenSSO: string | null =
  getCookie('gov_access_token_sso') || '{}';

export const getFluxoFlowBySlug = (slug: string): Promise<AxiosResponse> =>
  apiforFlow.get(`flow/fluxo/?slug=${slug}`, {
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const getFormBySlug = (slug: string): Promise<AxiosResponse> =>
  apiforFlow.get(`/flow/formularios/?slug=${slug}`, {
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const getFormBySlugFluxo = (slug: string): Promise<AxiosResponse> =>
  apiforFlow.get(`/flow/formularios/?flow_slug=${slug}`, {
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const getRequests = (params: {
  cpf: string;
  page?: number | 1;
}): Promise<AxiosResponse> =>
  apiforFlow.get('/flow/respostas-fluxo/', {
    params,
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const startRequestFlow = (
  flow: string,
  cpf: string
): Promise<AxiosResponse> =>
  apiforFlow.post(
    '/flow/responder-fluxo/',
    {
      flow,
      cpf,
    },
    {
      headers: {
        Authorization: `Bearer ${tokenSSO}`,
      },
    }
  );

type answerProps = {
  flow_resposta: number;
  formulario: string;
  cpf: string;
  resposta: any;
};

export const answerForm = (answer: answerProps): Promise<AxiosResponse> =>
  apiforFlow.post('/flow/responder-formulario/', answer, {
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const getAnswerForm = (params: {
  identificador?: number;
  cpf?: string;
}): Promise<AxiosResponse> =>
  apiforFlow.get('/flow/respostas-formulario/', {
    params,
  });

export const getStatusFormFlow = (params: {
  identificador?: number;
  cpf?: string;
}): Promise<AxiosResponse> =>
  apiforFlow.get('/flow/rastreamento', {
    params,
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const getDocumentosResposta = (
  idDoc: string,
  params: { identificador_solicitacao: string; cpf: string }
): Promise<AxiosResponse> =>
  apiforFlow.get(`/flow/respostas-documento/${idDoc}`, {
    params,
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const sendFile = (arquivo, onlyImage = true) => {
  const formData = new FormData();
  formData.append('arquivo', arquivo);

  if (onlyImage) {
    return apiforFlow.post(`/flow/imagens-upload/`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  }

  return apiforFlow.post(`/flow/arquivo-upload/`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

export const onTermFlowAccept = (
  solicitacao: string,
  termo: string
): Promise<AxiosResponse> =>
  apiforFlow.post(
    '/flow/termo-fluxo-concordar/',
    {
      solicitacao,
      termo,
    },
    {
      headers: {
        Authorization: `Bearer ${tokenSSO}`,
      },
    }
  );

export const getAllEvaluationFlows = (id: string): Promise<AxiosResponse> =>
  apiforFlow.get('/flow/avaliar-solicitacao/', {
    params: {
      id,
    },
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const updatedEvaluationFlow = (
  id: string,
  avaliacao_informacao: number,
  avaliacao_atendimento: number,
  comentario_informacao?: string,
  comentario_atendimento?: string
): Promise<AxiosResponse> =>
  apiforFlow.put(
    `/flow/avaliar-solicitacao/${id}/?id=${id}`,
    {
      avaliacao_informacao,
      comentario_informacao,
      avaliacao_atendimento,
      comentario_atendimento,
    },
    {
      headers: {
        Authorization: `Bearer ${tokenSSO}`,
      },
    }
  );

export const cancelRequest = (
  status: string,
  request_id?: number
): Promise<AxiosResponse> =>
  apiforFlow.put(
    `/flow/respostas-fluxo/${request_id}/`,
    {
      status,
    },
    {
      headers: {
        Authorization: `Bearer ${tokenSSO}`,
      },
    }
  );
