import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { LoginType } from '../../interfaces/user';
import { getLoginTypeFromToken } from '../../utils/auth';
import getCookie from '../../utils/getCookies';

interface Props extends RouteProps {
  layout: React.ComponentType<any>;
  component: React.ComponentType<any>;
  isAuth?: boolean;
  requiredLoginType?: LoginType;
  [rest: string]: any;
}

const RouteLayout = (props: Props): JSX.Element => {
  const loginType = getLoginTypeFromToken();
  const token: string | null = getCookie('gov_access_token_sso');

  const {
    layout: Layout,
    component: Component,
    isAuth,
    requiredLoginType,
    ...rest
  } = props;

  return (
    <Route
      {...rest}
      render={(matchProps) => {
        if (isAuth && !token) {
          return <Redirect to='/' />;
        }

        const currentPath = matchProps.location.pathname;

        if (requiredLoginType) {
          if (loginType !== requiredLoginType) {
            return <Redirect to='/workspace' />;
          }
        }

        if (loginType === 'e-cnpj') {
          const allowedRoutesForECNPJ = [
            '/',
            '/workspace/minha-area/meus-dados',
            '/workspace/meus-sistemas',
          ];

          if (!allowedRoutesForECNPJ.includes(currentPath)) {
            return <Redirect to='/workspace/meus-sistemas' />;
          }
        }

        return (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        );
      }}
    />
  );
};

export default RouteLayout;
