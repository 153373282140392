/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import {
  Box,
  Button,
  Snackbar,
  Theme,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Alert, Skeleton } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { FaBookmark, FaRegBookmark } from 'react-icons/fa';
import { IoIosArrowForward, IoMdSearch } from 'react-icons/io';
import { MdSubdirectoryArrowRight } from 'react-icons/md';
import { FavoriteSystemRequest, GeoEstrutura, Sistema } from '../..';
import {
  setRegisterAccess,
  setSystemsFavorite,
  setSystemsRemoveFavorite,
} from '../../../../services/sistema';
import { adicionarStateNaURL } from '../../../../utils/adicionarStateNaURL';
import getCookie from '../../../../utils/getCookies';
import CardListActionsPerfil from '../cardListAction';
import CardListActionsSctructure from '../cardlistStructure';

const CardListActions = ({
  listCards,
  loading,
  listFavorites,
  getFavorites,
  getSystemsRequest,
}: {
  listCards: Sistema[];
  loading;
  listFavorites: FavoriteSystemRequest[];
  getFavorites: () => void;
  getSystemsRequest: () => void;
}) => {
  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      position: 'relative',
      display: 'flex',
      width: '285px',
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        marginBottom: '60px',
      },
    },
    containercard: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      border: '1px solid #DDDDDD',
      borderRadius: '8px',
      width: '100%',
      backgroundColor: '#FFFFFF',
      maxHeight: '672px',
      overflowY: 'auto',
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    detailCardContainer: {
      position: 'absolute',
      zIndex: 10,
      left: '100%',
      top: 0,
      maxHeight: '672px',
      flexDirection: 'row-reverse',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    card: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      transition: 'background-color 0.3s ease',
      color: '#212121',
      borderBottom: '1px solid #DDDDDD',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        '& $icon': {
          color: '#fff',
        },
      },
    },
    icon: {
      color: theme.palette.primary.main,
    },
    iconResponsive: {
      color: '#212121',
    },
    iconSearch: {
      color: '#B0B0B0',
    },
    title: {
      fontSize: '16px',
      marginBottom: '8px',
    },
    containerDescription: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    description: {
      fontSize: '12px',
      width: '181px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      margin: '0',
      display: '-webkit-box',
      webkitLineClamp: 2,
      webkitBoxOrient: 'vertical',
      lineHeight: '1.2em',
      height: ' 2.4em',
    },
    skeleton: {
      backgroundColor: '#DDDDDD',
      borderBottom: '1px solid #fff',
    },
    searchBar: {
      width: '285px',
      backgroundColor: '#fff',
      textAlign: 'center',
      marginBottom: '8px',
      display: 'flex',
      color: '#DDDDDD',
      border: '1px solid #DDDDDD',
      alignContent: 'center',
      alignItems: 'center',
      borderRadius: 2,
      justifyContent: 'space-between',
      padding: 0,
      '&::placeholder': {
        color: '#DDDDDD',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    searchBarInput: {
      border: 'none',
      width: '100%',
      fontSize: theme.typography.pxToRem(16),
      height: 47,
      outline: 0,
      margin: 0,
    },
    btnSearch: {
      background: '#FFF',
      height: 47,
      margin: 0,
      padding: 0,
      width: 47,
      borderRadius: '0',
      '&:hover': {
        background: '#FFF',
        opacity: 0.8,
      },
    },
    notFound: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    actionAccess: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      color: '#212121',
      textDecoration: 'none',
      fontSize: '12px',
    },
    header: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      padding: '18px 16px',
      color: '#212121',
      backgroundColor: '#fff',
      justifyContent: 'space-between',
    },
    headerDescription: {
      fontSize: '14px',
      margin: 0,
      fontWeight: 500,
      color: '#6D6D6D',
    },
    detailCard: {
      display: 'flex',
      flexDirection: 'row',
      padding: '0px 24px',
      backgroundColor: '#FFFFFF',
      borderTop: '1px solid #DDDDDD',
      alignItems: 'center',
      justifyContent: 'space-between',
      transition: 'background-color 0.3s ease',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        '& $icon': {
          color: '#fff',
        },
        '& $iconFavorite': {
          color: '#fff',
          fill: '#fff',
        },
        '& $a': {
          color: '#fff',
        },
        '& $actionAccess': {
          color: '#fff',
        },
        '& $h2': {
          color: '#fff',
        },
        '& $iconResponsive': {
          color: '#fff',
        },
      },
    },
    iconFavorite: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  }));

  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const classes = useStyles();
  const [selectedCard, setSelectedCard] = useState<Sistema>();
  const [selectedStructure, setSelectedStructure] = useState<GeoEstrutura>();
  const [textSearch, setTextSearch] = useState('');
  const [filteredCards, setFilteredCards] = useState<Sistema[]>([]);

  const token: string | null = getCookie('gov_access_token_sso');

  const [successMessage, setSuccessMessage] = useState('');
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

  const openErrorSnackbar = message => {
    setErrorMessage(message);
    setErrorSnackbarOpen(true);
  };

  const openSuccessSnackbar = message => {
    setSuccessMessage(message);
    setSuccessSnackbarOpen(true);
  };

  const removeFavorite = async (perfil: any, profile: any) => {
    const system = {
      system_id: selectedCard.sistemaID,
      group_id: profile.grupoID,
      geo_estrutura_id: perfil.geoEstruturaID,
    };

    try {
      const response = await setSystemsRemoveFavorite(system, token);
      openSuccessSnackbar(response?.data.message);
      getFavorites();
    } catch (error) {
      console.log('');
    }
  };

  const setNewAccess = async (perfil: any, profile) => {
    const favoriteSystemData = {
      system_id: selectedCard.sistemaID,
      group_id: profile.grupoID,
      geo_estrutura_id: perfil.geoEstruturaID,
      sigla: selectedCard.sigla,
      nome: selectedCard.nomeSistema,
      descricao: selectedCard.integracaoGSI,
      endereco: adicionarStateNaURL(perfil.endereco, perfil.geoEstruturaID),
      extra_data: {
        grupoID: profile.grupoID,
        nomeGrupo: profile.nomeGrupo,
        colecaoID: perfil.colecaoID,
        geoEstruturaID: perfil.geoEstruturaID,
        geoEstruturaSigla: perfil.geoEstruturaSigla,
        geoEstruturaNome: perfil.geoEstruturaNome,
        tipoEnderecoID: perfil.tipoEnderecoID,
        endereco: adicionarStateNaURL(perfil.endereco, perfil.geoEstruturaID),
      },
    };

    try {
      await setRegisterAccess(favoriteSystemData, token);
      window.open(
        adicionarStateNaURL(perfil.endereco, perfil.geoEstruturaID),
        '_blank',
      );
    } catch (error) {
      console.error('Error fetching favorites:', error);
    }
  };

  const callSetSystemsFavorite = async (perfil: any, profile: any) => {
    const favoriteSystemData = {
      system_id: selectedCard.sistemaID,
      group_id: profile.grupoID,
      geo_estrutura_id: perfil.geoEstruturaID,
      sigla: selectedCard.sigla,
      nome: selectedCard.nomeSistema,
      descricao: selectedCard.integracaoGSI,
      endereco: perfil.endereco,
      extra_data: {
        grupoID: perfil.grupoID,
        nomeGrupo: profile.nomeGrupo,
        colecaoID: perfil.colecaoID,
        geoEstruturaID: perfil.geoEstruturaID,
        geoEstruturaSigla: perfil.geoEstruturaSigla,
        geoEstruturaNome: perfil.geoEstruturaNome,
        tipoEnderecoID: perfil.tipoEnderecoID,
        endereco: perfil.endereco,
      },
    };

    try {
      const response = await setSystemsFavorite(favoriteSystemData, token);
      openSuccessSnackbar(response?.data.message);
      getFavorites();
    } catch (error) {
      if (error.response && error.response.status === 403) {
        openErrorSnackbar('Sistema já favoritado.');
      } else {
        openErrorSnackbar(
          'Erro ao enviar a avaliação. Por favor, tente novamente mais tarde.',
        );
      }
    }
  };

  const DetailCardFavorite = ({
    card,
    callSetSystemsFavorite,
    systemId,
    profile,
  }) => {
    const theme = useTheme();
    const [isHovered, setIsHovered] = useState(false);
    const [hoveredPerfil, setHoveredPerfil] = useState(false);

    const handleMouseOver = () => setIsHovered(true);
    const handleMouseOut = () => setIsHovered(false);

    const shouldDisplayWhiteIcon = () => {
      return listFavorites.some(
        favorite =>
          favorite.system_id === systemId &&
          favorite?.extra_data?.geoEstruturaID === card?.geoEstruturaID &&
          favorite?.group_id === profile.grupoID,
      );
    };

    return (
      <>
        <Box
          key={card.geoEstruturaID}
          className={`${classes.detailCard} `}
          style={{ padding: '8px 30px' }}
          onMouseEnter={() => setHoveredPerfil(true)}
          onMouseLeave={() => setHoveredPerfil(false)}
        >
          <div className={classes.actionAccess}>
            <div
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <MdSubdirectoryArrowRight
                size={16}
                className={classes.iconResponsive}
              />
              {isHovered || shouldDisplayWhiteIcon() ? (
                <FaBookmark
                  fontSize={20}
                  size={20}
                  className={classes.iconFavorite}
                  onClick={
                    shouldDisplayWhiteIcon()
                      ? () => removeFavorite(card, profile)
                      : () => callSetSystemsFavorite(card, profile)
                  }
                  style={{
                    fill:
                      shouldDisplayWhiteIcon() && !hoveredPerfil
                        ? theme.palette.primary.main
                        : '#fff',
                  }}
                />
              ) : (
                <FaRegBookmark
                  fontSize={20}
                  size={20}
                  className={classes.iconFavorite}
                  onClick={() => callSetSystemsFavorite(card, profile)}
                />
              )}
            </div>
            <h2 className={classes.title}>{profile.nomeGrupo}</h2>
          </div>
          <button
            onClick={() => setNewAccess(card, profile)}
            className={classes.actionAccess}
            style={{
              cursor: 'pointer',
              border: 'none',
              backgroundColor: 'transparent',
            }}
          >
            Acessar
            <IoIosArrowForward size={20} className={classes.iconResponsive} />
          </button>
        </Box>
      </>
    );
  };

  const DetailCard = ({ card, isLastItem, sistemaID }) => {
    const [isCollapseOpen, setIsCollapseOpen] = useState(false);

    const handleToggleCollapse = () => {
      setIsCollapseOpen(!isCollapseOpen);
    };

    return (
      <>
        <Box
          key={card.geoEstruturaID}
          onClick={handleToggleCollapse}
          className={`${classes.detailCard}`}
          onMouseEnter={() => setSelectedStructure(card)}
        >
          <div className={classes.actionAccess}>
            <MdSubdirectoryArrowRight
              size={16}
              className={classes.iconResponsive}
            />
            <h2 className={classes.title}>{card.geoEstruturaNome}</h2>
          </div>
          <button
            className={classes.actionAccess}
            style={{
              cursor: 'pointer',
              border: 'none',
              backgroundColor: 'transparent',
            }}
          >
            <IoIosArrowForward size={20} className={classes.iconResponsive} />
          </button>
        </Box>
        {isCollapseOpen && (
          <div>
            {card.perfis.map((profileCard, index) => (
              <div key={profileCard.grupoID}>
                <DetailCardFavorite
                  key={card.grupoID}
                  card={card}
                  callSetSystemsFavorite={callSetSystemsFavorite}
                  systemId={sistemaID}
                  profile={profileCard}
                />
              </div>
            ))}
          </div>
        )}
      </>
    );
  };

  const handleCardClick = (card: Sistema) => {
    setSelectedCard(card);
  };

  useEffect(() => {
    if (textSearch) {
      const filtered = listCards.filter(card =>
        card.sigla.toLowerCase().includes(textSearch.toLowerCase()),
      );
      setFilteredCards(filtered);
    } else {
      setFilteredCards(listCards);
    }
  }, [textSearch, listCards]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Box className={classes.searchBar}>
        <Button className={classes.btnSearch}>
          <IoMdSearch className={classes.iconSearch} size={20} />
        </Button>
        <input
          type='text'
          aria-label='description'
          placeholder='Procure por algum sistema'
          className={classes.searchBarInput}
          id='searchBarInput'
          value={textSearch}
          onChange={e => {
            setTextSearch(e.target.value);
          }}
        />
      </Box>

      <Box
        className={classes.container}
        onMouseLeave={() => handleCardClick(null)}
      >
        <Box className={classes.containercard}>
          {loading ? (
            Array.from(new Array(5)).map((_, index) => (
              <Skeleton
                key={index}
                variant='rect'
                className={classes.skeleton}
                height={118}
              />
            ))
          ) : filteredCards.length > 0 ? (
            filteredCards.map(list => (
              <>
                <Box
                  key={list.sistemaID}
                  className={classes.card}
                  onMouseEnter={() => {
                    if (!isMobile) {
                      handleCardClick(list);
                      setSelectedStructure(null);
                    }
                  }}
                  onClick={() => {
                    if (isMobile) {
                      handleCardClick(list);
                    }
                  }}
                >
                  <div
                    style={{ padding: '16px 40px 16px 16px', width: '100%' }}
                  >
                    <h3 className={classes.title}>{list.sigla}</h3>
                    <div className={classes.containerDescription}>
                      <p className={classes.description}>
                        {list.integracaoGSI}
                      </p>
                      <IoIosArrowForward size={20} className={classes.icon} />
                    </div>
                  </div>
                  {selectedCard &&
                    isMobile &&
                    selectedCard.sistemaID === list.sistemaID && (
                      <>
                        <Box className={classes.header}>
                          <p className={classes.headerDescription}>
                            Selecione uma estrutura
                          </p>
                          <IoIosArrowForward
                            size={20}
                            className={classes.iconResponsive}
                          />
                        </Box>
                        {selectedCard &&
                          selectedCard.geoEstruturas.map((card, index) => (
                            <DetailCard
                              key={card.geoEstruturaID}
                              card={card}
                              sistemaID={selectedCard.sistemaID}
                              isLastItem={
                                index === selectedCard.geoEstruturas.length - 1
                              }
                            />
                          ))}
                      </>
                    )}
                </Box>
              </>
            ))
          ) : (
            <Box>
              <p className={classes.notFound}>Nenhum sistema encontrado</p>
            </Box>
          )}
        </Box>
        {selectedCard && (
          <Box className={classes.detailCardContainer}>
            <CardListActionsSctructure
              setSelectedStructure={setSelectedStructure}
              listCards={selectedCard}
            />
            {selectedStructure && (
              <CardListActionsPerfil
                getSystemsRequest={getSystemsRequest}
                listCards={selectedStructure}
                listCardsServices={selectedCard}
                listFavorites={listFavorites}
                getFavorites={getFavorites}
              />
            )}
          </Box>
        )}
      </Box>
      <Snackbar
        open={errorSnackbarOpen || successSnackbarOpen}
        autoHideDuration={5000}
        onClose={() => setErrorSnackbarOpen(false)}
      >
        <Alert
          severity={successMessage ? 'success' : 'error'}
          onClose={() => setErrorSnackbarOpen(false)}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: successMessage || errorMessage,
            }}
          />
        </Alert>
      </Snackbar>
    </>
  );
};

export default CardListActions;
