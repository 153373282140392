import { Box, Container, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

import getCookie from '../../utils/getCookies';
import Breadcrumb from '../Breadcrumb';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFFFFF',
  },
  navContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px',
  },
}));

export default function NavMenu(): JSX.Element {
  const classes = useStyles();
  const [openModalProfile, setOpenModalProfile] = React.useState(false);
  const currentProfile = getCookie('perfil');
  const matchesMobile = useMediaQuery('(max-width:660px)');

  const onChangeProfile = () => {
    setOpenModalProfile(!openModalProfile);
  };

  React.useEffect(() => {
    if (currentProfile === 'Cidadão') {
      onChangeProfile();
    }
  }, []);

  return (
    <Box className={classes.main}>
      <Container className={classes.navContent}>
        <Breadcrumb links={[]} />
        {/* {!matchesMobile && <SelectProfile />} */}
      </Container>
    </Box>
  );
}
