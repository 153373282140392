/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import {
  Box,
  Divider,
  Snackbar,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useState } from 'react';
import { FaBookmark, FaRegBookmark } from 'react-icons/fa';
import { IoIosArrowForward } from 'react-icons/io';
import { FavoriteSystemRequest, GeoEstrutura, Sistema } from '../..';
import {
  setRegisterAccess,
  setSystemsFavorite,
  setSystemsRemoveFavorite,
} from '../../../../services/sistema';
import { adicionarStateNaURL } from '../../../../utils/adicionarStateNaURL';
import getCookie from '../../../../utils/getCookies';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '672px',
    position: 'absolute',
    maxHeight: '672px',
    width: '390px',
    zIndex: 2,
    borderRadius: '8px',
    left: '100%',
    top: 0,
    overflowY: 'auto',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderStartStartRadius: '8px',
    borderStartEndRadius: '8px',
    padding: '11px 8px',
    backgroundColor: '#fff',
  },
  headerTitle: {
    fontSize: '16px',
    padding: 0,
    margin: 0,
    fontWeight: 600,
    color: '#212121',
  },
  divider: {
    width: '362px',
    height: '2px',
    margin: '11px 0px',
    borderRadius: '8px',
    background: '#004F9F4D',
  },
  headerDescription: {
    fontSize: '14px',
    margin: 0,
    fontWeight: 500,
    color: '#212121',
  },
  detailCard: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px 24px',
    backgroundColor: '#FFFFFF',
    borderTop: '1px solid #DDDDDD',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      '& $icon': {
        color: '#fff',
      },
      '& $iconFavorite': {
        color: '#fff',
        fill: '#fff',
      },
      '& $a': {
        color: '#fff',
      },
      '& $h2': {
        color: '#fff',
      },
      '& $actionAccess': {
        color: '#fff',
      },
    },
  },
  actionAccess: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontSize: '12px',
  },
  detailradius: {
    borderEndStartRadius: '8px',
    borderEndEndRadius: '8px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#212121',
  },
  icon: {
    color: theme.palette.primary.main,
  },
  iconFavorite: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

const CardListActionsPerfil = ({
  listCards,
  listFavorites,
  getFavorites,
  listCardsServices,
  getSystemsRequest,
}: {
  listCards: GeoEstrutura;
  listFavorites: FavoriteSystemRequest[];
  getFavorites: () => void;
  listCardsServices: Sistema;
  getSystemsRequest: () => void;
}) => {
  const classes = useStyles();
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [successMessage, setSuccessMessage] = useState('');
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const token: string | null = getCookie('gov_access_token_sso');
  const setNewAccess = async (perfil: any, profile) => {
    const favoriteSystemData = {
      system_id: listCardsServices.sistemaID,
      group_id: profile.grupoID,
      geo_estrutura_id: perfil.geoEstruturaID,
      sigla: listCardsServices.sigla,
      nome: listCardsServices.nomeSistema,
      descricao: listCardsServices.integracaoGSI,
      endereco: adicionarStateNaURL(perfil.endereco, perfil.geoEstruturaID),
      extra_data: {
        grupoID: profile.grupoID,
        nomeGrupo: profile.nomeGrupo,
        colecaoID: perfil.colecaoID,
        geoEstruturaID: perfil.geoEstruturaID,
        geoEstruturaSigla: perfil.geoEstruturaSigla,
        geoEstruturaNome: perfil.geoEstruturaNome,
        tipoEnderecoID: perfil.tipoEnderecoID,
        endereco: adicionarStateNaURL(perfil.endereco, perfil.geoEstruturaID),
      },
    };

    try {
      await setRegisterAccess(favoriteSystemData, token);
      window.open(
        adicionarStateNaURL(perfil.endereco, perfil.geoEstruturaID),
        '_blank',
      );
    } catch (error) {
      console.error('Error fetching favorites:', error);
    }
  };

  const openErrorSnackbar = message => {
    setErrorMessage(message);
    setErrorSnackbarOpen(true);
  };

  const openSuccessSnackbar = message => {
    setSuccessMessage(message);
    setSuccessSnackbarOpen(true);
  };

  const removeFavorite = async (perfil: any, profile) => {
    const system = {
      system_id: listCardsServices.sistemaID,
      group_id: profile.grupoID,
      geo_estrutura_id: perfil.geoEstruturaID,
    };

    try {
      const response = await setSystemsRemoveFavorite(system, token);
      openSuccessSnackbar(response?.data.message);
      getFavorites();
      getSystemsRequest();
    } catch (error) {
      console.log('');
    }
  };

  const callSetSystemsFavorite = async (perfil: any, profile: any) => {
    const favoriteSystemData = {
      system_id: listCardsServices.sistemaID,
      group_id: profile.grupoID,
      geo_estrutura_id: perfil.geoEstruturaID,
      sigla: listCardsServices.sigla,
      nome: listCardsServices.nomeSistema,
      descricao: listCardsServices.integracaoGSI,
      endereco: adicionarStateNaURL(perfil.endereco, perfil.geoEstruturaID),
      extra_data: {
        grupoID: perfil.grupoID,
        nomeGrupo: profile.nomeGrupo,
        colecaoID: perfil.colecaoID,
        geoEstruturaID: perfil.geoEstruturaID,
        geoEstruturaSigla: perfil.geoEstruturaSigla,
        geoEstruturaNome: perfil.geoEstruturaNome,
        tipoEnderecoID: perfil.tipoEnderecoID,
        endereco: adicionarStateNaURL(perfil.endereco, perfil.geoEstruturaID),
      },
    };

    try {
      const response = await setSystemsFavorite(favoriteSystemData, token);
      openSuccessSnackbar(response?.data.message);
      getFavorites();
    } catch (error) {
      // Check if the error response has a status of 403
      if (error.response && error.response.status === 403) {
        // Display a custom message for 403 Forbidden error
        openErrorSnackbar('Sistema já favoritado.');
      } else {
        // Fallback for other types of errors
        openErrorSnackbar(
          'Erro ao enviar a avaliação. Por favor, tente novamente mais tarde.',
        );
      }
    }
  };

  const DetailCardFavorite = ({
    card,
    isLastItem,
    callSetSystemsFavorite,
    systemId,
    profile,
  }) => {
    const theme = useTheme();
    const [isHovered, setIsHovered] = useState(false);
    const [hoveredPerfil, setHoveredPerfil] = useState(false);

    const handleMouseOver = () => setIsHovered(true);
    const handleMouseOut = () => setIsHovered(false);

    const shouldDisplayWhiteIcon = () => {
      return listFavorites.some(
        favorite =>
          favorite.system_id === systemId &&
          favorite?.extra_data?.geoEstruturaID === card?.geoEstruturaID &&
          favorite?.group_id === profile.grupoID,
      );
    };

    return (
      <>
        <Box
          key={card.geoEstruturaID}
          className={`${classes.detailCard} ${
            isLastItem ? classes.detailradius : ''
          }`}
          onMouseEnter={() => setHoveredPerfil(true)}
          onMouseLeave={() => setHoveredPerfil(false)}
        >
          <div className={classes.actionAccess}>
            <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
              {isHovered || shouldDisplayWhiteIcon() ? (
                <FaBookmark
                  fontSize={20}
                  size={20}
                  className={classes.iconFavorite}
                  onClick={
                    shouldDisplayWhiteIcon()
                      ? () => removeFavorite(card, profile)
                      : () => callSetSystemsFavorite(card, profile)
                  }
                  style={{
                    fill:
                      shouldDisplayWhiteIcon() && !hoveredPerfil
                        ? theme.palette.primary.main
                        : '#fff',
                  }}
                />
              ) : (
                <FaRegBookmark
                  fontSize={20}
                  size={20}
                  className={classes.iconFavorite}
                  onClick={() => callSetSystemsFavorite(card, profile)}
                />
              )}
            </div>
            <h2 className={classes.title}>{profile.nomeGrupo}</h2>
          </div>
          <button
            onClick={() => setNewAccess(card, profile)}
            className={classes.actionAccess}
            style={{
              cursor: 'pointer',
              border: 'none',
              backgroundColor: 'transparent',
            }}
          >
            Acessar
            <IoIosArrowForward size={20} className={classes.icon} />
          </button>
        </Box>
      </>
    );
  };

  return (
    <Box className={classes.container}>
      <Box style={{ border: '1px solid #DDDDDD' }}>
        <Box className={classes.header}>
          <h3 className={classes.headerTitle}>
            {listCards?.geoEstruturaSigla} - {listCards?.geoEstruturaNome}
          </h3>
          <Divider className={classes.divider} />
          <p className={classes.headerDescription}>Selecione um perfil</p>
        </Box>
        {listCards &&
          listCards?.perfis.map((card, index) => {
            return (
              <>
                <DetailCardFavorite
                  key={card.grupoID}
                  card={listCards}
                  isLastItem={index === listCards.perfis.length - 1}
                  callSetSystemsFavorite={callSetSystemsFavorite}
                  systemId={listCardsServices.sistemaID}
                  profile={card}
                />
              </>
            );
          })}
      </Box>
      <Snackbar
        open={errorSnackbarOpen || successSnackbarOpen}
        autoHideDuration={5000}
        onClose={() => setErrorSnackbarOpen(false)}
      >
        <Alert
          severity={successMessage ? 'success' : 'error'}
          onClose={() => setErrorSnackbarOpen(false)}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: successMessage || errorMessage,
            }}
          />
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CardListActionsPerfil;
