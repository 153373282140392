import {
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { MdArrowBack } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import { Breadcrumb, PaginationService } from '../../Components';
import { SearchII } from '../../interfaces/servico';
import { getServiceWithParams } from '../../services/servico';
import getCookie from '../../utils/getCookies';
import { CardService } from './components';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 10px',
  },
  boxIndicatorsBack: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0px',
  },
  indicatorsBack: {
    display: 'flex',
    width: '100%',
    color: '#373F41',
    maxWidth: '1250px',
    alignItems: 'center',
  },
  boxTermsList: {
    backgroundColor: '#fff',
    borderRadius: 4,
    marginBottom: 22,
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    color: '#373F41',
  },
  boxDateInfo: {
    display: 'flex',
    color: '#373F41',
    alignItems: 'center',
    marginTop: 17,
    flexWrap: 'wrap',
  },
  date: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 16,
    fontStyle: 'normal',
    margin: '0px 4px',
    color: '#373F41',
    opacity: 0.6,
  },
}));

export default function DigitalServices(): JSX.Element {
  const classes = useStyles();
  const tokenSiseci: string | null = getCookie('gov_access_token_sso');
  const { tema_slug, tema } = useParams<{ tema_slug: string; tema: string }>();

  const [loading, setLoading] = useState<boolean>(true);

  const history = useHistory();
  const [services, setServices] = useState<SearchII | undefined>();
  const linksBreadcrumb = [
    {
      name: 'Serviços digitais',
      redirectTo: '#',
    },
    {
      name: tema,
      redirectTo: '#',
    },
  ];

  const getServiceDigitalForCategory = async (page = 1) => {
    const typeUser = await getCookie('perfil');
    try {
      const { data } = await getServiceWithParams(
        {
          ativo: true,
          page,
          items_size: 4,
          publico_especifico: typeUser,
          categoria_slug: tema_slug,
        },
        tokenSiseci,
      );
      setServices(data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getServiceDigitalForCategory(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Breadcrumb links={linksBreadcrumb} />
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.boxIndicatorsBack}>
              <Box className={classes.indicatorsBack}>
                <IconButton onClick={() => history.push('/workspace')}>
                  <MdArrowBack />
                </IconButton>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 700,
                    textTransform: 'capitalize',
                  }}
                >
                  {tema}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          {loading ? <CircularProgress style={{ margin: 'auto' }} /> : ''}
          {!loading && services?.results?.length
            ? services.results.map(service => (
                <Grid key={service.id} item sm={12} md={6}>
                  <CardService service={service} key={service.slug} />
                </Grid>
              ))
            : null}
          {!loading && services?.results?.length === 0 ? (
            <Box margin='auto'>
              <Typography style={{ textAlign: 'center' }}>
                Sem resultados para {tema}.
              </Typography>
            </Box>
          ) : null}
        </Grid>
      </Container>
      {services && services.results.length ? (
        <PaginationService
          getSearch={getServiceDigitalForCategory}
          listSearch={services}
        />
      ) : (
        ''
      )}
    </>
  );
}
