import { makeStyles } from '@material-ui/core';
import { ReactElement, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { sendCodeForSISECI } from '../../../services/auth';
import { setCookie } from '../../../utils/setCookie';
import Footer from '../../Footer';
import Header from '../../Header';

interface Props {
  children: JSX.Element;
  window?: () => Window;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100dvh',
  },
  content: {
    flex: 1,
  },
}));

export default function Dash(props: Props): ReactElement {
  const classes = useStyles();
  const { children } = props;
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    const url = (window as any).location.search;
    const { pathname } = location;
    const query = new URLSearchParams(url);
    const code: any = query.get('code');
    if (code) {
      setCookie('pathname_redirect_gov', pathname);
      sendCodeForSISECI(code, history);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={classes.root}>
      <Header />
      <main className={classes.content}>{children}</main>
      <Footer />
    </div>
  );
}
